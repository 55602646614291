import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import { AuthPage, Logout } from "./modules/Auth";
import { ForgotPassword } from "./modules/Auth/pages/ForgotPassword";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { CategoriasPage } from "./pages/Categoria";
import { CategoriacobPage } from "./pages/Categoriacob";
import Users from "./pages/Users/";
import { CobrconvPage } from "./pages/cobcobrconv";
import { CobradorPage } from "./pages/Cobrador";
import { CobrancasPage } from "./pages/Cobrancas";
import { StatusCobrancaPage } from "./pages/cobStatus";
import { ContaBancoStatusPage } from "./pages/contabancostatus";
import { ContratoPPage } from "./pages/ContratoP";
import { ContratoPPageM } from "./pages/ContratoPM";

import { ContratosNovoPage } from "./pages/Contratos_Novo";
import { StatusContratoPage } from "./pages/ctStatus";
import { VariaveisPage } from "./pages/ctVariaveis";
import { EmpresasPage } from "./pages/Empresa";
import { FamiliasPage } from "./pages/Familias";
import { GrausPage } from "./pages/Graus";
import { GruposPage } from "./pages/Grupos";
import { ManutencaoPage } from "./pages/ManutencaoP";
import { ModManutPage } from "./pages/ModManut";
import { ModRelatPage } from "./pages/ModRelat";

import { PrintPage } from "./pages/PrintContrato";
import { ContratoPage } from "./pages/RelContrato";
import { FluxoRecPage } from "./pages/RelFluxoRec";
import { FluxoRecPrevistoPage } from "./pages/RelFluxoRecprevisto";
import { RelacaoDebitoPage } from "./pages/RelRelacaoDebito";
import { RelacaoInadimplenciaPage } from "./pages/RelRelacInad";
import { TaxatpPage } from "./pages/taxatp";
import { TpDebitosPage } from "./pages/tpdebito";
import { TpDepsPage } from "./pages/tpdep";
import { TpInfosPage } from "./pages/tpinfo";
import { TpVendasPage } from "./pages/tpvenda";
import { UnBancosPage } from "./pages/unbanco";
import { UnEstcivilPage } from "./pages/unestcivil";
import { UnFincontaPage } from "./pages/unfinconta";
import { UnReligiaoPage } from "./pages/unreligiao";
import { UnSexosPage } from "./pages/unsexo";
import { VendedorPage } from "./pages/Vendedor";
import { hasAnyRoles, isAuthenticated } from "./services/auth";
import CompaniesPage from "./pages/Empresas";
import { ModulosPage } from "./pages/Modulos";
import { ContratosPage } from "./pages/Contratos";
import DebitosPage from "./pages/Debitos";
import { RelatoriosPage } from "./pages/Relatorios";
// import ChartPreview from "./pages/ChartPreview";

function PrivateRoute({ component: Component, roles = [], ...rest }) {
  return (
    <Route
      {...rest}
      render={() =>
        !isAuthenticated() ? (
          <Redirect
            to={{
              pathname: "/auth/login",
            }}
          />
        ) : !hasAnyRoles(roles) ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          <Layout>
            <Component />
          </Layout>
        )
      }
    />
  );
}

export function Routes() {
  return (
    <Switch>
      <Route path="/auth/login" exact component={AuthPage} />
      <Route path="/auth/forgot-password" exact component={ForgotPassword} />
      <Route path="/error" exact component={ErrorsPage} />
      <Route path="/logout" exact component={Logout} />

      <PrivateRoute path="/" exact component={ModulosPage} />
      <PrivateRoute path="/modmanut" component={ModManutPage} />
      <PrivateRoute path="/modrelat" component={ModRelatPage} />
      <PrivateRoute path="/print" component={PrintPage} />
      <PrivateRoute path="/contratos" component={ContratosPage} />
      <PrivateRoute path="/contrato_novo" component={ContratosNovoPage} />
      <PrivateRoute path="/cobranca" component={CobrancasPage} />
      <PrivateRoute path="/grupos" component={GruposPage} />
      <PrivateRoute path="/categorias" component={CategoriasPage} />
      <PrivateRoute path="/categoriacob" component={CategoriacobPage} />
      <PrivateRoute path="/tpvendas" component={TpVendasPage} />
      <PrivateRoute path="/empresas" component={EmpresasPage} />
      <PrivateRoute path="/familias" component={FamiliasPage} />
      <PrivateRoute path="/vendedor" component={VendedorPage} />
      <PrivateRoute path="/cobrador" component={CobradorPage} />
      <PrivateRoute path="/graus" component={GrausPage} />
      <PrivateRoute path="/ctstatus" component={StatusContratoPage} />
      <PrivateRoute path="/contabancostatus" component={ContaBancoStatusPage} />
      <PrivateRoute path="/ctvariaveis" component={VariaveisPage} />
      <PrivateRoute path="/cobstatus" component={StatusCobrancaPage} />
      <PrivateRoute path="/tpdep" component={TpDepsPage} />
      <PrivateRoute path="/taxatp" component={TaxatpPage} />
      <PrivateRoute path="/manutencaop" component={ManutencaoPage} />
      <PrivateRoute path="/tpdebito" component={TpDebitosPage} />
      <PrivateRoute path="/tpinfo" component={TpInfosPage} />
      <PrivateRoute path="/unsexo" component={UnSexosPage} />
      <PrivateRoute path="/unbanco" component={UnBancosPage} />
      <PrivateRoute path="/unfinconta" component={UnFincontaPage} />
      <PrivateRoute path="/cobcobrconv" component={CobrconvPage} />
      <PrivateRoute path="/unreligiao" component={UnReligiaoPage} />
      <PrivateRoute path="/unestcivil" component={UnEstcivilPage} />
      <PrivateRoute path="/relrecrealizado" component={FluxoRecPage} />
      <PrivateRoute path="/relrecprevisto" component={FluxoRecPrevistoPage} />
      <PrivateRoute path="/relrelacaodebito" component={RelacaoDebitoPage} />
      <PrivateRoute path="/relrelacinad" component={RelacaoInadimplenciaPage} />
      <PrivateRoute path="/relcontrato" component={ContratoPage} />
      <PrivateRoute path="/imprimir/:idPlano" component={PrintPage} />
      <PrivateRoute path="/icontrato/:idPlano" component={ContratoPPage} />
      <PrivateRoute path="/icontratoM/:idPlano" component={ContratoPPageM} />

      <PrivateRoute
        path="/usuarios"
        exact
        component={Users}
        roles={["ADMIN"]}
      />

      <PrivateRoute
        path="/empresas-novo"
        exact
        component={CompaniesPage}
        roles={["ADMIN"]}
      />

      <PrivateRoute path="/debitos" exact component={DebitosPage} />
      <PrivateRoute path="/relatorios" exact component={RelatoriosPage} />
      <PrivateRoute
        path="/relatorios/:report_type/:start_date/:end_date"
        exact
        component={RelatoriosPage}
      />
      {/* <PrivateRoute path="/grafico/:type" exact component={ChartPreview} /> */}

      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  );
}
