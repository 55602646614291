import React from "react";
import Chart from "react-apexcharts";

export function ChartBubbles() {
  const options = {
    chart: {
      height: 350,
      type: "bubble",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 0.8,
      gradient: {
        enabled: false,
      },
    },
    grid: {
      strokeDashArray: 5,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      min: 0,
      max: 100,
      labels: {
        style: {
          colors: "#aaa",
        },
      },
    },

    yaxis: {
      decimalsInFloat: 0,
      min: 0,
      max: 60,
      labels: {
        style: {
          colors: "#aaa",
        },
      },
    },
    colors: ["#00A3FF", "#f25776", "#50CC89", "#F8D15F", "#6B3CE1"],
    legend: {
      fontFamily: "Poppins",
      fontWeight: "200",
      fontSize: "8px",
    },
  };

  const series = [
    {
      name: "Serie 1",
      data: [
        {
          x: 50,
          y: 20,
          z: 40,
        },
      ],
    },
    {
      name: "Serie 2",
      data: [
        {
          x: 20,
          y: 40,
          z: 20,
        },
      ],
    },
    {
      name: "Serie 3",
      data: [
        {
          x: 50,
          y: 40,
          z: 30,
        },
      ],
    },
    {
      name: "Serie 4",
      data: [
        {
          x: 80,
          y: 30,
          z: 35,
        },
      ],
    },
    {
      name: "Serie 5",
      data: [
        {
          x: 40,
          y: 30,
          z: 35,
        },
      ],
    },
  ];

  return (
    <div className={`card card-custom p-8 pr-12`}>
      <h4 class="font-weight-bolder ml-2 pt-0">Tasks Over Time</h4>
      <div className="fs-6 fw-bold ml-2 text-gray-400">24 Overdue Tasks</div>

      <Chart options={options} series={series} type="bubble" height={350} />
    </div>
  );
}
