import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { Fab, Icon } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ComponentToContrato } from "../../components/ComponentToContrato";

export function ContratoPPage() {
  const componentRef = useRef(null);

  return (
    <div style={{ textAlign: "right" }}>
      <Link style={{ marginRight: 20 }} to="/contratos">
        Voltar
      </Link>
      <ReactToPrint
        style={{ marginLeft: 40 }}
        trigger={() => (
          <Fab title="Imprimir" color="inherit" aria-label="Imprimir">
            <Icon>print</Icon>
          </Fab>
        )}
        content={() => componentRef.current}
        pageStyle={{ padding: 20 }}
      />
      <ComponentToContrato componentRef={componentRef} />
    </div>
  );
}
