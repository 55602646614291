/* eslint-disable */
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Icon from "@material-ui/core/Icon";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import api from "../services/api";
import { toast, ToastContainer } from "react-toastify";
//import Select from '@material-ui/core/Select';
//import MenuItem from '@material-ui/core/MenuItem';
//import OutlinedInput from '@material-ui/core/OutlinedInput';
//import InputLabel from '@material-ui/core/InputLabel';
import Radio from "@material-ui/core/Radio";
//import moment from 'moment';
//import { useReactToPrint } from 'react-to-print';
//import { Link } from "react-router-dom"
// import { PrintPage } from './Print';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";



const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  fab: {
    margin: theme.spacing(1),
    width: 35,
    height: 5,
  },
  modalContratos: {
    maxWidth: 900,
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStylesSelect = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export function CategoriasPage() {
  const classes = useStyles();
  const classesSelect = useStylesSelect();
  const [errors, setErrors] = useState([]);
  const [show, setShow] = useState(false);
  const [editar, setEditar] = useState(false);
  const handleClose = () => setShow(false);

  const componentRef = useRef();

  const handleShow = () => {
    for (var key in values) {
      if(values[key] != null  && values[key].toUpperCase  != "MEMCANMOM"){
        values[key] = "";
      }   
    }
    setShow(true);

  }

  //const handleShow = () => {
  //  setValues({});
  //  setErrors([]);
  //}

  function atualizaContratos() {
    api.get("/ct_pl_categ").then((res) => {
      console.log("ct_pl_categ ", JSON.parse(JSON.stringify(res.data)));
      res.data.map((data_plano, i) => {
        if (i === 0) {
          setSelectedValue(data_plano.CODCATEG);
        }
        data_plano.IS_SHOW_ACTION = false;
      });
      setRows(res.data);
      setRowsBusca(res.data);
    });
  }

  const [showDelete, setShowDelete] = useState(false);

  const [linhaARemover, setLinhaARemover] = useState();

  const modalDeleteClose = () => setShowDelete(false);

  function modalDeleteShow() {
    console.log(selectedValue);
    setShowDelete(true);
    rows.map((row_aux, i) => {
      if (selectedValue === row_aux.CODCATEG) {
        console.log("i", i);
        setLinhaARemover(i);
      }
    });
  }

  const [rows, setRows] = useState([]);
  const [rowsBusca, setRowsBusca] = useState([]);

  const [selectedValue, setSelectedValue] = useState("");
  function handleChangeRadio(event) {
    setSelectedValue(event.currentTarget.value);
  }
  useEffect(() => {
    atualizaContratos();
  }, []);

  const filtrarNome = (event) => {
    const name = event.target.value.toLowerCase();
    const auxBusca = rows.filter((row) =>
      row.DESCRICAO.toLowerCase().includes(name)
    );
    if (name) {
      setRowsBusca(auxBusca);
    } else {
      setRowsBusca(rows);
    }
  };

  const [values, setValues] = useState({});

  const handleChangeModal = (event) => {
    const auxValues = { ...values };
    console.log("VALOR ", event.target.value);
    auxValues[event.target.name] = event.target.value;
    setValues(auxValues);
  };

  const handleSubmit1 = (callback) => (event) => {
    event.preventDefault();
    callback();
  };

  //const [editarIndex, setEditarIndex] = useState(0)

  function validarContratos(dados) {
    var data = new Date();
    var errors = [];
    if (dados["codcateg"] === "" || dados["codcateg"] === undefined) {
      errors.push("codcateg");
    }
    setErrors(errors);
    console.log("errors ", errors);
    if (errors.length > 0) {
      toast("Preencha os campos obrigatórios.", { type: "error" });
      return false;
    }
    return true;
  }

  function hasError(key) {
    return errors.indexOf(key) !== -1;
  }

  const cadastrarContrato = () => {
    if (editar) {
      const editedContrato = values;
      var key,
        keys = Object.keys(editedContrato);
      var n = keys.length;
      var newobj = {};
      while (n--) {
        key = keys[n];
        newobj[key.toLowerCase()] = editedContrato[key];
      }
      console.log(newobj);
      if (validarContratos(newobj)) {
        api
          .put(`/ct_pl_categ/${editedContrato.CODCATEG}`, newobj)
          .then((res) => {
            atualizaContratos();
            setShow(false);
          });
      }
    } else {
      //values["CODCATEG"] = rows.values;//.length;
      const createdContrato = values;
      let key,
        keys = Object.keys(createdContrato);
      let n = keys.length;
      let newobj = {};
      while (n--) {
        key = keys[n];
        newobj[key.toLowerCase()] = createdContrato[key];
      }
      if (validarContratos(newobj)) {
        api.post("/ct_pl_categ", newobj).then((res) => {
          atualizaContratos();
          setShow(false);
        });
      }
    }
  };

  function editarContrato() {
    rows.map((row_aux, i) => {
      if (selectedValue === row_aux.CODCATEG) {
        console.log("IIIII ", i);
        const row = rows[i];
        console.log("row ", row);
        const auxEditar = row;
        setShow(true);
        setEditar(true);
        setValues(auxEditar);
        setErrors([]);
      }
    });
  }

  function removerContrato() {
    const removeRow = rows[linhaARemover];
    console.log(removeRow);
    api.delete(`/ct_pl_categ/${removeRow.CODCATEG}`).then((res) => {
      atualizaContratos();
      setShow(false);
    });
    modalDeleteClose();
    atualizaContratos();
  }

  return (
    <>
      <Card>
        <CardHeader title="Categoria de Contrato">
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <form className={classesSelect.root} autoComplete="off">
            <FormControl className={classesSelect.margin}>
              <FormHelperText>Filtrar por Desrição</FormHelperText>
              <BootstrapInput
                onChange={filtrarNome}
                id="age-customized-input"
              />
            </FormControl>
            <div style={{ marginTop: 18 }}>
              <Fab
                title="Adicionar"
                className={classes.fab}
                color="primary"
                onClick={handleShow}
                aria-label="Edit"
              >
                <Icon>add</Icon>
              </Fab>
              <Fab
                title="Editar"
                className={classes.fab}
                color="secondary"
                onClick={() => editarContrato()}
                aria-label="Edit"
              >
                <Icon>edit_icon</Icon>
              </Fab>
              <Fab
                title="Apagar"
                className={classes.fab}
                aria-label="Delete"
                onClick={() => modalDeleteShow()}
              >
                <DeleteIcon />
              </Fab>
            </div>
          </form>
          <Paper className={classes.root}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">Código</TableCell>
                  <TableCell align="center">Descrição</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsBusca.map((row, index) => (
                  <TableRow key={row.CODCATEG}>
                    <TableCell align="center">
                      <Radio
                        checked={selectedValue === row.CODCATEG}
                        onChange={handleChangeRadio}
                        value={row.CODCATEG}
                        color="default"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "D" }}
                      />
                    </TableCell>
                    <TableCell align="center">{row.CODCATEG}</TableCell>
                    <TableCell align="center">{row.DESCRICAO}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </CardBody>
      </Card>
      <Modal size="lg" show={show} onHide={handleClose}>
        <form onSubmit={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Cadastro de Categorias de Contrato</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextField autoComplete="off"
              required
              onChange={handleChangeModal}
              name="CODCATEG"
              value={values.CODCATEG}
              id="outlined-multiline-flexible"
              label="Código da Categoria"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              error={hasError("codcateg")}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DESCRICAO"
              value={values.DESCRICAO}
              id="outlined-multiline-flexible"
              label="Descrição"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button
              className={classes.button}
              onClick={handleSubmit1(cadastrarContrato)}
              type="submit"
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={showDelete} onHide={modalDeleteClose}>
        <form onSubmit={modalDeleteClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Remover categoria</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>Tem certeza que deseja remover a categoria?</h3>
            <br />
            Esta ação <strong>não</strong> pode ser desfeita.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={modalDeleteClose}>
              Não
            </Button>
            <Button
              variant="contained"
              onClick={removerContrato}
              type="button"
              color="primary"
              className={classes.button}
            >
              Sim
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
