export function formatDate(date) {
  const dateTemp = date?.split("-");

  return dateTemp[2] + "/" + dateTemp[1] + "/" + dateTemp[0];
}

export function formatMoney(value) {
  return Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  }).format(value);
}

export function getMonthAbbreviations(dateArray) {
  const monthAbbreviations = {
    "01": "JAN",
    "02": "FEV",
    "03": "MAR",
    "04": "ABR",
    "05": "MAI",
    "06": "JUN",
    "07": "JUL",
    "08": "AGO",
    "09": "SET",
    "10": "OUT",
    "11": "NOV",
    "12": "DEZ",
  };

  const result = [];

  for (const date of dateArray) {
    const parts = date.split("/");
    if (parts.length === 2 && monthAbbreviations[parts[1]]) {
      result.push(monthAbbreviations[parts[1]]);
    } else {
      result.push("Invalid Date");
    }
  }

  return result;
}
