import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../services/api";
/* import { Table, TableBody, TableCell, TableRow } from "@material-ui/core"; */
import moment from "moment";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import parse from "html-react-parser";
import "moment/locale/pt-br";
// import { useParams } from "react-router-dom";

/* moment.locale("pt-br"); */

function formatCurrency(valor) {
  if (valor !== null && valor !== undefined) {
    return valor.replace(".", ",");
  }
  return valor;
}

export function ComponentToContrato({componentRef}) {
  const { idPlano } = useParams();

  const [ctPlano, setCtPlano] = useState([]);
  const [ctPlanoDebito, setCtPlanoDebito] = useState([]);
  const [ctPlanoDepend, setCtPlanoDepend] = useState([]);

  useEffect(() => {
    api.get("/ct_plano/" + idPlano).then((res) => {
      setCtPlano(res.data);
    });

    api.get("/ct_pl_debito/" + idPlano).then((res) => {
      setCtPlanoDebito(res.data);
    });

    api.get("/ct_pl_depend/" + idPlano).then((res) => {
      setCtPlanoDepend(res.data);
    });
  }, [idPlano]);

  let Dep = "<table border=1 cellspacing=0 cellpadding=0 width=100%>";
  Dep = Dep + "<tr>";
  Dep =
    Dep +
    "<td width=33% style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:solid black 1.0pt;background:silver;padding:0cm 0cm 0cm 0cm;height:13.15pt'><p><b>Nome</b></p></td>";
  Dep =
    Dep +
    "<td width=22% style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:solid black 1.0pt;background:silver;padding:0cm 0cm 0cm 0cm;height:13.15pt'><p align=center><b>Grau de parentesco</b></p></td>";
  Dep =
    Dep +
    "<td width=15% style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:solid black 1.0pt;background:silver;padding:0cm 0cm 0cm 0cm;height:13.15pt'><p align=center><b>Nascimento</b></p></td>";
  Dep =
    Dep +
    "<td width=15% style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:solid black 1.0pt;background:silver;padding:0cm 0cm 0cm 0cm;height:13.15pt'><p align=center><b>Sexo</b></p></td>";
  Dep =
    Dep +
    "<td width=15% style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:solid black 1.0pt;background:silver;padding:0cm 0cm 0cm 0cm;height:13.15pt'><p align=center><b>Estado Civil</b></p></td>";
  Dep = Dep + "</tr>";

  ctPlanoDepend.map(
    (rowsDep, index) =>
      (Dep =
        Dep +
        "<tr key=" +
        rowsDep.IDDEP +
        ">" +
        "<td align=left>" +
        rowsDep.NOME +
        "</td>" +
        "<td align=center>" +
        (rowsDep.DESCGRAU != null ? rowsDep.DESCGRAU : '') +
        "</td>" +
        "<td align=center>" +
        (rowsDep.DT_NASC_F != null ? rowsDep.DT_NASC_F : '') +
        "</td>" +
        "<td align=center>" +
        (rowsDep.DESCSEXO != null ? rowsDep.DESCSEXO : '') +
        "</td>" +
        "<td align=center>" +
        (rowsDep.DESCESTCIV != null ? rowsDep.DESCESTCIV : '') +
        "</td>" +
        "</tr>")
  );
  Dep = Dep + "</table>";

  var Deb = "<table border=1 cellspacing=0 cellpadding=0 width=100%>";
  Deb = Deb + "<tr>";
  Deb =
    Deb +
    "<td width=25% style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:solid black 1.0pt;background:silver;padding:0cm 0cm 0cm 0cm;height:13.15pt'><p align=center><b>Categoria</b></p></td>";
  Deb =
    Deb +
    "<td width=25% style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:solid black 1.0pt;background:silver;padding:0cm 0cm 0cm 0cm;height:13.15pt'><p align=center><b>Tipo</b></p></td>";
  Deb =
    Deb +
    "<td width=15% style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:solid black 1.0pt;background:silver;padding:0cm 0cm 0cm 0cm;height:13.15pt'><p align=center><b>Referencia</b></p></td>";
  Deb =
    Deb +
    "<td width=15% style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:solid black 1.0pt;background:silver;padding:0cm 0cm 0cm 0cm;height:13.15pt'><p align=center><b>Vencimento</b></p></td>";
  Deb =
    Deb +
    "<td width=20% style='border:solid black 1.0pt;border-left:none;mso-border-left-alt:solid black 1.0pt;background:silver;padding:0cm 0cm 0cm 0cm;height:13.15pt'><p align=right><b>Valor</b></p></td>";
  Deb = Deb + "</tr>";

  console.log(ctPlanoDebito);

  ctPlanoDebito.map(
    (rowsDeb, index) =>
      (Deb =
        Deb +
        "<tr key=" +
        rowsDeb.IDDEP +
        ">" +
        "<td align=center>" +
        rowsDeb.CATEG +
        "</td>" +
        "<td align=center>" +
        rowsDeb.TIPO +
        "</td>" +
        "<td align=center>" +
        rowsDeb.REFERENCIA +
        "</td>" +
        "<td align=center>" +
        rowsDeb.DT_VENC_F +
        "</td>" +
        "<td align=right>" +
        formatCurrency(rowsDeb.VALOR) +
        "</td>" +
        "</tr>")
  );
  Deb = Deb + "</table>";

  var memo = "`" + ctPlano.IMPCONTRATO + "`";

  var extenso = ctPlano.EMP_CIDADE + " , " + moment().format("LL");

  for (var i = 0; i < 4; i++) {
    memo = memo.replace("_Nome_", ctPlano.NOME != null ? ctPlano.NOME : "");
    memo = memo.replace("_IdPlano_", ctPlano.IDPLANO);
    memo = memo.replace(
      "_CPF_",
      ctPlano.CNPJ_CPF != null
        ? ctPlano.CNPJ_CPF.replace(
            /^(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4"
          )
        : ""
    );
    memo = memo.replace(
      "_Bairro_",
      ctPlano.BAIRRO != null ? ctPlano.BAIRRO : ""
    );
    memo = memo.replace(
      "_Cidade_",
      ctPlano.CIDADE != null ? ctPlano.CIDADE : ""
    );
    memo = memo.replace(
      "_Endereco_",
      ctPlano.ENDERECO != null ? ctPlano.ENDERECO : ""
    );
    memo = memo.replace(
      "_Numero_",
      ctPlano.NUMERO != null ? ctPlano.NUMERO : ""
    );
    memo = memo.replace(
      "_Complemento_",
      ctPlano.COMPLEMENT != null ? ctPlano.COMPLEMENT : ""
    );
    memo = memo.replace("_RG_", ctPlano.IE_RG != null ? ctPlano.IE_RG : "");
    memo = memo.replace(
      "_Estado_",
      ctPlano.ESTADO != null ? ctPlano.ESTADO : ""
    );
    memo = memo.replace("_CEP_", ctPlano.CEP != null ? ctPlano.CEP : "");
    memo = memo.replace("_Fone_", ctPlano.FONE != null ? ctPlano.FONE : "");
    memo = memo.replace(
      "_Celular_",
      ctPlano.CELULAR != null ? ctPlano.CELULAR : ""
    );
    memo = memo.replace("_Fax_", ctPlano.FAX != null ? ctPlano.FAX : "");
    memo = memo.replace(
      "_Contato_",
      ctPlano.CONTATO != null ? ctPlano.CONTATO : ""
    );
    memo = memo.replace(
      "_Nascimento_",
      ctPlano.DT_NASC != null
        ? moment(ctPlano.DT_NASC).format("DD/MM/YYYY")
        : ""
    );
    memo = memo.replace(
      "_Sexo_",
      ctPlano.DESC_SEXO != null ? ctPlano.DESC_SEXO : ""
    );
    memo = memo.replace(
      "_EstadoCivil_",
      ctPlano.DESC_ESTCIVIL != null ? ctPlano.DESC_ESTCIVIL : ""
    );
    memo = memo.replace(
      "_Profissao_",
      ctPlano.PROFISSAO != null ? ctPlano.PROFISSAO : ""
    );
    memo = memo.replace(
      "_VendedorCod_",
      ctPlano.VENDEDOR != null ? ctPlano.VENDEDOR : ""
    );
    memo = memo.replace(
      "_VendedorNome_",
      ctPlano.NOME_VENDEDOR != null ? ctPlano.NOME_VENDEDOR : ""
    );
    memo = memo.replace("_Email_", ctPlano.EMAIL != null ? ctPlano.EMAIL : "");
    memo = memo.replace(
      "_PontoRef_",
      ctPlano.OBSERVACAO != null ? ctPlano.OBSERVACAO : ""
    );
    memo = memo.replace(
      "_NomeEmpresa_",
      ctPlano.EMP_RAZAO_SOCIAL != null ? ctPlano.EMP_RAZAO_SOCIAL : ""
    );
    memo = memo.replace(
      "_CNPJEmpresa_",
      ctPlano.EMP_CNPJ_CPF != null
        ? ctPlano.EMP_CNPJ_CPF.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            "$1.$2.$3/$4-$5"
          )
        : ""
    );
    memo = memo.replace(
      "_FoneEmpresa_",
      ctPlano.EMP_FONE != null ? ctPlano.EMP_FONE : ""
    );
    memo = memo.replace(
      "_InscEEmpresa_",
      ctPlano.EMP_IE_RG != null ? ctPlano.EMP_IE_RG : ""
    );
    memo = memo.replace(
      "_SiteEmpresa_",
      ctPlano.EMP_SITE != null ? ctPlano.EMP_SITE : ""
    );
    memo = memo.replace(
      "_FoneCentralEmpresa_",
      ctPlano.EMP_FONEPLANTAO != null ? ctPlano.EMP_FONEPLANTAO : ""
    );
    memo = memo.replace(
      "_LogoEmpresa_",
      ctPlano.EMP_LOGO != null ? ctPlano.EMP_LOGO : ""
    );
    memo = memo.replace(
      "_EmpAbrangencia_",
      ctPlano.EMP_ABRANGENCIA != null ? ctPlano.EMP_ABRANGENCIA : ""
    );
    //memo = memo.replace("_LogoEmpresaD_", ctPlano.EMP_LOGOD != null ? ctPlano.EMP_LOGOD : "");
    memo = memo.replace("_Dependentes_", Dep != null ? Dep : "");
    memo = memo.replace("_Debitos_", Deb != null ? Deb : "");
    memo = memo.replace("_DataExtenso_", extenso);
    memo = memo.replace("`", "");
  }

  return (
    <div ref={componentRef}>
      <Table>
        <TableBody>
          <TableRow>
            {/* <TableCell colspan="12">{parse(Deb)}</TableCell> */}
            <TableCell colspan="12">{parse(memo)}</TableCell> 
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
