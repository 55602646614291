import React, { useEffect, useState } from "react";

import { Spinner } from "react-bootstrap";
import api from "../../services/api.js";
import { ChartInadiplencia } from "./components/chart-inadiplencia-qtd/index.jsx";
import { ChartStatusContratos } from "./components/chart-status-contratos/index.jsx";
import { ChartVendasXCencelados } from "./components/chart-vendas-cancelados/index.jsx";
import { useStyles } from "./styles.js";

import { ChartBubbles } from "./components/chart-bubbles/index.jsx";
import { ChartEmitidoXRecebido } from "./components/chart-emitido-x-recebido/index.jsx";
import { ChartTopRegisters } from "./components/chart-top-registers/index.jsx";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export function ModulosPage() {
  const styles = useStyles();

  const currentDate = new Date();
  const oneYearBefore = new Date();
  oneYearBefore.setMonth(oneYearBefore.getMonth() - 24);

  const [isLoadingChart1Data, setIsLoadingChart1Data] = useState(false);
  const [isLoadingChart2Data, setIsLoadingChart2Data] = useState(false);

  const [formDataFilter] = useState({
    data_de: formatDate(oneYearBefore),
    data_ate: formatDate(currentDate),
  });

  const [salesVsCancelled, setSalesVsCancelled] = useState({});
  const [issuedVsReceived, setIssuedVsReceived] = useState({});

  const [topVendas, setTopVendas] = useState([]);
  const [topCancelamentos, setTopCancelamentos] = useState([]);

  async function getChartDataSalesVsCancelled() {
    setIsLoadingChart1Data(true);
    try {
      const formdata = new FormData();
      formdata.append("data_de", formDataFilter.data_de);
      formdata.append("data_ate", formDataFilter.data_ate);

      const response = await api.post("/report/salesvscancelled", formdata, {
        redirect: "follow",
      });

      const obj = response.data;
      setSalesVsCancelled(obj);
    } catch (error) {
      console.log(error);
      alert("Erro ao buscar dados de vendas");
    }
    setIsLoadingChart1Data(false);
  }

  async function getReportRankingVendas() {
    try {
      const formdata = new FormData();
      formdata.append("data_de", formDataFilter.data_de);
      formdata.append("data_ate", formDataFilter.data_ate);

      const response = await api.post("/report/ranking", formdata, {
        redirect: "follow",
      });
      setTopVendas(response?.data);
    } catch (error) {
      console.log(error);
      alert("Erro ao buscar Ranking de vendedores");
    }
  }

  async function getReportRankingCancelamentos() {
    try {
      const formdata = new FormData();
      formdata.append("data_de", formDataFilter.data_de);
      formdata.append("data_ate", formDataFilter.data_ate);
      formdata.append("cancel", true);

      const response = await api.post("/report/ranking", formdata, {
        redirect: "follow",
      });

      setTopCancelamentos(response?.data);
    } catch (error) {
      console.log(error);
      alert("Erro ao buscar Ranking de vendedores");
    }
  }

  async function getChartDataIssuedVsReceived() {
    setIsLoadingChart2Data(true);
    try {
      const formdata = new FormData();
      formdata.append("data_de", formDataFilter.data_de);
      formdata.append("data_ate", formDataFilter.data_ate);

      const response = await api.post("/report/issuedvsreceived", formdata, {
        redirect: "follow",
      });

      const obj = response.data;

      setIssuedVsReceived(obj);
    } catch (error) {
      console.log(error);
      alert("Erro ao buscar dados de vendas");
    }
    setIsLoadingChart2Data(false);
  }

  function getApiData() {
    getChartDataSalesVsCancelled();
    getChartDataIssuedVsReceived();
    getReportRankingVendas();
    getReportRankingCancelamentos();
  }

  useEffect(() => {
    getApiData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className={styles.container}>
        {isLoadingChart1Data || isLoadingChart2Data ? (
          <div className={styles.containerLoading}>
            <Spinner color="primary" animation="border" />
          </div>
        ) : (
          <>
            <ChartVendasXCencelados
              salesVsCancelled={salesVsCancelled}
              topVendas={topVendas}
              topCancelamentos={topCancelamentos}
            />
            <ChartEmitidoXRecebido issuedVsReceived={issuedVsReceived} />

            <ChartStatusContratos issuedVsReceived={issuedVsReceived} />
            <ChartInadiplencia issuedVsReceived={issuedVsReceived} />

            <ChartTopRegisters />

            <ChartBubbles />
          </>
        )}
      </div>
    </div>
  );
}
