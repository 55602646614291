import {
  Fab,
  FormControl,
  FormHelperText,
  Icon,
  MenuItem,
  TextField,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import api from "../../services/api";
import * as yup from "yup";

import { useStyles } from "./styles";
import { Relatorio } from "./components/Relatorios";

const reportValidateSchema = yup.object().shape({
  initialDate: yup.date().typeError("Informe uma data"),
  finalDate: yup.date().typeError("Informe uma data"),
});

export function RelatoriosPage() {
  const classes = useStyles();

  const { report_type, start_date, end_date } = useParams();

  const [errors, setErrors] = useState({});
  const [typeReport, setTypeReport] = useState("");
  const [dataReport, setDataReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [formData, setFormData] = useState({
    initial_payment_date: "",
    final_payment_date: "",
    report_type: "analitico",
  });

  function formatDateToRequest(date) {
    const initialDate = date.split("-");
    return initialDate[0] + "-" + initialDate[1] + "-" + initialDate[2];
  }

  async function getDataFromApi() {
    setLoading(true);
    setDataNotFound(false);

    try {
      const response = await api.post("/report", {
        tipo: report_type,
        data_de: formatDateToRequest(start_date),
        data_ate: formatDateToRequest(end_date),
      });

      if (response.data.length > 0) {
        setTypeReport(report_type);
        setDataReport(response.data);
      } else {
        setDataNotFound(true);
      }
    } catch (error) {
      toast.error("Erro ao buscar dados do relatório");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (report_type && start_date && end_date) {
      getDataFromApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getReportData() {
    let dateFormData = {
      initialDate: formData.initial_payment_date,
      finalDate: formData.final_payment_date,
    };

    reportValidateSchema
      .validate(dateFormData, { abortEarly: false })
      .then(() => {
        setErrors({});
        window.open(
          `/relatorios/${formData.report_type}/${formData.initial_payment_date}/${formData.final_payment_date}`,
          "_blank"
        );
      })
      .catch((err) => {
        const inners = err.inner;

        let errors = {};

        inners.forEach((element) => {
          errors = { ...errors, [element.path]: element.errors[0] };
        });

        setErrors(errors);
      });
  }

  return (
    <div>
      <Card>
        <CardHeader title="Relatórios" />

        <CardBody>
          {!report_type ? (
            <div className={classes.form}>
              <FormControl>
                <FormHelperText>Pagamento inicial</FormHelperText>
                <TextField autoComplete="off"
                  type="date"
                  size="small"
                  variant="outlined"
                  style={{ width: 232, marginTop: 0 }}
                  value={formData.initial_payment_date}
                  error={errors.initialDate}
                  helperText={!!errors.initialDate && errors.initialDate}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      initial_payment_date: event.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormHelperText>Pagamento final</FormHelperText>
                <TextField autoComplete="off"
                  type="date"
                  size="small"
                  variant="outlined"
                  style={{ width: 232, marginTop: 0 }}
                  value={formData.final_payment_date}
                  error={errors.finalDate}
                  helperText={!!errors.finalDate && errors.finalDate}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      final_payment_date: event.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormHelperText>Tipo de Relatório</FormHelperText>
                <TextField autoComplete="off"
                  size="small"
                  variant="outlined"
                  select
                  style={{ width: 232, marginTop: 0 }}
                  value={formData.report_type}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      report_type: event.target.value,
                    })
                  }
                >
                  <MenuItem value="analitico" selected>
                    Analítico
                  </MenuItem>
                  <MenuItem value="sintetico">Sintético</MenuItem>
                </TextField>
              </FormControl>
              <Fab
                style={{ width: 35, height: 35, top: 17 }}
                color="primary"
                onClick={getReportData}
              >
                <Icon>search</Icon>
              </Fab>
            </div>
          ) : null}

          <Relatorio
            data={dataReport}
            type={typeReport}
            dataNotFound={dataNotFound}
            loading={loading}
            initialDate={start_date}
            finalDate={end_date}
          />
        </CardBody>
      </Card>

      <ToastContainer />
    </div>
  );
}
