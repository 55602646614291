export function obterUltimosValoresArray(array, quantidade) {
  if (quantidade < 1) {
    return [];
  }
  if (quantidade >= array.length) {
    return array.slice();
  }

  return array.slice(-quantidade);
}

export function pegarPrimeiraMetadeDoArray(array) {
  const metade = Math.floor(array.length / 2);
  return array.slice(0, metade);
}

export function calcularPorcentagem(valorAtual, valorAnterior) {
  if (valorAnterior === 0) {
    return "Impossível calcular a porcentagem, o valor anterior é zero.";
  }

  const diferenca = valorAtual - valorAnterior;
  const porcentagem = (diferenca / valorAnterior) * 100;

  return `${porcentagem.toFixed(2)}%`;
}

export function extrairValorNumericoPorcentagem(string) {
  const regex = /(-?\d+(\.\d+)?)%/;
  const match = string.match(regex);
  if (match) {
    const valorNumerico = parseFloat(match[1]);
    return valorNumerico;
  }
  return null;
}
