import { makeStyles } from "@material-ui/core";

export const useStylesSelect = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
  footer: {
    margin: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  box: {
    margin: theme.spacing(3),
  },
}));
