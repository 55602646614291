import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  // baseURL: "http://localhost:4200",
  baseURL: "https://memorium-api.cocreare.etc.br",
  headers: {
    "content-type": "application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  // console.log(" config.headers ",  config.headers);
  if (token) {
    config.headers["X-Token"] = `${token}`;
  }
  return config;
});

export default api;
