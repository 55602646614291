import { Fab, Icon } from "@material-ui/core";
import React, { useRef, useCallback } from "react";

import { useReactToPrint } from "react-to-print";
import { getAtualDate, getAtualHour } from "../../../../../utils/atualDateHour";
import { formatDate, formatMoney } from "../../../../../utils/formats";

import styles from "./styles.module.css";

export function RelatorioSintetico(props) {
  const { data = [], initialDate, finalDate } = props;
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: { padding: 20 },
  });

  const totalCels = useCallback((itens = [], field) => {
    return itens.reduce((acc, item) => acc + Number(item[field]), 0).toFixed(2);
  }, []);

  const totalRow = useCallback((...n) => {
    let valor = n.reduce(function(valorAcumulado, valorAtual) {
      return (valorAcumulado += Number(valorAtual));
    }, 0);

    return valor;
  }, []);

  const totalIndividualRows = [];

  const sumTotalRows = useCallback(() => {
    data.forEach((item) => {
      totalIndividualRows.push(
        Number(item.Valor_Insc) +
          Number(item.Valor_Manut) +
          Number(item.VlTotal_Negocio)
      );
    });

    return totalIndividualRows.reduce((acc, atual) => acc + atual, 0);
  }, [data, totalIndividualRows]);

  if (!data.length) return null;

  return (
    <div>
      <div
        style={{
          textAlign: "right",
          marginBottom: -60,
          marginTop: 50,
        }}
      >
        <Fab
          color="info"
          onClick={handlePrint}
          style={{ width: 35, height: 5 }}
        >
          <Icon>print</Icon>
        </Fab>
      </div>

      <div ref={componentRef}>
        <div className={styles.container_report}>
          <div className={styles.header_report}>
            <div className={styles.line} />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
                width: "100%",
              }}
            >
              <img
                alt="logo da empresa"
                src={
                  data[0]?.LogoEmpresa ??
                  "https://memorium.cocreare.etc.br/media/logos/logo-letter-2.png"
                }
                style={{ width: 60, height: 60, objectFit: "contain" }}
              />
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 className={styles.title_report}>
                  Fluxo de Recebimento Sintético
                </h1>
                <h2 className={styles.subtitle_report}>
                  (Por Mês de Pagamento)
                </h2>
              </div>
              <div style={{ width: 60 }} />
            </div>

            <div className={styles.line} />

            <div className={styles.time_container}>
              <p>{getAtualDate()}</p>
              <p>{getAtualHour()}</p>
            </div>
            <div className={styles.line} />

            <div className={styles.info_container}>
              <div>
                <div className={styles.item_info_title}>
                  <p>Pag. Inicial</p>
                  <span>{formatDate(initialDate)}</span>
                </div>
                <div className={styles.item_info_title}>
                  <p>Pag. Inicial</p>
                  <span>{formatDate(finalDate)}</span>
                </div>
                <div className={styles.item_info_title}>
                  <p>Arq. Morto</p>
                  <span>N - Não</span>
                </div>
              </div>
              <div>
                <div className={styles.item_info_title}>
                  <p>Cancelado</p>
                  <span>N - Não</span>
                </div>
                <div className={styles.item_info_title}>
                  <p>Empresarial</p>
                  <span>N - Não</span>
                </div>
              </div>
            </div>
          </div>

          <table className={styles.table_reports}>
            <thead>
              <tr className={styles.t_row}>
                <th className={`${styles.t_head} ${styles.center}`}>Data</th>

                <th colSpan="3" className={`${styles.t_head} ${styles.center}`}>
                  Adesões
                </th>

                <th colSpan="3" className={`${styles.t_head} ${styles.center}`}>
                  Manutenções
                </th>

                <th colSpan="3" className={`${styles.t_head} ${styles.center}`}>
                  Negociações
                </th>

                <th className={`${styles.t_head} ${styles.center}`}>
                  Total Geral
                </th>
              </tr>

              <tr className={styles.t_row}>
                <th className={`${styles.t_head} ${styles.center}`}>
                  Pagamento
                </th>

                <th className={`${styles.t_head} ${styles.center}`}>
                  Recebidos
                </th>
                <th className={`${styles.t_head} ${styles.center}`}>Juros</th>
                <th className={`${styles.t_head} ${styles.center}`}>Total</th>

                <th className={`${styles.t_head} ${styles.center}`}>
                  Recebidos
                </th>
                <th className={`${styles.t_head} ${styles.center}`}>Juros</th>
                <th className={`${styles.t_head} ${styles.center}`}>Total</th>

                <th className={`${styles.t_head} ${styles.center}`}>
                  Recebidos
                </th>
                <th className={`${styles.t_head} ${styles.center}`}>Juros</th>
                <th className={`${styles.t_head} ${styles.center}`}>Total</th>

                <th className={`${styles.t_head} ${styles.center}`}>
                  Valor Pago
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr className={styles.t_row} key={index}>
                  <td className={`${styles.t_data} ${styles.center}`}>
                    {item.MesAnoPgto.split("-")[1]}/
                    {item.MesAnoPgto.split("-")[0]}
                  </td>

                  <td className={`${styles.t_data} ${styles.right}`}>
                    {formatMoney(item.VlOrig_Insc)}
                    {}
                  </td>
                  <td className={`${styles.t_data} ${styles.right}`}>
                    {formatMoney(item.Juros_Insc)}
                  </td>
                  <td className={`${styles.t_data} ${styles.right}`}>
                    {formatMoney(item.Valor_Insc)}
                  </td>

                  <td className={`${styles.t_data} ${styles.right}`}>
                    {formatMoney(item.VlOrig_Manut)}
                  </td>
                  <td className={`${styles.t_data} ${styles.right}`}>
                    {formatMoney(item.Juros_Manut)}
                  </td>
                  <td className={`${styles.t_data} ${styles.right}`}>
                    {formatMoney(item.Valor_Manut)}
                  </td>

                  <td className={`${styles.t_data} ${styles.right}`}>
                    {formatMoney(item.VlOrig_Negocio)}
                  </td>
                  <td className={`${styles.t_data} ${styles.right}`}>
                    {formatMoney(item.Juros_Negocio)}
                  </td>
                  <td className={`${styles.t_data} ${styles.right}`}>
                    {formatMoney(item.VlTotal_Negocio)}
                  </td>

                  <td className={`${styles.t_data} ${styles.right}`}>
                    {formatMoney(
                      totalRow(
                        item.Valor_Insc ?? 0,
                        item.Valor_Manut ?? 0,
                        item.VlTotal_Negocio ?? 0
                      )
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className={styles.t_row}>
                <td className={styles.center}>TOTAL</td>

                <td className={`${styles.t_data} ${styles.right}`}>
                  {formatMoney(totalCels(data, "VlOrig_Insc"))}
                </td>
                <td className={`${styles.t_data} ${styles.right}`}>
                  {formatMoney(totalCels(data, "Juros_Insc"))}
                </td>
                <td className={`${styles.t_data} ${styles.right}`}>
                  {formatMoney(totalCels(data, "Valor_Insc"))}
                </td>

                <td className={`${styles.t_data} ${styles.right}`}>
                  {formatMoney(totalCels(data, "VlOrig_Manut"))}
                </td>
                <td className={`${styles.t_data} ${styles.right}`}>
                  {formatMoney(totalCels(data, "Juros_Manut"))}
                </td>
                <td className={`${styles.t_data} ${styles.right}`}>
                  {formatMoney(totalCels(data, "Valor_Manut"))}
                </td>

                <td className={`${styles.t_data} ${styles.right}`}>
                  {formatMoney(totalCels(data, "VlOrig_Negocio"))}
                </td>
                <td className={`${styles.t_data} ${styles.right}`}>
                  {formatMoney(totalCels(data, "Juros_Negocio"))}
                </td>
                <td className={`${styles.t_data} ${styles.right}`}>
                  {formatMoney(totalCels(data, "VlTotal_Negocio"))}
                </td>

                <td className={`${styles.t_data} ${styles.right}`}>
                  {formatMoney(sumTotalRows())}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}
