import React from "react";

import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";

import Typography from '@material-ui/core/Typography';

export function ManutencaoPage() {

    return (
      <div>
        <br/>
        <br/>
        <br/>
        <br/>
        <Typography variant="h3" gutterBottom style={{ textAlign: 'center' }}>
        <SVG height="70" src={toAbsoluteUrl("/media/svg/icons/Home/servico.svg")}/>
        <br/>
        <br/>
          Em Manutenção
      </Typography>
      </div >
    );

}