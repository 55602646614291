/* eslint-disable */

import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import api from "../services/api";
import {
  FormControl,
  Button,
  TextField,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
} from "@material-ui/core";

import { Modal } from "react-bootstrap";

import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";

import { cpf } from "cpf-cnpj-validator";
import { cnpj } from "cpf-cnpj-validator";

import { toast, ToastContainer } from "react-toastify";

var somenteleitura = false;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },

  tableA: {
    backgroundColor: "#DCDCDC", //'#D3D3D3',
  },

  tableB: {
    backgroundColor: "#FFFFFF",
  },

  tableC: {
    backgroundColor: "#FFC0CB", //'#E9967A', // '#FA8072',
    color: "#FFFFFF",
  },

  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    marginRight: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  fab: {
    margin: theme.spacing(1),
    width: 35,
    height: 5,
  },

  modalContratos: {
    maxWidth: 900,
  },
}));

export function RelacaoDebitoPage() {
  const classes = useStyles();

  const [errors, setErrors] = useState([]);
  const [show, setShow] = useState(false);
  const [DebitosData, setDebitosData] = useState(false);
  const [DebitosDatabaixar, setDebitosDatabaixar] = useState(false);
  const [pegaVariaveis, setpegaVariaveis] = useState(false);
  const [DebitosDatadesbaixar, setDebitosDatadesbaixar] = useState(false);
  const [DebitosDataInsert, setDebitosDataInsert] = useState(false);
  const [InfosData, setInfosData] = useState(false);
  const [TaxasData, setTaxasData] = useState(false);
  const [DependentesData, setDependentesData] = useState(false);
  const [editar, setEditar] = useState(false);
  const [editarDebitos, setEditarDebitos] = useState(false);
  const [baixarDebitos, setbaixarDebitos] = useState(false);
  const [desbaixarDebitos, setdesbaixarDebitos] = useState(false);
  const [editarInfos, setEditarInfos] = useState(false);
  const [editarTaxas, setEditarTaxas] = useState(false);
  const [editarDependentes, setEditarDependentes] = useState(false);
  const handleClose = () => setShow(false);
  const handleDebitosData = () => setDebitosData(false);
  const handleDebitosDatabaixar = () => setDebitosDatabaixar(false);
  const handleDebitosDataInsert = () => setDebitosDataInsert(false);
  const handleInfosData = () => setInfosData(false);
  const handleTaxasData = () => setTaxasData(false);
  const handleDependentesData = () => setDependentesData(false);
  const handleCloseDebitos = () => setShowDebitos(false);
  const handleCloseDebitosC = () => setShowDebitosC(false);
  const handleCloseInfos = () => setShowInfos(false);
  const handleCloseTaxas = () => setShowTaxas(false);
  const handleCloseDependentes = () => setShowDependentes(false);
  const [categorias, setCategorias] = useState([]);
  const [debitotp, setDebitotp] = useState([]);
  const [infotp, setInfotp] = useState([]);
  const [taxatp, setTaxatp] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [tpvendas, settpVendas] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [graus, setGraus] = useState([]);
  const [sexo, setSexo] = useState([]);
  const [religiao, setReligiao] = useState([]);
  const [vendedor, setVendedor] = useState([]);
  const [convenio, setConvenio] = useState([]);
  const [variaveis, setVariaveis] = useState([]);
  const [estcivil, setEstcivil] = useState([]);
  const [deptp, setDeptp] = useState([]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleShow = () => {
    setValues({});
    setErrors([]);
    somenteleitura = false;
    api.get("/ct_pl_categ").then((res) => {
      console.log("ct_pl_categ  ", JSON.parse(JSON.stringify(res.data)));
      setCategorias(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/ct_pl_grupo").then((res) => {
      console.log("ct_pl_grupo  ", JSON.parse(JSON.stringify(res.data)));
      setGrupos(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/ct_pl_vendatp").then((res) => {
      console.log("ct_pl_vendatp  ", JSON.parse(JSON.stringify(res.data)));
      settpVendas(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/ct_pl_familia").then((res) => {
      console.log("ct_pl_familia  ", JSON.parse(JSON.stringify(res.data)));
      setFamilias(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/ct_pl_grau").then((res) => {
      console.log("ct_pl_grau  ", JSON.parse(JSON.stringify(res.data)));
      setGraus(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/un_sexo").then((res) => {
      console.log("un_sexo  ", JSON.parse(JSON.stringify(res.data)));
      setSexo(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/un_religiao").then((res) => {
      console.log("un_religiao  ", JSON.parse(JSON.stringify(res.data)));
      setReligiao(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/un_estcivil").then((res) => {
      console.log("un_estcivil  ", JSON.parse(JSON.stringify(res.data)));
      setEstcivil(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/ct_pl_vendedor").then((res) => {
      console.log("ct_pl_vendedor  ", JSON.parse(JSON.stringify(res.data)));
      setVendedor(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/cobrconv").then((res) => {
      console.log("cobrconv  ", JSON.parse(JSON.stringify(res.data)));
      setConvenio(res.data);
      setShow(true);
      setEditar(false);
    });

    api.get("/variaveis").then((res) => {
      console.log("variaveis  ", JSON.parse(JSON.stringify(res.data)));
      setVariaveis(res.data);
      setShow(true);
      setEditar(false);
    });
  };

  const handleDebitos = () => {
    api.get("/ct_pl_debitotp").then((res) => {
      console.log("ct_pl_debitotp  ", JSON.parse(JSON.stringify(res.data)));
      setDebitotp(res.data);
      setValues({});
      setDebitosData(true);
      setEditarDebitos(false);
      setShowDebitos(false);
    });
  };

  const handleDebitosInsert = () => {
    api.get("/ct_pl_categ").then((res) => {
      console.log("ct_pl_categ  ", JSON.parse(JSON.stringify(res.data)));
      setCategorias(res.data);
      setValues({});
      setDebitosDataInsert(true);
      setEditarDebitos(false);
      setShowDebitosInsert(false);
    });
  };

  const handleInfos = () => {
    api.get("/ct_pl_infotp").then((res) => {
      console.log("ct_pl_infotp", JSON.parse(JSON.stringify(res.data)));
      setInfotp(res.data);
      setValues({});
      setInfosData(true);
      setEditarInfos(false);
      setShowInfos(false);
    });
  };

  const handleTaxas = () => {
    api.get("/ct_pl_taxatp").then((res) => {
      console.log("ct_pl_taxatp", JSON.parse(JSON.stringify(res.data)));
      setTaxatp(res.data);
      setValues({});
      setTaxasData(true);
      setEditarTaxas(false);
      setShowTaxas(false);
    });
  };

  const handleDependencias = () => {
    setValues({});
    setDependentesData(true);
    setEditarDependentes(false);
    setShowDependentes(false);
  };

  function atualizaContratos() {
    api.get("/ct_plano").then((res) => {
      console.log("ct_plano ", JSON.parse(JSON.stringify(res.data)));
      res.data.map((data_plano, i) => {
        if (i === 0) {
          setSelectedValue(data_plano.IDPLANO);
        }
        data_plano.IS_SHOW_ACTION = false;
      });
      setRows(res.data);
      setRowsBusca(res.data);
    });
  }

  function formatCurrency(valor) {
    if (valor !== null && valor !== undefined) {
      return valor.replace(".", ",");
    }
    return valor;
  }

  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteDebito, setShowDeleteDebito] = useState(false);
  const [showDesbaixaDebito, setShowDesbaixaDebito] = useState(false);
  const [showDeleteInfo, setShowDeleteInfo] = useState(false);
  const [showDeleteTaxa, setShowDeleteTaxa] = useState(false);
  const [showDeleteDependencia, setShowDeleteDependencia] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [showDebitos, setShowDebitos] = useState(false);
  const [showDebitosC, setShowDebitosC] = useState(false);
  const [showDebitosbaixar, setShowDebitosbaixar] = useState(false);
  const [showDebitosdesbaixar, setShowDebitosdesbaixar] = useState(false);
  const [showDebitosInsert, setShowDebitosInsert] = useState(false);
  const [showInfos, setShowInfos] = useState(false);
  const [showTaxas, setShowTaxas] = useState(false);
  const [showDependentes, setShowDependentes] = useState(false);

  const [showVariaveis, setShowVariaveis] = useState(false);

  const [planoAtual, setPlanoAtual] = useState();
  const [linhaARemover, setLinhaARemover] = useState();
  const [debitoARemover, setDebitoARemover] = useState();
  const [debitoADesbaixar, setDebitoADesbaixar] = useState();
  const [InfosARemover, setInfosARemover] = useState();
  const [TaxasARemover, setTaxasARemover] = useState();
  const [dependenteARemover, setDependenteARemover] = useState();
  const modalDeleteClose = () => setShowDelete(false);
  const modalDeleteDebitoClose = () => setShowDeleteDebito(false);
  const modalDesbaixaDebitoClose = () => setShowDesbaixaDebito(false);
  const modalDeleteInfoClose = () => setShowDeleteInfo(false);
  const modalDeleteTaxaClose = () => setShowDeleteTaxa(false);
  const modalDeleteDependenciaClose = () => setShowDeleteDependencia(false);

  function modalDeleteShow() {
    console.log(selectedValue);
    setShowDelete(true);
    rows.map((row_aux, i) => {
      if (selectedValue === row_aux.IDPLANO) {
        console.log("i", i);
        setLinhaARemover(i);
      }
    });
  }

  function modalDeleteDebitoShow(index) {
    setDebitoARemover(index);
    console.log(debitoARemover);
    setShowDeleteDebito(true);
    setShowDebitos(false);
  }

  function modalDesbaixaDebitoShow(index) {
    setDebitoADesbaixar(index);
    console.log(debitoADesbaixar);
    setShowDesbaixaDebito(true);
    setShowDebitos(false);
    //setShowDebitos(true);
  }

  function modalDeleteInfoShow(index) {
    setInfosARemover(index);
    console.log(InfosARemover);
    setShowDeleteInfo(true);
    setShowInfos(false);
  }

  function modalDeleteTaxaShow(index) {
    setTaxasARemover(index);
    console.log(TaxasARemover);
    setShowDeleteTaxa(true);
    setShowTaxas(false);
  }

  function modalDeleteDependenciaShow(index) {
    setDependenteARemover(index);
    setShowDeleteDependencia(true);
    setShowDependentes(false);
    console.log(linhaARemover);
  }

  function modalDebitosShow(index) {
    //    idPlanoCob = [...rows][index].IDPLANO;
    let idPlanoSelecionado = [...rows][index].IDPLANO;
    api.get("/ct_pl_debito/" + idPlanoSelecionado).then((resDebito) => {
      setPlanoAtual(idPlanoSelecionado);
      setFilteredDebitos(resDebito.data);
      setShowDebitos(true);
    });
  }

  function modalDebitosCobShow(index) {
    //let idPlanoSelecionado = [...rows][index].IDPLANO;
    let idPlanoSelecionado = [...rows][index].IDPLANO;
    api.get("/ct_pl_debitocob/" + idPlanoSelecionado).then((resDebito) => {
      setPlanoAtual(idPlanoSelecionado);
      setFilteredDebitos(resDebito.data);
      setShowDebitosC(true);
    });
  }

  function modalInfosShow(index) {
    let idPlanoSelecionado = [...rows][index].IDPLANO;
    api.get("/ct_pl_info/" + idPlanoSelecionado).then((resInfo) => {
      setPlanoAtual(idPlanoSelecionado);
      setFilteredInfos(resInfo.data);
      setShowInfos(true);
    });
  }

  function modalTaxasShow(index) {
    let idPlanoSelecionado = [...rows][index].IDPLANO;
    api.get("/ct_pl_taxa/" + idPlanoSelecionado).then((resTaxa) => {
      setPlanoAtual(idPlanoSelecionado);
      setFilteredTaxas(resTaxa.data);
      setShowTaxas(true);
    });
  }

  function showOrHideActions(index, value) {
    const newRow = [...rows];
    const row = newRow[index];
    row.IS_SHOW_ACTION = !value;
    setRows(rows.map((item) => (item.IDPLANO === index ? row : item)));
  }

  function modalDependentesShow(index) {
    let idPlanoSelecionado = [...rows][index].IDPLANO;
    api.get("/ct_pl_depend/" + idPlanoSelecionado).then((resDependentes) => {
      setPlanoAtual(idPlanoSelecionado);
      console.log(
        "ct_pl_depend",
        JSON.parse(JSON.stringify(resDependentes.data))
      );
      setFilteredDependentes(resDependentes.data);
      setShowDependentes(true);
    });
    //aqui
    api.get("/ct_pl_grau").then((resDependentes) => {
      console.log(
        "ct_pl_grau  ",
        JSON.parse(JSON.stringify(resDependentes.data))
      );
      setGraus(resDependentes.data);
      setDependentesData(false);
      setEditarDependentes(false);
      setShowDependentes(true);
    });
    api.get("/un_sexo").then((resDependentes) => {
      console.log("un_sexo  ", JSON.parse(JSON.stringify(resDependentes.data)));
      setSexo(resDependentes.data);
      setDependentesData(false);
      setEditarDependentes(false);
      setShowDependentes(true);
    });
    api.get("/un_religiao").then((resDependentes) => {
      console.log(
        "un_religiao  ",
        JSON.parse(JSON.stringify(resDependentes.data))
      );
      setReligiao(resDependentes.data);
      setDependentesData(false);
      setEditarDependentes(false);
      setShowDependentes(true);
    });
    api.get("/un_estcivil").then((resDependentes) => {
      console.log(
        "un_estcivil  ",
        JSON.parse(JSON.stringify(resDependentes.data))
      );
      setEstcivil(resDependentes.data);
      setDependentesData(false);
      setEditarDependentes(false);
      setShowDependentes(true);
    });
    api.get("/ct_pl_deptp").then((resDependentes) => {
      console.log(
        "ct_pl_deptp  ",
        JSON.parse(JSON.stringify(resDependentes.data))
      );
      setDeptp(resDependentes.data);
      setDependentesData(false);
      setEditarDependentes(false);
      setShowDependentes(true);
    });
    //até aqui
  }

  const [rows, setRows] = useState([]);
  const [rowsBusca, setRowsBusca] = useState([]);
  const [filteredDependentes, setFilteredDependentes] = useState([]);
  const [filteredDebitos, setFilteredDebitos] = useState([]);
  const [filteredVariaveis, setFilteredVariaveis] = useState([]);
  const [filteredInfos, setFilteredInfos] = useState([]);
  const [filteredTaxas, setFilteredTaxas] = useState([]);

  const [selectedValue, setSelectedValue] = useState("");
  function handleChangeRadio(event) {
    setSelectedValue(event.currentTarget.value);
  }
  useEffect(() => {
    atualizaContratos();
    api.get("/ct_pl_categ").then((res) => {
      setCategorias(res.data);
    });
    api.get("/ct_pl_grupo").then((res) => {
      setGrupos(res.data);
    });
    api.get("/ct_pl_vendatp").then((res) => {
      settpVendas(res.data);
    });
    api.get("/ct_pl_familia").then((res) => {
      setFamilias(res.data);
    });
    api.get("/ct_pl_grau").then((res) => {
      setGraus(res.data);
    });
    api.get("/un_sexo").then((res) => {
      setSexo(res.data);
    });
    api.get("/un_religiao").then((res) => {
      setReligiao(res.data);
    });
    api.get("/un_estcivil").then((res) => {
      setEstcivil(res.data);
    });
    api.get("/ct_pl_vendedor").then((res) => {
      setVendedor(res.data);
    });
    api.get("/cobrconv").then((res) => {
      setConvenio(res.data);
    });
    api.get("/variaveis").then((res) => {
      setVariaveis(res.data);
    });
  }, []);

  const filtrarNome = (event) => {
    const name = event.target.value.toLowerCase();
    const auxBusca = rows.filter((row) =>
      row.NOME.toLowerCase().includes(name)
    );
    if (name) {
      setRowsBusca(auxBusca);
    } else {
      setRowsBusca(rows);
    }
  };
  const filtrarEndereco = (event) => {
    const cidade = event.target.value.toLowerCase();
    const auxBusca = rows.filter((row) =>
      row.CIDADE.toLowerCase().includes(cidade)
    );
    if (cidade) {
      setRowsBusca(auxBusca);
    } else {
      setRowsBusca(rows);
    }
  };

  const [values, setValues] = useState({});

  const handleChangeModal = (event) => {
    const auxValues = { ...values };
    console.log("VALOR ", event.target.value);
    auxValues[event.target.name] = event.target.value;
    setValues(auxValues);
  };

  const handleChangeModalCurrency = (event) => {
    var v = event.target.value;
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    event.target.value = v;

    const auxValues = { ...values };
    console.log("VALOR ", event.target.value);
    auxValues[event.target.name] = event.target.value;
    setValues(auxValues);
  };

  const maskCurrency = (event) => {
    var v = event.target.value;
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    event.target.value = v;
  };

  const handleSubmit1 = (callback) => (event) => {
    event.preventDefault();
    callback();
  };

  const [editarIndex, setEditarIndex] = useState(0);
  const [baixarIndex, setbaixarIndex] = useState(0);
  //const [variaveisIndex, setVariaveisIndex] = useState(0)
  //const [desbaixarIndex, setdesbaixarIndex] = useState(0)

  function validarDependencia(dados) {
    var errors = [];
    if (dados["nome"] === "" || dados["nome"] === undefined) {
      errors.push("nome");
    }
    //Esta validação de CPF permite cpf em branco
    if (
      dados["cnpj_cpf"] !== "" &&
      dados["cnpj_cpf"] !== undefined &&
      !(cpf.isValid(dados["cnpj_cpf"]) || cnpj.isValid(dados["cnpj_cpf"]))
    ) {
      console.log("IFFF");
      errors.push("cnpj_cpf");
    }
    setErrors(errors);
    if (errors.length > 0) {
      toast("Preencha os campos obrigatórios.", { type: "error" });
      return false;
    }
    return true;
  }

  function hasError(key) {
    return errors.indexOf(key) !== -1;
  }

  function validarContratos(dados) {
    var data = new Date();
    var errors = [];
    if (dados["vendadt"] === "" || dados["vendadt"] === undefined) {
      errors.push("vendadt");
    }
    console.log("calculo ", data);
    if (
      dados["emissaotp"] === "" ||
      dados["emissaotp"] === " " ||
      dados["emissaotp"] === undefined
    ) {
      errors.push("emissaotp");
    }
    if (
      dados["dt_nasc"] === "" ||
      dados["dt_nasc"] === undefined ||
      dados["dt_nasc"] <= data - 6570
    ) {
      errors.push("dt_nasc");
    }
    if (
      dados["nome"] === "" ||
      dados["nome"] === " " ||
      dados["nome"] === undefined
    ) {
      errors.push("nome");
    }
    //Esta validação de CPF não permite cpf em branco
    if (
      dados["cnpj_cpf"] !== "" &&
      dados["cnpj_cpf"] !== undefined &&
      !(cpf.isValid(dados["cnpj_cpf"]) || cnpj.isValid(dados["cnpj_cpf"]))
    ) {
      // if (dados['cnpj_cpf'] == "" || dados['cnpj_cpf'] !== undefined || !(cpf.isValid(dados['cnpj_cpf']) || cnpj.isValid(dados['cnpj_cpf']))) {
      console.log("IFFF");
      errors.push("cnpj_cpf");
    }
    setErrors(errors);
    console.log("errors ", errors);
    if (errors.length > 0) {
      toast("Preencha os campos obrigatórios.", { type: "error" });
      return false;
    }
    return true;
  }

  const cadastrarContrato = () => {
    if (editar) {
      const editedContrato = values;
      var key,
        keys = Object.keys(editedContrato);
      var n = keys.length;
      var newobj = {};
      while (n--) {
        key = keys[n];
        newobj[key.toLowerCase()] = editedContrato[key];
      }
      console.log(newobj);
      if (validarContratos(newobj)) {
        api.put(`/ct_plano/${editedContrato.IDPLANO}`, newobj).then((res) => {
          atualizaContratos();
          setShow(false);
        });
      }
    } else {
      values["IDPLANO"] = rows.length + 1;
      const createdContrato = values;
      let key,
        keys = Object.keys(createdContrato);
      let n = keys.length;
      let newobj = {};
      while (n--) {
        key = keys[n];
        newobj[key.toLowerCase()] = createdContrato[key];
      }
      if (validarContratos(newobj)) {
        api.post("/ct_plano", newobj).then((res) => {
          atualizaContratos();
          setShow(false);
        });
        values["IDDEP"] = filteredDependentes.length + 1;
        const createdDependente = values;
        createdDependente.IDPLANO = values["IDPLANO"] = rows.length + 1;
        let key,
          keys = Object.keys(createdDependente);
        let n = keys.length;
        let sendDependente = {};
        while (n--) {
          key = keys[n];
          sendDependente[key.toLowerCase()] = createdDependente[key];
        }
        console.log(sendDependente);
        if (validarDependencia(sendDependente)) {
          api.post(`/ct_pl_depend`, sendDependente);
          setDependentesData(false);
        }
      }
    }
  };

  return (
    <>
      <form autoComplete="off">
        <Modal.Header closeButton>
          <Modal.Title>Filtro Relatório "Relação de Débito"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>Vencimento</b>
          <hr />
          <TextField autoComplete="off"
            //required
            onChange={handleChangeModal}
            name="VCTINI"
            //value=""
            id="outlined-multiline-flexible"
            label="Data de Pagamento Inicial"
            type="date"
            rowsMax="4"
            //onBlur={(ev) => somaData(values.VENDADT)}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 232 }}
            //error={hasError("vendadt")}
          />

          <TextField autoComplete="off"
            //required
            onChange={handleChangeModal}
            name="VCTFIM"
            //value=""
            id="outlined-multiline-flexible"
            label="Data de Pagamento Final"
            type="date"
            rowsMax="4"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 232 }}
          />
          <hr />
          <b>Geração do Débito</b>
          <hr />
          <TextField autoComplete="off"
            //required
            onChange={handleChangeModal}
            name="GINI"
            //value=""
            id="outlined-multiline-flexible"
            label="Geração Inicial"
            type="date"
            rowsMax="4"
            //onBlur={(ev) => somaData(values.VENDADT)}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 232 }}
            //error={hasError("vendadt")}
          />

          <TextField autoComplete="off"
            //required
            onChange={handleChangeModal}
            name="GFIM"
            //value=""
            id="outlined-multiline-flexible"
            label="Geração Final"
            type="date"
            rowsMax="4"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 232 }}
          />
          <hr />
          <b>Emissão</b>
          <hr />
          <TextField autoComplete="off"
            //required
            onChange={handleChangeModal}
            name="EINI"
            //value=""
            id="outlined-multiline-flexible"
            label="Emissão Inicial"
            type="date"
            rowsMax="4"
            //onBlur={(ev) => somaData(values.VENDADT)}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 232 }}
            //error={hasError("vendadt")}
          />

          <TextField autoComplete="off"
            //required
            onChange={handleChangeModal}
            name="EFIM"
            //value=""
            id="outlined-multiline-flexible"
            label="Emissão Final"
            type="date"
            rowsMax="4"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 232 }}
          />
          <hr />
          <b>Plano</b>
          <hr />
          <TextField autoComplete="off"
            //required
            onChange={handleChangeModal}
            name="VNDINI"
            //value=""
            id="outlined-multiline-flexible"
            label="Data da Venda Inicial"
            type="date"
            rowsMax="4"
            //onBlur={(ev) => somaData(values.VENDADT)}
            className={classes.textField}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 232 }}
            //error={hasError("vendadt")}
          />

          <TextField autoComplete="off"
            //required
            onChange={handleChangeModal}
            name="VNDFIM"
            //value=""
            id="outlined-multiline-flexible"
            label="Data da Venda Final"
            type="date"
            rowsMax="4"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 232 }}
          />
          <br />
          <FormControl
            className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
            style={{ width: 232, marginLeft: 0, marginRight: 16 }}
          >
            <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
              Grupo
            </InputLabel>
            <Select
              value={values.GRUPO ? values.GRUPO : " "}
              name="GRUPO"
              label="Grupo"
              onChange={handleChangeModal}
              style={{ width: 232 }}
              className={classes.textField}
              input={
                <OutlinedInput
                  name="ageg"
                  id="outlined-age-simple"
                  label="Grupo"
                />
              }
            >
              {grupos.map((row, index) => (
                <MenuItem value={row.CODGRUPO} key={index}>
                  {row.DESCRICAO}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
            style={{ width: 232, marginLeft: 0, marginRight: 16 }}
          >
            <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
              Categoria
            </InputLabel>
            <Select
              value={values.CATEGORIA ? values.CATEGORIA : " "}
              name="CATEGORIA"
              label="Categoria"
              onChange={handleChangeModal}
              style={{ width: 232 }}
              className={classes.textField}
              input={
                <OutlinedInput
                  name="age"
                  id="outlined-age-simple"
                  label="Categoria"
                />
              }
            >
              {categorias.map((row, index) => (
                <MenuItem value={row.CODCATEG} key={index}>
                  {row.DESCRICAO}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <FormControl
            className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
            style={{ width: 232, marginLeft: 0, marginRight: 16 }}
          >
            <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
              Familia
            </InputLabel>
            <Select
              value={values.FAMILIA ? values.FAMILIA : " "}
              name="FAMILIA"
              label="Familia"
              onChange={handleChangeModal}
              style={{ width: 232 }}
              className={classes.textField}
              input={
                <OutlinedInput
                  name="agefm"
                  id="outlined-age-simple"
                  label="Familia"
                />
              }
            >
              {familias.map((row, index) => (
                <MenuItem value={row.CODFAMILIA} key={index}>
                  {row.DESCRICAO}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
            style={{ width: 232, marginLeft: 0, marginRight: 16 }}
          >
            <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
              Tipo de Venda
            </InputLabel>
            <Select
              value={values.VENDATIPO ? values.VENDATIPO : " "}
              name="VENDATIPO"
              label="Tipo de Venda"
              onChange={handleChangeModal}
              style={{ width: 232 }}
              className={classes.textField}
              input={
                <OutlinedInput
                  name="agetpv"
                  id="outlined-age-simple"
                  label="Tipo de Venda"
                />
              }
            >
              {tpvendas.map((row, index) => (
                <MenuItem value={row.CODTIPO} key={index}>
                  {row.DESCRICAO}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <FormControl
            className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
            name="emissaotp"
            required
            error={hasError("emissaotp")}
            style={{ width: 232, marginLeft: 0, marginRight: 16 }}
          >
            <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
              Convênio
            </InputLabel>
            <Select
              value={values.EMISSAOTP ? values.EMISSAOTP : " "}
              name="EMISSAOTP"
              label="Convênio"
              onChange={handleChangeModal}
              style={{ width: 232 }}
              className={classes.textField}
              input={
                <OutlinedInput
                  name="ageconv"
                  id="outlined-age-simple"
                  label="Convênio"
                />
              }
            >
              {convenio.map((row, index) => (
                <MenuItem value={row.CODCONV} key={index}>
                  {row.DESCRICAO}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
            style={{ width: 232, marginLeft: 0, marginRight: 16 }}
          >
            <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
              Vendedor
            </InputLabel>
            <Select
              value={values.VENDEDOR ? values.VENDEDOR : " "}
              name="VENDEDOR"
              label="Vendedor"
              onChange={handleChangeModal}
              style={{ width: 232 }}
              className={classes.textField}
              input={
                <OutlinedInput
                  name="agevend"
                  id="outlined-age-simple"
                  label="Vendedor"
                />
              }
            >
              {vendedor.map((row, index) => (
                <MenuItem value={row.IDVENDEDOR} key={index}>
                  {row.NOME}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Link to={`/manutencaop`} className={classes.button}>
            <Button
              className={classes.button}
              type="submit"
              color="primary"
              variant="contained"
            >
              Enviar
            </Button>
          </Link>
        </Modal.Footer>
      </form>
    </>
  );
}
