/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts from "apexcharts";
import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";
// import { DropdownCustomToggler, DropdownMenu2 } from "../../dropdowns";
// import { useHtmlClassService } from "@layout";
import { useHtmlClassService } from "../../../../../_metronic/layout";
import { getMonthAbbreviations } from "../../../../utils/formats";
import { obterUltimosValoresArray } from "../utils";

export function ChartEmitidoXRecebido({
  className,
  chartColor = "danger",
  issuedVsReceived = {},
}) {
  const uiService = useHtmlClassService();

  const [indexButton, setIndexButton] = useState(6);

  const [emitidosValor, setEmitidosValor] = useState([]);
  // const [ emitidosAnterioresValor , setEmitidosAnterioresValor] = useState([]);

  const [recebidosValor, setRecebidosValor] = useState([]);
  // const [recebidosAnterioresValor, setRecebidosAnterioresValor] = useState([]);

  const [labels, setLabels] = useState([]);

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);

  async function getChartData() {
    const obj = issuedVsReceived;
    const keysArray = Object.keys(obj);

    const emitidosValorArray = [];

    const recebidosValorArray = [];

    keysArray.forEach((key) => {
      const item = obj[key];

      emitidosValorArray.push(item.emitted_valor.toFixed(2));
      recebidosValorArray.push(item.received_valor);
    });

    const keysMonth = getMonthAbbreviations(keysArray);
    const responseLabels = obterUltimosValoresArray(keysMonth, indexButton);

    setLabels(responseLabels);

    const responseValues = obterUltimosValoresArray(
      recebidosValorArray,
      indexButton
    );
    setRecebidosValor(responseValues);
    // setRecebidosAnterioresValor(recebidosValorArray);

    const responseValuesEmitidos = obterUltimosValoresArray(
      emitidosValorArray,
      indexButton
    );
    setEmitidosValor(responseValuesEmitidos);
    // setEmitidosAnterioresValor(emitidosValorArray);
  }

  useEffect(() => {
    getChartData();
    // eslint-disable-next-line
  }, [issuedVsReceived, indexButton]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_60_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
    // eslint-disable-next-line
  }, [layoutProps, emitidosValor, recebidosValor]);

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom  ${className}`}>
        {/* begin::Header */}
        <div className="mt-8 border-0"></div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column p-0">
          {/* begin::Chart */}
          <h4 class="font-weight-bolder ml-8 pt-0">Emitidos x Recebidos</h4>

          <div class="fs-6 d-flex text-gray-400 fs-6 fw-bold ml-8">
            <div class="d-flex align-items-center me-6">
              <span class="menu-bullet d-flex align-items-center me-2 mr-1">
                <span class="bullet bg-success" style={{ height: 3 }}></span>{" "}
              </span>
              Emitidos
            </div>
            <div class="d-flex align-items-center">
              <span class="menu-bullet d-flex align-items-center me-2 ml-4 mr-1">
                <span class="bullet bg-primary" style={{ height: 3 }}></span>{" "}
              </span>
              Recebidos
            </div>
          </div>

          <div className="ml-8 d-flex flex-row mt-4 mb-2">
            <button
              onClick={() => setIndexButton(3)}
              className="mr-8 p-3"
              style={{
                fontWeight: "bold",
                width: 50,
                border: 0,
                borderRadius: 8,
                background:
                  indexButton === 3 ? "rgba(242, 87, 118, 1)" : "transparent",
                color: indexButton === 3 ? "#fff" : "#b1b1b1",
              }}
            >
              3m
            </button>

            <button
              onClick={() => setIndexButton(6)}
              className="mr-8 p-3"
              style={{
                fontWeight: "bold",
                width: 50,
                border: 0,
                borderRadius: 8,
                background:
                  indexButton === 6 ? "rgba(242, 87, 118, 1)" : "transparent",
                color: indexButton === 6 ? "#fff" : "#b1b1b1",
              }}
            >
              6m
            </button>

            <button
              onClick={() => setIndexButton(12)}
              className="mr-8 p-3"
              style={{
                fontWeight: "bold",
                width: 50,
                border: 0,
                borderRadius: 8,
                background:
                  indexButton === 12 ? "rgba(242, 87, 118, 1)" : "transparent",
                color: indexButton === 12 ? "#fff" : "#b1b1b1",
              }}
            >
              12m
            </button>
          </div>

          <div id="kt_mixed_widget_60_chart" data-color={chartColor} />

          {/* <div className="card-spacer bg-white card-rounded flex-grow-1">
            <div className="row m-0">
              <div className="col px-8 py-6 mr-8">
                <div className="font-size-sm text-muted font-weight-bold">
                  Average Sale
                </div>
                <div className="font-size-h4 font-weight-bolder">$650</div>
              </div>
              <div className="col px-8 py-6">
                <div className="font-size-sm text-muted font-weight-bold">
                  Commission
                </div>
                <div className="font-size-h4 font-weight-bolder">$233,600</div>
              </div>
            </div>

            <div className="row m-0">
              <div className="col px-8 py-6 mr-8">
                <div className="font-size-sm text-muted font-weight-bold">
                  Annual Taxes
                </div>
                <div className="font-size-h4 font-weight-bolder">$29,004</div>
              </div>
              <div className="col px-8 py-6">
                <div className="font-size-sm text-muted font-weight-bold">
                  Annual Income
                </div>
                <div className="font-size-h4 font-weight-bolder">$1,480,00</div>
              </div>
            </div>
          </div> */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );

  function getChartOption(layoutProps) {
    const options = {
      series: [
        {
          name: "Emitido",
          data: emitidosValor,
        },
        {
          name: "Recebido",
          data: recebidosValor,
        },
      ],
      chart: {
        type: "area",
        height: 300,
        width: 500,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: ["#039DF5", "#4ECC88"],
      },
      xaxis: {
        categories: labels,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: "#bbb",
            fontSize: "12px",
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: "#aaa",
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#bbb",
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        // y: {
        //   formatter: function(val) {
        //     return val + " tasks";
        //   },
        // },
      },
      colors: ["#F1FAFE", "#E7FFF3"], //
      grid: {
        borderColor: "#d9d9d9",
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        //size: 5,
        colors: ["#F1FAFE", "#E7FFF3"], //
        strokeColor: ["#039DF5", "#4ECC88"],
        strokeWidth: 3,
      },
    };
    return options;
  }
}
