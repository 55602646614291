import React from "react";
import { Spinner } from "react-bootstrap";
import { RelatorioAnalitico } from "./Analitico";
import { RelatorioSintetico } from "./Sintetico";

import { useStyles } from "./styles";

export function Relatorio(props) {
  const { data, type, dataNotFound, loading, initialDate, finalDate } = props;

  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.container}>
        <Spinner color="primary" animation="border" />
      </div>
    );
  }

  if (!loading && dataNotFound) {
    return (
      <div className={classes.container}>
        <h1 className={classes.msg}>
          Nenhuma informação encontrada para o filtro selecionado
        </h1>
      </div>
    );
  }

  if (type === "analitico") {
    return (
      <RelatorioAnalitico
        data={data}
        initialDate={initialDate}
        finalDate={finalDate}
      />
    );
  }

  return (
    <RelatorioSintetico
      data={data}
      initialDate={initialDate}
      finalDate={finalDate}
    />
  );
}
