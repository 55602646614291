import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Typography, Button, Divider, Box } from "@material-ui/core";

import { useStylesSelect } from "./styles";

const ModalComponent = (props) => {
  const {
    loading = false,
    isVisible,
    closeModal,
    handleSave,
    children,
    title,
    size = "lg",
  } = props;

  const classes = useStylesSelect();

  return (
    <Modal
      show={isVisible}
      onHide={closeModal}
      backdrop="static"
      centered
      size={size}
    >
      {loading ? (
        <div className={classes.containerLoading}>
          <div className={classes.contentLoading}>
            <Spinner animation="border" role="status" />
            <p className={classes.textLoading}>Salvando...</p>
          </div>
        </div>
      ) : null}

      <Typography className={classes.title} component="h2" variant="h6">
        {title}
      </Typography>
      <Divider />
      <Box className={classes.box}>{children}</Box>
      <Divider />
      <div className={classes.footer}>
        <Button
          onClick={handleSave}
          className={classes.button}
          color="primary"
          variant="contained"
        >
          Salvar
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          Fechar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalComponent;
