import jwtDecode from "jwt-decode";

export const TOKEN_KEY = "X-Token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = (token) => {
  /*   console.log(token); */

  localStorage.setItem(TOKEN_KEY, token);
  hasAnyRoles();
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

//type roles (ADMIN)

export const hasAnyRoles = (permissions = []) => {
  const token = getToken();

  if (token) {
    const tokenDecoded = jwtDecode(token);

    let roles = [];
    if (tokenDecoded.roles) {
      roles = JSON.parse(tokenDecoded.roles);
    }

    if (permissions.length === 0) return true;

    for (let i = 0; i < permissions.length; i++) {
      if (roles.includes(permissions[i])) {
        return true;
      }
    }
  }

  return false;
};
