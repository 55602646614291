/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";

import * as yup from "yup";

import {
  Box,
  Checkbox,
  Fab,
  FormControl,
  FormHelperText,
  Icon,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";

import api from "../../services/api";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

import { toast, ToastContainer } from "react-toastify";
import { useStyles, useStylesSelect } from "./styles";
import ModalComponent from "../../components/Modal";

let schema = yup.object().shape({
  categoria: yup.string().required("Selecione uma categoria"),
  tipo: yup.string().required("Selecione um tipo"),
  valor: yup.number().typeError("Informe um valor"),
  quantidade: yup
    .number()
    .positive("Digite um valor maior que zero")
    .typeError("Informe uma quantidade"),
  dt_venc: yup.date().typeError("Informe uma data"),
  considera_taxa: yup.string().required("Selecione uma opção"),
});

function DebitosPage() {
  const classes = useStyles();
  const classesSelect = useStylesSelect();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsBusca, setRowsBusca] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [showModalDebit, setShowModalDebit] = useState(false);
  const [typeDebits, setTypeDebits] = useState([]);
  const [values, setValues] = useState({
    categoria: "",
    tipo: "",
    valor: "",
    quantidade: "",
    dt_venc: "",
    considera_taxa: "",
  });
  const [checkedAll, setCheckedAll] = useState(false);
  const [inscricaoInicial, setInscricaoInicial] = useState("");
  const [inscricaoFinal, setInscricaoFinal] = useState("");
  const [categoria, setCategoria] = useState("");
  const [errors, setErrors] = useState({});
  const [loadingSave, setLoadingSave] = useState(false);

  useEffect(() => {
    buscarContratos();
  }, [page, rowsPerPage]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  function buscarContratos(categoria = "") {
    api
      .post(`ct_plano_total?page=1&pageSize=5`, {
        inscricao_inicial: inscricaoInicial,
        inscricao_final: inscricaoFinal,
        categoria: categoria,
      })
      .then((response) => setTotalRows(response.data[0].total));

    api
      .post(`ct_plano_filtro?page=${page + 1}&pageSize=${rowsPerPage}`, {
        inscricao_inicial: inscricaoInicial,
        inscricao_final: inscricaoFinal,
        categoria: categoria,
      })
      .then((res) => {
        // setRows(res.data);
        setRowsBusca(res.data);
      })
      .catch((error) =>
        toast("Erro ao buscar contratos: " + error, { type: "error" })
      );
  }

  async function getDebitsType() {
    api.get("/ct_pl_debitotp").then((res) => {
      setTypeDebits(res.data);
    });
  }

  function handleCheckItem(idPlano) {
    const copySelectedValues = [...selectedValues];

    const index = selectedValues.indexOf(idPlano);

    if (index > -1) {
      copySelectedValues.splice(index, 1);
    } else {
      copySelectedValues.push(idPlano);
    }

    setSelectedValues(copySelectedValues);
  }

  async function handleCheckAll() {
    if (!checkedAll) {
      const response = await api.post("/ct_plano_ids");

      setSelectedValues(response.data);
    } else {
      setSelectedValues([]);
    }

    setCheckedAll(!checkedAll);
  }

  function searchItemSelected(idPlano) {
    const item = !!selectedValues.find((item) => item === idPlano);
    return item;
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      buscarContratos();
    }
  }

  async function fetchNewDebits() {
    setLoadingSave(true);
    try {
      await api.post("/ct_pl_debito_batch", {
        categoria: values.categoria,
        tipo: values.tipo,
        valor: values.valor,
        quantidade: values.quantidade,
        dt_venc: values.dt_venc,
        considera_taxa: values.considera_taxa,
        idplano: selectedValues.toString(),
        ordem: 0,
        iddebito: 0,
        valor_ad: 0.0,
      });

      toast.success("Debitos salvos com sucesso");
      closeModal();
    } catch (error) {
      toast.error("Erro ao salvar debitos");
    } finally {
      setLoadingSave(false);
      setSelectedValues([]);
      setCheckedAll(false);
    }
  }

  async function createDebits() {
    let debitFormData = {
      categoria: values.categoria,
      tipo: values.tipo,
      valor: values.valor,
      quantidade: values.quantidade,
      dt_venc: values.dt_venc,
      considera_taxa: values.considera_taxa,
    };

    schema
      .validate(debitFormData, { abortEarly: false })
      .then(() => {
        fetchNewDebits();
      })
      .catch((err) => {
        const inners = err.inner;

        let errors = {};

        inners.forEach((element) => {
          errors = { ...errors, [element.path]: element.errors[0] };
        });
        setErrors(errors);
      });
  }

  function closeModal() {
    setShowModalDebit(false);
    setValues({
      categoria: "",
      tipo: "",
      valor: "",
      quantidade: "",
      dt_venc: "",
      considera_taxa: "",
      // dt_final: "",
    });
    setErrors({});
  }

  return (
    <div style={{ position: "relative" }}>
      <Card>
        <CardHeader title="Débitos">
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className={classesSelect.root} autoComplete="off">
            <FormControl className={classesSelect.margin}>
              <FormHelperText>Inscrição inicial</FormHelperText>
              <TextField autoComplete="off"
                onChange={(event) => setInscricaoInicial(event.target.value)}
                onKeyPress={(event) => handleKeyPress(event)}
                type="text"
                size="small"
                variant="outlined"
                style={{ width: 232, marginTop: 0 }}
              />
            </FormControl>
            <FormControl className={classesSelect.margin}>
              <FormHelperText>Inscrição final</FormHelperText>
              <TextField autoComplete="off"
                onChange={(event) => setInscricaoFinal(event.target.value)}
                onKeyPress={(event) => handleKeyPress(event)}
                type="text"
                size="small"
                variant="outlined"
                style={{ width: 232, marginTop: 0 }}
              />
            </FormControl>
            <FormControl className={classesSelect.margin}>
              <FormHelperText>Categoria</FormHelperText>
              <TextField autoComplete="off"
                size="small"
                variant="outlined"
                select
                value={categoria}
                onChange={(event) => {
                  buscarContratos(event.target.value);
                  setCategoria(event.target.value);
                }}
                style={{ width: 232, marginTop: 0 }}
              >
                <MenuItem value="">Nenhuma</MenuItem>
                <MenuItem value="ADESAO">Adesão</MenuItem>
                <MenuItem value="MANUTENCAO">Manutenção</MenuItem>
              </TextField>
            </FormControl>
            <div style={{ marginTop: 18 }}>
              {selectedValues.length > 0 && (
                <Fab
                  title="Adicionar"
                  className={classes.fab}
                  color="primary"
                  onClick={() => {
                    getDebitsType();
                    setShowModalDebit(true);
                  }}
                  aria-label="Adicionar"
                >
                  <Icon>add</Icon>
                </Fab>
              )}
            </div>
          </div>

          <Paper className={classes.root}>
            <TableContainer className={classes.tcontainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Checkbox
                        checked={checkedAll}
                        onChange={handleCheckAll}
                        color="default"
                      />
                    </TableCell>
                    <TableCell align="center">Inscrição</TableCell>
                    <TableCell align="center">Nome</TableCell>
                    <TableCell align="center">Cidade</TableCell>
                    <TableCell align="center">CPF/CNPJ</TableCell>
                    <TableCell align="center">RG/IE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsBusca.map((row) => {
                    return (
                      <TableRow key={row.IDPLANO}>
                        <TableCell align="center">
                          <Checkbox
                            checked={searchItemSelected(row.IDPLANO)}
                            onChange={() => handleCheckItem(row.IDPLANO)}
                          />
                        </TableCell>
                        <TableCell align="center">{row.IDPLANO}</TableCell>
                        <TableCell align="center">{row.NOME}</TableCell>
                        <TableCell align="center">{row.CIDADE}</TableCell>
                        <TableCell align="center">
                          {row.CNPJ_CPF
                            ? row.CNPJ_CPF.replace(
                                /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                                "$1.$2.$3-$4"
                              )
                            : ""}
                        </TableCell>
                        <TableCell align="center">{row.IE_RG}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={"Registros por Página:"}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          </Paper>
        </CardBody>
      </Card>

      <ModalComponent
        isVisible={showModalDebit}
        closeModal={closeModal}
        title="Cadastro de Débito"
        handleSave={createDebits}
        loading={loadingSave}
      >
        <Typography>Tipo de débito</Typography>
        <br />
        <Box className={classes.lineTextFields}>
          <TextField autoComplete="off"
            variant="outlined"
            select
            name="categoria"
            label="Categoria"
            value={values.categoria}
            onChange={(event) =>
              setValues({ ...values, categoria: event.target.value })
            }
            error={errors.categoria}
            helperText={!!errors.categoria && errors.categoria}
            /*     style={{ width: 232 }} */
            className={classes.textField}
          >
            <MenuItem value="ADESAO">Adesão</MenuItem>
            <MenuItem value="MANUTENCAO">Manutenção</MenuItem>
          </TextField>
          <TextField autoComplete="off"
            variant="outlined"
            select
            name="tipo"
            label="Tipo"
            value={values.tipo}
            onChange={(event) =>
              setValues({ ...values, tipo: event.target.value })
            }
            error={errors.tipo}
            helperText={!!errors.tipo && errors.tipo}
            style={{ width: 232 }}
            className={classes.textField}
          >
            {typeDebits.map((item) => (
              <MenuItem key={item.CODTIPO} value={item.CODTIPO}>
                {item.DESCRICAO}
              </MenuItem>
            ))}
          </TextField>
          <TextField autoComplete="off"
            variant="outlined"
            select
            name="considera_taxa"
            label="Considerar taxa"
            value={values.considera_taxa}
            onChange={(event) =>
              setValues({ ...values, considera_taxa: event.target.value })
            }
            error={errors.considera_taxa}
            helperText={!!errors.considera_taxa && errors.considera_taxa}
            className={classes.textField}
          >
            <MenuItem value="SIM">Sim</MenuItem>
            <MenuItem value="NAO">Não</MenuItem>
          </TextField>
        </Box>

        <Typography>Valor</Typography>
        <Box className={classes.lineTextFields}>
          <TextField autoComplete="off"
            required
            onChange={(event) =>
              setValues({ ...values, valor: event.target.value })
            }
            name="valor"
            value={values.valor}
            label="Valor"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            InputProps={{
              value: values.valor,
              inputComponent: NumberFormatCustom,
            }}
            error={errors.valor}
            helperText={!!errors.valor && errors.valor}
          />
          <Box className={classes.textField} />
          <Box className={classes.textField} />
        </Box>

        <Typography>Parcela</Typography>
        <Box className={classes.lineTextFields}>
          <TextField autoComplete="off"
            name="quantidade"
            value={values.quantidade}
            id="QTD"
            label="Quantidade"
            type="number"
            rowsMax="4"
            onChange={(event) =>
              setValues({ ...values, quantidade: event.target.value })
            }
            className={classes.textField}
            margin="normal"
            variant="outlined"
            error={errors.quantidade}
            helperText={!!errors.quantidade && errors.quantidade}
          />
          <TextField autoComplete="off"
            name="dt_venc"
            value={values.dt_venc}
            id="outlined-multiline-flexible"
            label="Data de Vencimento"
            type="date"
            rowsMax="4"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            onChange={(event) =>
              setValues({ ...values, dt_venc: event.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
            error={errors.dt_venc}
            helperText={!!errors.dt_venc && errors.dt_venc}
          />
          <Box className={classes.textField} />
        </Box>
      </ModalComponent>

      <ToastContainer />
    </div>
  );
}

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, value, ...other } = props;

  return (
    <NumberFormat
      {...other}
      value={value}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      prefix="R$ "
    />
  );
});

export default DebitosPage;
