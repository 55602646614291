/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import api from "../services/api";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import { Alert, Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import FormHelperText from "@material-ui/core/FormHelperText";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";

import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";
//import varGlobais from "./vg";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";

import { cpf } from "cpf-cnpj-validator";
import { cnpj } from "cpf-cnpj-validator";

var somenteleitura = false;
var ndcarenciaPg = 0;
var ndcarenciaAt = 0;
var cadGalax = [];
var i = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },

  tableA: {
    backgroundColor: "#DCDCDC", //'#D3D3D3',
  },

  tableB: {
    backgroundColor: "#FFFFFF",
  },

  tableC: {
    backgroundColor: "#FFC0CB", //'#E9967A', // '#FA8072',
    color: "#FFFFFF",
  },

  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  tcontainer: {
    maxHeight: 320,
  },
  tcontainerF: {
    maxHeight: 350,
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  fab: {
    disabled: {},
    margin: theme.spacing(1),
    width: 35,
    height: 5,
  },

  fabd: {
    disabled: {},
    margin: theme.spacing(1),
    width: 35,
    height: 5,
  },
  modalContratos: {
    maxWidth: 900,
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStylesSelect = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export function ContratosNovoPage() {
  const classes = useStyles();
  const classesSelect = useStylesSelect();
  const [errors, setErrors] = useState([]);
  const [show, setShow] = useState(false);
  const [DebitosData, setDebitosData] = useState(false);
  const [DebitosDatabaixar, setDebitosDatabaixar] = useState(false);
  const [pegaVariaveis, setpegaVariaveis] = useState(false);
  const [DebitosDatadesbaixar, setDebitosDatadesbaixar] = useState(false);
  const [DebitosDataInsert, setDebitosDataInsert] = useState(false);
  const [InfosData, setInfosData] = useState(false);
  const [TaxasData, setTaxasData] = useState(false);
  const [DependentesData, setDependentesData] = useState(false);
  const [editar, setEditar] = useState(false);
  const [editarDebitos, setEditarDebitos] = useState(false);
  const [baixarDebitos, setbaixarDebitos] = useState(false);
  const [desbaixarDebitos, setdesbaixarDebitos] = useState(false);
  const [editarInfos, setEditarInfos] = useState(false);
  const [editarTaxas, setEditarTaxas] = useState(false);
  const [editarDependentes, setEditarDependentes] = useState(false);
  const handleClose = () => setShow(false);
  const handleDebitosData = () => setDebitosData(false);
  const handleDebitosDatabaixar = () => setDebitosDatabaixar(false);
  const handleDebitosDataInsert = () => setDebitosDataInsert(false);
  const handleInfosData = () => setInfosData(false);
  const handleTaxasData = () => setTaxasData(false);
  const handleDependentesData = () => setDependentesData(false);
  const handleCloseDebitos = () => setShowDebitos(false);
  const handleCloseDebitosC = () => setShowDebitosC(false);
  const handleCloseInfos = () => setShowInfos(false);
  const handleCloseTaxas = () => setShowTaxas(false);
  const handleCloseDependentes = () => setShowDependentes(false);
  const [categorias, setCategorias] = useState([]);
  const [debitotp, setDebitotp] = useState([]);
  const [infotp, setInfotp] = useState([]);
  const [taxatp, setTaxatp] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [tpvendas, settpVendas] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [graus, setGraus] = useState([]);
  const [sexo, setSexo] = useState([]);
  const [religiao, setReligiao] = useState([]);
  const [vendedor, setVendedor] = useState([]);
  const [convenio, setConvenio] = useState([]);
  const [variaveis, setVariaveis] = useState([]);
  const [estcivil, setEstcivil] = useState([]);
  //const [ndcarenciaPg, setndcarenciaPg] = useState();
  //const [ndcarenciaAt, setndcarenciaAt] = useState();
  const [deptp, setDeptp] = useState([]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  //Paginação
  //Principal

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Débito
  const [pageDeb, setPageDeb] = React.useState(0);
  const [rowsPerPageDeb, setRowsPerPageDeb] = React.useState(5);

  const handleChangePageDeb = (event, newPageDeb) => {
    setPageDeb(newPageDeb);
  };

  const handleChangeRowsPerPageDeb = (event) => {
    setRowsPerPageDeb(parseInt(event.target.value, 10));
    setPageDeb(0);
  };

  //Dependente
  const [pageDep, setPageDep] = React.useState(0);
  const [rowsPerPageDep, setRowsPerPageDep] = React.useState(5);

  const handleChangePageDep = (event, newPageDep) => {
    setPageDep(newPageDep);
  };

  const handleChangeRowsPerPageDep = (event) => {
    setRowsPerPageDep(parseInt(event.target.value, 10));
    setPageDep(0);
  };

  //Informação
  const [pageInf, setPageInf] = React.useState(0);
  const [rowsPerPageInf, setRowsPerPageInf] = React.useState(5);

  const handleChangePageInf = (event, newPageInf) => {
    setPageInf(newPageInf);
  };

  const handleChangeRowsPerPageInf = (event) => {
    setRowsPerPageInf(parseInt(event.target.value, 10));
    setPageInf(0);
  };

  //Taxa
  const [pageTx, setPageTx] = React.useState(0);
  const [rowsPerPageTx, setRowsPerPageTx] = React.useState(5);

  const handleChangePageTx = (event, newPageTx) => {
    setPageTx(newPageTx);
  };

  const handleChangeRowsPerPageTx = (event) => {
    setRowsPerPageTx(parseInt(event.target.value, 10));
    setPageTx(0);
  };
  //Termina Paginação

  const handleShow = () => {
    pegavar1();
    pegavar2();
    setValues({});
    setErrors([]);
    somenteleitura = false;
    api.get("/ct_pl_categ").then((res) => {
      console.log("ct_pl_categ  ", JSON.parse(JSON.stringify(res.data)));
      setCategorias(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/ct_pl_grupo").then((res) => {
      console.log("ct_pl_grupo  ", JSON.parse(JSON.stringify(res.data)));
      setGrupos(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/ct_pl_vendatp").then((res) => {
      console.log("ct_pl_vendatp  ", JSON.parse(JSON.stringify(res.data)));
      settpVendas(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/ct_pl_familia").then((res) => {
      console.log("ct_pl_familia  ", JSON.parse(JSON.stringify(res.data)));
      setFamilias(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/ct_pl_grau").then((res) => {
      console.log("ct_pl_grau  ", JSON.parse(JSON.stringify(res.data)));
      setGraus(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/un_sexo").then((res) => {
      console.log("un_sexo  ", JSON.parse(JSON.stringify(res.data)));
      setSexo(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/un_religiao").then((res) => {
      console.log("un_religiao  ", JSON.parse(JSON.stringify(res.data)));
      setReligiao(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/un_estcivil").then((res) => {
      console.log("un_estcivil  ", JSON.parse(JSON.stringify(res.data)));
      setEstcivil(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/ct_pl_vendedor").then((res) => {
      console.log("ct_pl_vendedor  ", JSON.parse(JSON.stringify(res.data)));
      setVendedor(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/cobrconv").then((res) => {
      console.log("cobrconv  ", JSON.parse(JSON.stringify(res.data)));
      setConvenio(res.data);
      setShow(true);
      setEditar(false);
    });

    //api.get("/variaveis").then(res => {
    //  console.log("variaveis  ", JSON.parse(JSON.stringify(res.data)));
    //  setVariaveis(res.data);
    //  setShow(true);
    // setEditar(false);
  };

  const handleDebitos = () => {
    api.get("/ct_pl_debitotp").then((res) => {
      console.log("ct_pl_debitotp  ", JSON.parse(JSON.stringify(res.data)));
      setDebitotp(res.data);
      setValues({});
      setDebitosData(true);
      setEditarDebitos(false);
      setShowDebitos(false);
    });
  };

  const handleDebitosInsert = () => {
    api.get("/ct_pl_debitotp").then((res) => {
      console.log("ct_pl_debitotp  ", JSON.parse(JSON.stringify(res.data)));
      setDebitotp(res.data);
      setValues({});
      setDebitosDataInsert(true);
      setEditarDebitos(false);
      setShowDebitosInsert(false);
    });
  };

  const handleInfos = () => {
    api.get("/ct_pl_infotp").then((res) => {
      console.log("ct_pl_infotp", JSON.parse(JSON.stringify(res.data)));
      setInfotp(res.data);
      setValues({});
      setInfosData(true);
      setEditarInfos(false);
      setShowInfos(false);
    });
  };

  const handleTaxas = () => {
    api.get("/ct_pl_taxatp").then((res) => {
      console.log("ct_pl_taxatp", JSON.parse(JSON.stringify(res.data)));
      setTaxatp(res.data);
      setValues({});
      setTaxasData(true);
      setEditarTaxas(false);
      setShowTaxas(false);
    });
  };

  const handleDependencias = () => {
    setValues({});
    setDependentesData(true);
    setEditarDependentes(false);
    setShowDependentes(false);
  };

  function atualizaContratos() {
    api.get("/ct_plano").then((res) => {
      console.log("ct_plano ", JSON.parse(JSON.stringify(res.data)));
      res.data.map((data_plano, i) => {
        if (i === 0) {
          setSelectedValue(data_plano.IDPLANO);
        }
        data_plano.IS_SHOW_ACTION = false;
      });
      setRows(res.data);
      setRowsBusca(res.data);
    });
  }

  function formatCurrency(valor) {
    if (valor !== null && valor !== undefined) {
      return valor.replace(".", ",");
    }
    return valor;
  }

  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteDebito, setShowDeleteDebito] = useState(false);
  const [showDesbaixaDebito, setShowDesbaixaDebito] = useState(false);
  const [showDeleteInfo, setShowDeleteInfo] = useState(false);
  const [showDeleteTaxa, setShowDeleteTaxa] = useState(false);
  const [showDeleteDependencia, setShowDeleteDependencia] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [showDebitos, setShowDebitos] = useState(false);
  const [showDebitosC, setShowDebitosC] = useState(false);
  const [showDebitosbaixar, setShowDebitosbaixar] = useState(false);
  const [showDebitosdesbaixar, setShowDebitosdesbaixar] = useState(false);
  const [showDebitosInsert, setShowDebitosInsert] = useState(false);
  const [showInfos, setShowInfos] = useState(false);
  const [showTaxas, setShowTaxas] = useState(false);
  const [showDependentes, setShowDependentes] = useState(false);

  const [showVariaveis, setShowVariaveis] = useState(false);

  const [planoAtual, setPlanoAtual] = useState();
  const [linhaARemover, setLinhaARemover] = useState();
  const [debitoARemover, setDebitoARemover] = useState();
  const [debitoADesbaixar, setDebitoADesbaixar] = useState();
  const [InfosARemover, setInfosARemover] = useState();
  const [TaxasARemover, setTaxasARemover] = useState();
  const [dependenteARemover, setDependenteARemover] = useState();
  const modalDeleteClose = () => setShowDelete(false);
  const modalDeleteDebitoClose = () => setShowDeleteDebito(false);
  const modalDesbaixaDebitoClose = () => setShowDesbaixaDebito(false);
  const modalDeleteInfoClose = () => setShowDeleteInfo(false);
  const modalDeleteTaxaClose = () => setShowDeleteTaxa(false);
  const modalDeleteDependenciaClose = () => setShowDeleteDependencia(false);

  function modalDeleteShow() {
    console.log(selectedValue);
    setShowDelete(true);
    rows.map((row_aux, i) => {
      if (selectedValue === row_aux.IDPLANO) {
        console.log("i", i);
        setLinhaARemover(i);
      }
    });
  }

  function modalDeleteDebitoShow(index) {
    setDebitoARemover(index);
    console.log(debitoARemover);
    setShowDeleteDebito(true);
    setShowDebitos(false);
  }

  function modalgerarCobranca(
    iddebito,
    vencimento,
    valor,
    descdebito,
    catdebito,
    idplano,
    codconv,
    plano,
    nome,
    cnpj_cpf,
    ie_rg,
    email,
    celular,
    fone,
    cep,
    endereco,
    numero,
    complement,
    cidade,
    bairro,
    estado,
    pais
  ) {
    //alert('Geração de Cobrança débito ' + iddebito + ' Cliente :' +nome);
    if (
      window.confirm("Deseja gerar a cobrança para o cliente: " + nome + " ?")
    ) {
      api.get(`/pegasequencia/` + codconv).then((resSeq) => {
        setFilteredSeq(resSeq.data);
        let seq = resSeq.data.SEQUENCIA;
        console.log("Sequencia :", resSeq.data);
        console.log("Seq :", resSeq.data.SEQUENCIA);

        const createdCob = values;
        createdCob.OCORRENCIA = "1";
        createdCob.EMPRESA = "";
        createdCob.CATEGORIA = "";
        createdCob.CONVENIO = codconv;
        createdCob.SEQUENCIA = seq;
        createdCob.SEQ_EXTERNA = "";
        createdCob.SACADOTAB = "CT_PLANO";
        createdCob.SACADOID = plano;
        createdCob.DOCUMENTO = iddebito;
        createdCob.DESCRICAO = descdebito;
        createdCob.VENCIMENTO = moment(vencimento).format("YYYY-MM-DD");
        createdCob.VALOR = valor;
        createdCob.VALORTOT = valor;
        createdCob.RAZ_SOCIAL = nome;
        createdCob.CNPJ_CPF = cnpj_cpf;
        createdCob.IE_RG = ie_rg;
        createdCob.CEP = cep;
        createdCob.LOGRADOURO = "";
        createdCob.ENDERECO = endereco;
        createdCob.NUMERO = numero;
        createdCob.COMPLEMENT = complement;
        createdCob.BAIRRO = bairro;
        createdCob.CIDADE = cidade;
        createdCob.ESTADO = estado;
        createdCob.PAIS = pais;

        createdCob.COBRANCA = "1";
        console.log("dados cobranca ", createdCob);

        let key,
          keys = Object.keys(createdCob);
        console.log("key", key);
        console.log("keys", keys);
        let n = keys.length;
        let sendCob = {};
        while (n--) {
          key = keys[n];
          sendCob[key.toLowerCase()] = createdCob[key];
        }
        console.log("Cadastro cobranca ", sendCob);
        api.post(`/cobranca`, sendCob);

        api.get(`/buscacobranca/` + iddebito).then((resCobra) => {
          setFilteredCobra(resCobra.data);
          let idcobra = resCobra.data.IDCOBRA;
          console.log("IdCobra :", resCobra.data);

          const createdCobdoc = values;
          createdCobdoc.IDCOBRA = idcobra;
          createdCobdoc.DESCRICAO = descdebito;
          createdCobdoc.VENCORIG = moment(vencimento).format("YYYY-MM-DD");
          createdCobdoc.VALORORIG = valor;
          createdCobdoc.JUROS = "0";
          createdCobdoc.MULTA = "0";
          createdCobdoc.ORIGEMTAB = "CT_PL_DEBITO";
          createdCobdoc.ORIGEMID = iddebito;

          console.log("dados doc cobranca ", createdCobdoc);

          let keydoc,
            keysdoc = Object.keys(createdCobdoc);
          console.log("key", keydoc);
          console.log("keys", keysdoc);
          let n = keysdoc.length;
          let sendCobdoc = {};
          while (n--) {
            keydoc = keysdoc[n];
            sendCobdoc[keydoc.toLowerCase()] = createdCobdoc[keydoc];
          }
          console.log("Cadastro doc cobranca ", sendCobdoc);
          api.post(`/cobrdocu`, sendCobdoc);
        });

        api.put(`/pegasequencia/${codconv}`);
        setShowDebitos(false);
        alert("Cobrança cadastrada");
      });
    }
  }

  function modalDesbaixaDebitoShow(index) {
    setDebitoADesbaixar(index);
    console.log(debitoADesbaixar);
    setShowDesbaixaDebito(true);
    setShowDebitos(false);
    //setShowDebitos(true);
  }

  function modalDeleteInfoShow(index) {
    setInfosARemover(index);
    console.log(InfosARemover);
    setShowDeleteInfo(true);
    setShowInfos(false);
  }

  function modalDeleteInfoShowRecorre(index) {
    setInfosARemover(index);
    console.log(InfosARemover);
    setShowDeleteInfo(false);
    setShowInfos(false);
  }

  function modalDeleteTaxaShow(index) {
    setTaxasARemover(index);
    console.log(TaxasARemover);
    setShowDeleteTaxa(true);
    setShowTaxas(false);
  }

  function modalDeleteDependenciaShow(index) {
    setDependenteARemover(index);
    setShowDeleteDependencia(true);
    setShowDependentes(false);
    console.log(linhaARemover);
  }

  function modalDebitosShow(index) {
    let idPlanoSelecionado = [...rows][index].IDPLANO;
    api.get("/ct_pl_debito/" + idPlanoSelecionado).then((resDebito) => {
      setPlanoAtual(idPlanoSelecionado);
      console.log("ct_pl_debito_Antes ", resDebito.data);
      resDebito.data.map((data_debito, i) => {
        if (i === 0) {
          setSelectedValue(data_debito.IDDEBITO);
        }
        data_debito.IS_SHOW_ACTION_D = false;
      });
      setRowsDeb(resDebito.data);
      cadGalax = [...rows][index];
      console.log("cadCob -->: ", cadGalax);
      setFilteredDebitos(resDebito.data);
      console.log("ct_pl_debito_Após ", resDebito.data);
      setShowDebitos(true);
    });
  }

  function modalDebitosCobShow(index) {
    //let idPlanoSelecionado = [...rows][index].IDPLANO;
    let idPlanoSelecionado = [...rows][index].IDPLANO;
    api.get("/ct_pl_debitocob/" + idPlanoSelecionado).then((resDebito) => {
      setPlanoAtual(idPlanoSelecionado);
      setFilteredDebitos(resDebito.data);
      setShowDebitosC(true);
    });
  }

  function removerrecorre(
    index,
    tipoi,
    myid,
    cliente,
    chave,
    endp,
    parceiro,
    chavep,
    siteccliente,
    usar_parceiro
  ) {
    let tipo = tipoi;
    if (tipo != "GALAXPAY") {
      alert("Não é uma Recorrência!!!");
    } else {
      //var resultado = window.confirm("Deseja remover recorrência: ?");
      if (window.confirm("Deseja remover recorrência: ?")) {
        var myHeaders = new Headers();
        var myHeaders1 = new Headers();
        var site = endp;
        var sitec = siteccliente + "/" + myid + "/galaxPayId";
        var myaut = btoa(cliente + ":" + chave);
        myHeaders.append("Authorization", "Basic " + myaut);
        if (usar_parceiro === "S") {
          var myautp = btoa(parceiro + ":" + chavep);
          myHeaders.append("AuthorizationPartner", ":" + myautp);
        }
        myHeaders.append("Content-Type", "application/js on");

        var raw = JSON.stringify({
          grant_type: "authorization_code",
          scope:
            "customers.read customers.write plans.read plans.write transactions.read transactions.write webhooks.write cards.read cards.write card-brands.read subscriptions.read subscriptions.write charges.read charges.write",
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        var raw = JSON.stringify([]);

        var requestOptions1 = {
          method: "DELETE",
          headers: myHeaders1,
          body: raw,
          redirect: "follow",
        };

        fetch(site, requestOptions)
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            console.log("GalaxPay: ", result);
            myHeaders1.append("Authorization", "Bearer " + result.access_token);
            myHeaders1.append("Content-Type", "application/json");
            fetch(sitec, requestOptions1)
              .then((response) => {
                return response.text();
              })
              .then((result) => {
                let memo = result;
                let jid = JSON.parse(result);
                console.log("Resposta da Exclusão ", jid);
                removerInfoRecorre(index);
                alert("Recorrencia Removida !!! ");
              })
              .catch((error) => console.log("erro na exclusão :", error));
          })
          .catch((error) => console.log("erro no tokken :", error));
      }
      setShowInfos(false);
    }
  }

  function handlecadastrargalaxpay(
    index,
    cliente,
    chave,
    endp,
    parceiro,
    chavep,
    siteccliente,
    plano,
    nome,
    cpf,
    email,
    celular,
    fone,
    cep,
    endereco,
    numero,
    complemento,
    cidade,
    bairro,
    estado,
    pais,
    operador,
    usar_parceiro
  ) {
    if (window.confirm("Deseja incluir recorrência: ?")) {
      var myHeaders = new Headers();
      var myHeaders1 = new Headers();
      var site = endp;
      var sitec = siteccliente;
      var myaut = btoa(cliente + ":" + chave);
      myHeaders.append("Authorization", "Basic " + myaut);

      if (usar_parceiro === "S") {
        var myautp = btoa(parceiro + ":" + chavep);
        myHeaders.append("AuthorizationPartner", ":" + myautp);
      }
      myHeaders.append("Content-Type", "application/js on");

      var raw = JSON.stringify({
        grant_type: "authorization_code",
        scope:
          "customers.read customers.write plans.read plans.write transactions.read transactions.write webhooks.write cards.read cards.write card-brands.read subscriptions.read subscriptions.write charges.read charges.write",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      var teste = '{"myId": "' + plano + '", ';
      teste = teste + '"name": "' + nome + '", ';
      teste = teste + '"document": "' + cpf + '", ';
      teste = teste + '"emails": ["' + email + '"], ';
      if (celular > "") {
        teste = teste + '"phones": [' + celular.replace(/[^0-9]+/g, "") + "], ";
      }
      //      teste = teste + '"phones": ["' + celular.replace(/[^0-9]+/g, '') + '","' + fone.replace(/[^0-9]+/g, '') + '"], ';
      teste = teste + '"password": "' + cpf + '", ';
      teste = teste + '"Address": { "zipCode": "' + cep + '", ';
      teste = teste + '"street": "' + endereco + '", ';
      teste = teste + '"number": "' + numero + '", ';
      teste = teste + '"complement": "' + complemento + '", ';
      teste = teste + '"neighborhood": "' + bairro + '", ';
      teste = teste + '"city": "' + cidade + '", ';
      teste = teste + '"state": "' + estado + '", ';
      teste = teste + '"country": "' + pais + '"}}';

      //var rawc = JSON.stringify(teste);
      var rawc = teste;

      var requestOptions1 = {
        method: "POST",
        headers: myHeaders1,
        body: rawc,
        redirect: "follow",
      };

      function cadastrainfogalax(planoAtual, memo, galaxid) {
        const createdInfo = values;
        createdInfo.IDPLANO = planoAtual;
        createdInfo.TIPO = operador;
        createdInfo.DESCRICAO = operador;
        createdInfo.IDINFO = "1";
        createdInfo.MEMO = memo;
        createdInfo.VALOR = galaxid;
        createdInfo.DT_INICIO = dataAtualFormatada();

        let key,
          keys = Object.keys(createdInfo);
        console.log("key", key);
        console.log("keys", keys);
        let n = keys.length;
        let sendInfo = {};
        while (n--) {
          key = keys[n];
          sendInfo[key.toLowerCase()] = createdInfo[key];
        }
        console.log("Cadastro informação ", sendInfo);
        api.post(`/ct_pl_info`, sendInfo);
        setInfosData(false);
      }

      fetch(site, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          console.log("GalaxPay: ", result);
          myHeaders1.append("Authorization", "Bearer " + result.access_token);
          myHeaders1.append("Content-Type", "application/json");
          fetch(sitec, requestOptions1)
            .then((response) => {
              return response.text();
            })
            .then((result) => {
              let memo = result;
              let jid = JSON.parse(result);
              console.log("Resposta do Cadastro", jid);
              cadastrainfogalax(index, memo, jid.Customer.galaxPayId);
              alert(
                "Cadastro/Alteração feito junto a GALAXPAY \n galaxPayId - " +
                  jid.Customer.galaxPayId
              );
            })
            .catch((error) => console.log("erro no cadastro :", error));
        })
        .catch((error) => console.log("erro no tokken :", error));
    }
    setShowInfos(false);
  }

  function modalInfosShow(index) {
    let idPlanoSelecionado = [...rows][index].IDPLANO;
    api.get("/ct_pl_info/" + idPlanoSelecionado).then((resInfo) => {
      setPlanoAtual(idPlanoSelecionado);
      resInfo.data.map((data_info, i) => {
        if (i === 0) {
          setSelectedValue(data_info.IDINFO);
        }
        data_info.IS_SHOW_ACTION_I = false;
      });
      cadGalax = [...rows][index];
      console.log("cadGalax -->: ", cadGalax);
      setRowsInfo(resInfo.data);
      setFilteredInfos(resInfo.data);
      setShowInfos(true);
    });
  }

  function modalTaxasShow(index) {
    let idPlanoSelecionado = [...rows][index].IDPLANO;
    api.get("/ct_pl_taxa/" + idPlanoSelecionado).then((resTaxa) => {
      setPlanoAtual(idPlanoSelecionado);
      console.log("ct_pl_taxa_Antes ", resTaxa.data);
      resTaxa.data.map((data_taxa, i) => {
        if (i === 0) {
          setSelectedValue(data_taxa.IDTAXA);
        }
        data_taxa.IS_SHOW_ACTION_T = false;
      });
      setRowsTaxa(resTaxa.data);
      console.log("ct_pl_taxa_Após ", resTaxa.data);
      setFilteredTaxas(resTaxa.data);
      setShowTaxas(true);
    });
  }

  function showOrHideActions(index, value) {
    const newRow = [...rows];
    const row = newRow[index];
    row.IS_SHOW_ACTION = !value;
    setRows(rows.map((item) => (item.IDPLANO === index ? row : item)));
  }

  function showOrHideActionsD(index, value) {
    const newRow = [...rowsDeb];
    const rowsDebi = newRow[index];
    rowsDebi.IS_SHOW_ACTION_D = !value;
    setRowsDeb(
      rowsDeb.map((itemD) => (itemD.IDDEBITO === index ? rowsDebi : itemD))
    );
  }

  function showOrHideActionsA(index, value) {
    const newRow = [...rowsDep];
    const rowsDepi = newRow[index];
    rowsDepi.IS_SHOW_ACTION_A = !value;
    setRowsDep(
      rowsDep.map((itemA) => (itemA.IDDEP === index ? rowsDepi : itemA))
    );
  }

  function showOrHideActionsI(index, value) {
    const newRow = [...rowsInfo];
    const rowsInfos = newRow[index];
    rowsInfos.IS_SHOW_ACTION_I = !value;
    setRowsInfo(
      rowsInfo.map((itemI) => (itemI.IDINFO === index ? rowsInfos : itemI))
    );
  }

  function showOrHideActionsT(index, value) {
    const newRow = [...rowsTaxa];
    const rowsTaxas = newRow[index];
    rowsTaxas.IS_SHOW_ACTION_T = !value;
    setRowsTaxa(
      rowsTaxa.map((itemT) => (itemT.IDTAXA === index ? rowsTaxas : itemT))
    );
  }

  function modalDependentesShow(index) {
    let idPlanoSelecionado = [...rows][index].IDPLANO;
    api.get("/ct_pl_depend/" + idPlanoSelecionado).then((resDependentes) => {
      setPlanoAtual(idPlanoSelecionado);
      resDependentes.data.map((data_dep, i) => {
        if (i === 0) {
          setSelectedValue(data_dep.IDDEP);
        }
        data_dep.IS_SHOW_ACTION_A = false;
      });
      setRowsDep(resDependentes.data);
      console.log(
        "ct_pl_depend",
        JSON.parse(JSON.stringify(resDependentes.data))
      );
      setFilteredDependentes(resDependentes.data);
      setShowDependentes(true);
    });
    //aqui
    api.get("/ct_pl_grau").then((resDependentes) => {
      console.log(
        "ct_pl_grau  ",
        JSON.parse(JSON.stringify(resDependentes.data))
      );
      setGraus(resDependentes.data);
      setDependentesData(false);
      setEditarDependentes(false);
      setShowDependentes(true);
    });
    api.get("/un_sexo").then((resDependentes) => {
      console.log("un_sexo  ", JSON.parse(JSON.stringify(resDependentes.data)));
      setSexo(resDependentes.data);
      setDependentesData(false);
      setEditarDependentes(false);
      setShowDependentes(true);
    });
    api.get("/un_religiao").then((resDependentes) => {
      console.log(
        "un_religiao  ",
        JSON.parse(JSON.stringify(resDependentes.data))
      );
      setReligiao(resDependentes.data);
      setDependentesData(false);
      setEditarDependentes(false);
      setShowDependentes(true);
    });
    api.get("/un_estcivil").then((resDependentes) => {
      console.log(
        "un_estcivil  ",
        JSON.parse(JSON.stringify(resDependentes.data))
      );
      setEstcivil(resDependentes.data);
      setDependentesData(false);
      setEditarDependentes(false);
      setShowDependentes(true);
    });
    api.get("/ct_pl_deptp").then((resDependentes) => {
      console.log(
        "ct_pl_deptp  ",
        JSON.parse(JSON.stringify(resDependentes.data))
      );
      setDeptp(resDependentes.data);
      setDependentesData(false);
      setEditarDependentes(false);
      setShowDependentes(true);
    });
    //até aqui
  }

  const [rows, setRows] = useState([]);
  const [rowsDeb, setRowsDeb] = useState([]);
  const [rowsDep, setRowsDep] = useState([]);
  const [rowsTaxa, setRowsTaxa] = useState([]);
  const [rowsInfo, setRowsInfo] = useState([]);
  const [rowsBusca, setRowsBusca] = useState([]);
  const [filteredDependentes, setFilteredDependentes] = useState([]);
  const [filteredDebitos, setFilteredDebitos] = useState([]);
  const [filteredCobra, setFilteredCobra] = useState([]);
  const [filteredPlano, setFilteredPlano] = useState([]);
  const [filteredTaxa, setFilteredTaxa] = useState([]);
  const [filteredSeq, setFilteredSeq] = useState([]);
  const [filteredVar1, setFilteredVar1] = useState([]);
  const [filteredVar2, setFilteredVar2] = useState([]);
  const [filteredVariaveis, setFilteredVariaveis] = useState([]);
  const [filteredInfos, setFilteredInfos] = useState([]);
  const [filteredTaxas, setFilteredTaxas] = useState([]);
  const [infoGalax, setinfoGalax] = useState({});

  const [selectedValue, setSelectedValue] = useState("");
  function handleChangeRadio(event) {
    setSelectedValue(event.currentTarget.value);
  }
  useEffect(() => {
    atualizaContratos();
    api.get("/ct_pl_categ").then((res) => {
      setCategorias(res.data);
    });
    api.get("/ct_pl_grupo").then((res) => {
      setGrupos(res.data);
    });
    api.get("/ct_pl_vendatp").then((res) => {
      settpVendas(res.data);
    });
    api.get("/ct_pl_familia").then((res) => {
      setFamilias(res.data);
    });
    api.get("/ct_pl_grau").then((res) => {
      setGraus(res.data);
    });
    api.get("/un_sexo").then((res) => {
      setSexo(res.data);
    });
    api.get("/un_religiao").then((res) => {
      setReligiao(res.data);
    });
    api.get("/un_estcivil").then((res) => {
      setEstcivil(res.data);
    });
    api.get("/ct_pl_vendedor").then((res) => {
      setVendedor(res.data);
    });
    api.get("/cobrconv").then((res) => {
      setConvenio(res.data);
    });
    //    api.get("/variaveis").then(res => {
    //      setVariaveis(res.data);
    //    });
  }, []);

  const filtrarNome = (event) => {
    const name = event.target.value.toLowerCase();
    if (isNaN(name)) {
      const auxBusca = rows.filter((row) =>
        row.NOME.toLowerCase().includes(name)
      );
      if (name) {
        setRowsBusca(auxBusca);
      } else {
        setRowsBusca(rows);
      }
    } else {
      const auxBusca = rows.filter((row) =>
        row.IDPLANO.toLowerCase().includes(name)
      );
      if (name) {
        setRowsBusca(auxBusca);
      } else {
        setRowsBusca(rows);
      }
    }
  };
  const filtrarCPF = (event) => {
    const cpf = event.target.value.toLowerCase();
    const auxBusca = rows.filter((row) =>
      row.CNPJ_CPF.toLowerCase().includes(cpf)
    );
    if (cpf) {
      setRowsBusca(auxBusca);
    } else {
      setRowsBusca(rows);
    }
  };

  const [values, setValues] = useState({});

  const handleChangeModal = (event) => {
    const auxValues = { ...values };
    console.log("VALOR ", event.target.value);
    auxValues[event.target.name] = event.target.value;
    setValues(auxValues);
  };

  const handleChangeModalCurrency = (event) => {
    var v = event.target.value;
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    event.target.value = v;

    const auxValues = { ...values };
    console.log("VALOR ", event.target.value);
    auxValues[event.target.name] = event.target.value;
    setValues(auxValues);
  };

  const maskCurrency = (event) => {
    var v = event.target.value;
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    event.target.value = v;
  };

  const handleSubmit1 = (callback) => (event) => {
    event.preventDefault();
    callback();
  };

  const [editarIndex, setEditarIndex] = useState(0);
  const [baixarIndex, setbaixarIndex] = useState(0);
  //const [variaveisIndex, setVariaveisIndex] = useState(0)
  //const [desbaixarIndex, setdesbaixarIndex] = useState(0)

  function validarContratos(dados) {
    var data = new Date();
    var errors = [];
    if (dados["vendadt"] === "" || dados["vendadt"] === undefined) {
      errors.push("vendadt");
    }
    console.log("calculo ", data);
    if (
      dados["emissaotp"] === "" ||
      dados["emissaotp"] === " " ||
      dados["emissaotp"] === undefined
    ) {
      errors.push("emissaotp");
    }
    if (
      dados["dt_nasc"] === "" ||
      dados["dt_nasc"] === undefined ||
      dados["dt_nasc"] <= data - 6570
    ) {
      errors.push("dt_nasc");
    }
    if (
      dados["nome"] === "" ||
      dados["nome"] === " " ||
      dados["nome"] === undefined
    ) {
      errors.push("nome");
    }
    //Esta validação de CPF não permite cpf em branco
    if (
      dados["cnpj_cpf"] !== "" &&
      dados["cnpj_cpf"] !== undefined &&
      !(cpf.isValid(dados["cnpj_cpf"]) || cnpj.isValid(dados["cnpj_cpf"]))
    ) {
      // if (dados['cnpj_cpf'] == "" || dados['cnpj_cpf'] !== undefined || !(cpf.isValid(dados['cnpj_cpf']) || cnpj.isValid(dados['cnpj_cpf']))) {
      console.log("IFFF");
      errors.push("cnpj_cpf");
    }
    setErrors(errors);
    console.log("errors ", errors);
    if (errors.length > 0) {
      toast("Preencha os campos obrigatórios.", { type: "error" });
      return false;
    }
    return true;
  }

  const cadastrarContrato = () => {
    if (editar) {
      const editedContrato = values;
      var key,
        keys = Object.keys(editedContrato);
      var n = keys.length;
      var newobj = {};
      while (n--) {
        key = keys[n];
        newobj[key.toLowerCase()] = editedContrato[key];
      }
      console.log(newobj);
      if (validarContratos(newobj)) {
        api.put(`/ct_plano/${editedContrato.IDPLANO}`, newobj).then((res) => {
          atualizaContratos();
          setShow(false);
        });
      }
    } else {
      values["IDPLANO"] = rows.length + 1;
      const createdContrato = values;
      let key,
        keys = Object.keys(createdContrato);
      let n = keys.length;
      let newobj = {};
      while (n--) {
        key = keys[n];
        newobj[key.toLowerCase()] = createdContrato[key];
      }
      if (validarContratos(newobj)) {
        api.post("/ct_plano", newobj).then((res) => {
          atualizaContratos();
          setShow(false);
        });
        api.get(`/buscaplano/` + createdContrato.CNPJ_CPF).then((resPlano) => {
          setFilteredPlano(resPlano.data);
          let id_plano = resPlano.data.IDPLANO;
          console.log("IdPlano :", resPlano.data);

          values["IDDEP"] = filteredDependentes.length + 1;
          const createdDependente = values;
          createdDependente.IDPLANO = values["IDPLANO"] = id_plano;
          let key,
            keys = Object.keys(createdDependente);
          let n = keys.length;
          let sendDependente = {};
          while (n--) {
            key = keys[n];
            sendDependente[key.toLowerCase()] = createdDependente[key];
          }
          console.log(sendDependente);
          if (validarDependencia(sendDependente)) {
            api.post(`/ct_pl_depend`, sendDependente);
            setDependentesData(false);
          }
        });
      }
    }
  };

  function validarDebito(dados) {
    console.log("dados ", dados);
    var errors = [];
    if (dados["dt_venc"] === "" || dados["dt_venc"] === undefined) {
      errors.push("dt_venc");
    }
    if (dados["valor"] === "" || dados["valor"] === undefined) {
      errors.push("valor");
    }
    setErrors(errors);
    console.log("errors.length ", errors.length);
    if (errors.length > 0) {
      toast("Preencha os campos obrigatórios.", { type: "error" });
      return false;
    }
    return true;
  }

  function validarDebitobaixar(dados) {
    console.log("dados ", dados);
    var errors = [];
    if (dados["vl_pago"] === "" || dados["vl_pago"] === undefined) {
      errors.push("vl_pago");
    }
    if (dados["dt_pagto"] === "" || dados["dt_pagto"] === undefined) {
      errors.push("dt_pagto");
    }
    setErrors(errors);
    console.log("errors.length ", errors.length);
    if (errors.length > 0) {
      toast("Preencha os campos obrigatórios.", { type: "error" });
      return false;
    }
    return true;
  }

  const cadastrarDebito = () => {
    if (editarDebitos) {
      const newRow = [...filteredDebitos];
      const codigo = newRow[editarIndex].IDDEBITO;
      newRow[editarIndex] = values;
      newRow[editarIndex].IDDEBITO = codigo;
      newRow[editarIndex].IDPLANO = planoAtual;
      var key,
        keys = Object.keys(newRow[editarIndex]);
      var n = keys.length;
      let sendDebito = {};
      while (n--) {
        key = keys[n];
        if (
          newRow[editarIndex][key] !== null &&
          newRow[editarIndex][key] !== undefined &&
          newRow[editarIndex][key] !== false &&
          newRow[editarIndex][key] !== true
        ) {
          sendDebito[key.toLowerCase()] = newRow[editarIndex][key].replace(
            ",",
            "."
          );
        } else {
          sendDebito[key.toLowerCase()] = newRow[editarIndex][key];
        }
      }
      console.log("DEBITO: ", sendDebito);
      if (validarDebito(sendDebito)) {
        api.put(`/ct_pl_debito/${codigo}`, sendDebito);
        setDebitosData(false);
      }
    } else {
      values["IDDEBITO"] = filteredDebitos.length + 1;
      const createdDebito = values;
      createdDebito.IDPLANO = planoAtual;
      let key,
        keys = Object.keys(createdDebito);
      let n = keys.length;
      let sendDebito = {};
      while (n--) {
        key = keys[n];
        sendDebito[key.toLowerCase()] = createdDebito[key];
      }
      console.log(sendDebito);
      if (validarDebito(sendDebito)) {
        api.post(`/ct_pl_debito`, sendDebito);
        setDebitosData(false);
      }
    }
  };

  const cbaixarDebito = () => {
    if (baixarDebitos) {
      const newRow = [...filteredDebitos];
      const codigo = newRow[baixarIndex].IDDEBITO;
      newRow[baixarIndex] = values;
      newRow[baixarIndex].IDDEBITO = codigo;
      newRow[baixarIndex].IDPLANO = planoAtual;
      var key,
        keys = Object.keys(newRow[baixarIndex]);
      var n = keys.length;
      let baixarDebito = {};
      while (n--) {
        key = keys[n];
        if (
          newRow[baixarIndex][key] !== null &&
          newRow[baixarIndex][key] !== undefined
        ) {
          baixarDebito[key.toLowerCase()] = newRow[baixarIndex][key].replace(
            ",",
            "."
          );
        } else {
          baixarDebito[key.toLowerCase()] = newRow[baixarIndex][key];
        }
      }
      console.log("DEBITO: ", baixarDebito);
      if (validarDebitobaixar(baixarDebito)) {
        api.put(`/ct_pl_debito/${codigo}`, baixarDebito);
        setDebitosDatabaixar(false);
      }
    }
  };

  const cadastrarInfos = () => {
    if (editarInfos) {
      const newRow = [...filteredInfos];
      const codigo = newRow[editarIndex].IDINFO;
      newRow[editarIndex] = values;
      newRow[editarIndex].IDINFO = codigo;
      newRow[editarIndex].IDPLANO = planoAtual;
      var key,
        keys = Object.keys(newRow[editarIndex]);
      var n = keys.length;
      let sendInfo = {};
      while (n--) {
        key = keys[n];
        if (
          newRow[editarIndex][key] !== null &&
          newRow[editarIndex][key] !== undefined &&
          newRow[editarIndex][key] !== false &&
          newRow[editarIndex][key] !== true
        ) {
          sendInfo[key.toLowerCase()] = newRow[editarIndex][key].replace(
            ",",
            "."
          );
        } else {
          sendInfo[key.toLowerCase()] = newRow[editarIndex][key];
        }
      }
      console.log("INFORMAÇÃO: ", sendInfo);
      api.put(`/ct_pl_info/${codigo}`, sendInfo);
      setInfosData(false);
    } else {
      values["IDINFO"] = filteredInfos.length + 1;
      const createdInfo = values;
      createdInfo.IDPLANO = planoAtual;
      let key,
        keys = Object.keys(createdInfo);
      let n = keys.length;
      let sendInfo = {};
      while (n--) {
        key = keys[n];
        sendInfo[key.toLowerCase()] = createdInfo[key];
      }
      console.log("Cadastro de Informação ", sendInfo);
      api.post(`/ct_pl_info`, sendInfo);
      setInfosData(false);
    }
  };

  const cadastrarTaxas = () => {
    if (editarTaxas) {
      const newRow = [...filteredTaxas];
      const codigo = newRow[editarIndex].IDTAXA;
      newRow[editarIndex] = values;
      newRow[editarIndex].IDTAXA = codigo;
      newRow[editarIndex].IDPLANO = planoAtual;
      var key,
        keys = Object.keys(newRow[editarIndex]);
      var n = keys.length;
      let sendTaxa = {};
      while (n--) {
        key = keys[n];
        if (
          newRow[editarIndex][key] !== null &&
          newRow[editarIndex][key] !== undefined &&
          newRow[editarIndex][key] !== false &&
          newRow[editarIndex][key] !== true
        ) {
          sendTaxa[key.toLowerCase()] = newRow[editarIndex][key].replace(
            ",",
            "."
          );
        } else {
          sendTaxa[key.toLowerCase()] = newRow[editarIndex][key];
        }
      }
      console.log("TAXA: ", sendTaxa);
      api.put(`/ct_pl_taxa/${codigo}`, sendTaxa);
      setTaxasData(false);
    } else {
      values["IDTAXA"] = filteredTaxas.length + 1;
      const createdTaxa = values;
      createdTaxa.IDPLANO = planoAtual;
      let key,
        keys = Object.keys(createdTaxa);
      let n = keys.length;
      let sendTaxa = {};
      while (n--) {
        key = keys[n];
        sendTaxa[key.toLowerCase()] = createdTaxa[key];
      }
      console.log(sendTaxa);
      api.post(`/ct_pl_taxa`, sendTaxa);
      setTaxasData(false);
    }
  };

  function validarDependencia(dados) {
    var errors = [];
    if (dados["nome"] === "" || dados["nome"] === undefined) {
      errors.push("nome");
    }
    //Esta validação de CPF permite cpf em branco
    if (
      dados["cnpj_cpf"] !== "" &&
      dados["cnpj_cpf"] !== undefined &&
      !(cpf.isValid(dados["cnpj_cpf"]) || cnpj.isValid(dados["cnpj_cpf"]))
    ) {
      console.log("IFFF");
      errors.push("cnpj_cpf");
    }
    setErrors(errors);
    if (errors.length > 0) {
      toast("Preencha os campos obrigatórios.", { type: "error" });
      return false;
    }
    return true;
  }

  function hasError(key) {
    return errors.indexOf(key) !== -1;
  }

  const cadastrarDependencia = () => {
    if (editarDependentes) {
      const newRow = [...filteredDependentes];
      const codigo = newRow[editarIndex].IDDEP;
      newRow[editarIndex] = values;
      newRow[editarIndex].IDDEP = codigo;
      newRow[editarIndex].IDPLANO = planoAtual;
      var key,
        keys = Object.keys(newRow[editarIndex]);
      var n = keys.length;
      let sendDependente = {};
      while (n--) {
        key = keys[n];
        sendDependente[key.toLowerCase()] = newRow[editarIndex][key];
      }
      console.log("ERRO ", errors);
      if (validarDependencia(sendDependente)) {
        api.put(`/ct_pl_depend/${codigo}`, sendDependente);
        setDependentesData(false);
      }
    } else {
      values["IDDEP"] = filteredDependentes.length + 1;
      const createdDependente = values;
      createdDependente.IDPLANO = planoAtual;
      let key,
        keys = Object.keys(createdDependente);
      let n = keys.length;
      let sendDependente = {};
      while (n--) {
        key = keys[n];
        sendDependente[key.toLowerCase()] = createdDependente[key];
      }
      console.log(sendDependente);
      if (validarDependencia(sendDependente)) {
        api.post(`/ct_pl_depend`, sendDependente);
        setDependentesData(false);
      }
    }
  };

  function editarContrato() {
    pegavar1();
    pegavar2();
    rows.map((row_aux, i) => {
      if (selectedValue === row_aux.IDPLANO) {
        console.log("IIIII ", i);
        const row = rows[i];
        console.log("row ", row);
        const auxEditar = row;
        setShow(true);
        setEditar(true);
        setValues(auxEditar);
        setErrors([]);
        somenteleitura = true;
      }
    });
  }

  function editarDebito(index) {
    api.get("/ct_pl_debitotp").then((res) => {
      const newRow = [...filteredDebitos];
      const row = newRow[index];
      setEditarIndex(index);
      var key,
        keys = Object.keys(row);
      var n = keys.length;
      let sendDebito = {};
      while (n--) {
        key = keys[n];
        console.log("erro: " + row[key]);
        if (
          row[key] !== null &&
          row[key] !== undefined &&
          row[key] !== true &&
          row[key] !== false
        ) {
          sendDebito[key.toUpperCase()] = row[key].replace(".", ",");
        } else {
          sendDebito[key.toUpperCase()] = row[key];
        }
      }
      console.log("ct_pl_debitotp", JSON.parse(JSON.stringify(res.data)));
      setDebitotp(res.data);
      console.log("sendDebito ", sendDebito);
      setEditarDebitos(true);
      setDebitosData(true);
      setValues(sendDebito);
      setShowDebitos(false);
    });
  }

  function baixarDebito(index) {
    api.get("/ct_pl_debitotp").then((res) => {
      const newRow = [...filteredDebitos];
      const row = newRow[index];
      setbaixarIndex(index);
      var key,
        keys = Object.keys(row);
      var n = keys.length;
      let baixarDebito = {};
      while (n--) {
        key = keys[n];
        if (
          row[key] !== null &&
          row[key] !== undefined &&
          row[key] !== true &&
          row[key] !== false
        ) {
          baixarDebito[key.toUpperCase()] = row[key].replace(".", ",");
        } else {
          baixarDebito[key.toUpperCase()] = row[key];
        }
      }
      console.log("ct_pl_debitotp", JSON.parse(JSON.stringify(res.data)));
      setDebitotp(res.data);
      console.log("baixarDebito ", baixarDebito);
      setbaixarDebitos(true);
      setDebitosDatabaixar(true);
      setValues(baixarDebito);
      setShowDebitos(false);
    });
  }

  function editarInfo(index) {
    api.get("/ct_pl_infotp").then((res) => {
      const newRow = [...filteredInfos];
      const row = newRow[index];
      setEditarIndex(index);
      var key,
        keys = Object.keys(row);
      var n = keys.length;
      let sendInfo = {};
      while (n--) {
        key = keys[n];
        if (
          row[key] !== null &&
          row[key] !== undefined &&
          row[key] !== true &&
          row[key] !== false
        ) {
          sendInfo[key.toUpperCase()] = row[key].replace(".", ",");
        } else {
          sendInfo[key.toUpperCase()] = row[key];
        }
      }
      console.log("ct_pl_infotp", JSON.parse(JSON.stringify(res.data)));
      setInfotp(res.data);
      console.log("sendInfo ", sendInfo);
      setEditarInfos(true);
      setInfosData(true);
      setValues(sendInfo);
      setShowInfos(false);
    });
  }

  function editarTaxa(index) {
    api.get("/ct_pl_taxatp").then((res) => {
      const newRow = [...filteredTaxas];
      const row = newRow[index];
      setEditarIndex(index);
      var key,
        keys = Object.keys(row);
      var n = keys.length;
      let sendTaxa = {};
      while (n--) {
        key = keys[n];
        if (
          row[key] !== null &&
          row[key] !== undefined &&
          row[key] !== true &&
          row[key] !== false
        ) {
          sendTaxa[key.toUpperCase()] = row[key].replace(".", ",");
        } else {
          sendTaxa[key.toUpperCase()] = row[key];
        }
      }
      console.log("ct_pl_taxatp", JSON.parse(JSON.stringify(res.data)));
      setTaxatp(res.data);
      console.log("sendTaxa ", sendTaxa);
      setEditarTaxas(true);
      setTaxasData(true);
      setValues(sendTaxa);
      setShowTaxas(false);
    });
  }

  function editarDependente(index) {
    const newRow = [...filteredDependentes];
    const row = newRow[index];
    setEditarIndex(index);
    console.log(row);
    const auxEditar = row;
    setEditarDependentes(true);
    setDependentesData(true);
    setValues(auxEditar);
    setShowDependentes(false);
  }

  function removerContrato() {
    const removeRow = rows[linhaARemover];
    console.log(removeRow);
    api.delete(`/ct_plano/${removeRow.IDPLANO}`).then((res) => {
      atualizaContratos();
      setShow(false);
    });
    modalDeleteClose();
    atualizaContratos();
  }

  function removerDebito() {
    const removeRow = filteredDebitos[debitoARemover];
    console.log(removeRow);
    api.delete(`/ct_pl_debito/${removeRow.IDDEBITO}`);
    setShowDeleteDebito(false);
    modalDeleteDebitoClose();
  }

  function removerbaixaDebito() {
    const removeRow = filteredDebitos[debitoADesbaixar];
    console.log(removeRow);
    api.put(`/ct_pl_debitodb/${removeRow.IDDEBITO}`);
    setShowDesbaixaDebito(false);
    modalDesbaixaDebitoClose();
  }

  function removerInfo() {
    const removeRow = filteredInfos[InfosARemover];
    console.log(removeRow);
    api.delete(`/ct_pl_info/${removeRow.IDINFO}`);
    setShowDeleteInfo(false);
    modalDeleteInfoClose();
  }

  function removerInfoRecorre(index) {
    api.delete(`/ct_pl_info/${index}`);
    setShowDeleteInfo(false);
    modalDeleteInfoClose();
  }

  function removerTaxa() {
    const removeRow = filteredTaxas[TaxasARemover];
    console.log(removeRow);
    api.delete(`/ct_pl_taxa/${removeRow.IDTAXA}`);
    setShowDeleteTaxa(false);
    modalDeleteTaxaClose();
  }

  function removerDependente() {
    const removeRow = filteredDependentes[dependenteARemover];
    console.log(removeRow);
    api.delete(`/ct_pl_depend/${removeRow.IDDEP}`);
    setShowDeleteDependencia(false);
    modalDeleteDebitoClose();
  }

  function dataAtualFormatada() {
    var data = new Date(),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? "0" + mes : mes,
      anoF = data.getFullYear();
    return anoF + "-" + mesF + "-" + diaF;
  }

  function pegavar2() {
    api.get(`/pegavariaveis/` + "nDiasCarenciaAtend").then((res) => {
      setFilteredVar2(res.data);
      ndcarenciaAt = parseInt(res.data.VALOR);
      console.log("C. AT :", res.data);
      console.log("C. AT :", ndcarenciaAt);
    });
  }

  function pegavar1() {
    api.get(`/pegavariaveis/` + "nDiasCarenciaPagto").then((res) => {
      setFilteredVar1(res.data);
      ndcarenciaPg = parseInt(res.data.VALOR);
      console.log("C. PG :", res.data);
      console.log("C. PG :", ndcarenciaPg);
    });
  }

  function somaData(datasoma) {
    console.log("Carencia Atendimento :" + ndcarenciaAt);
    console.log("Carencia Pagamento :" + ndcarenciaPg);
    console.log(datasoma);
    //var time = new Date(datasoma);
    var time = new Date(moment(datasoma).format("YYYY/MM/DD"));
    console.log(time);
    var atendimento = new Date();
    var pagamento = new Date();
    atendimento.setDate(time.getDate() + ndcarenciaAt);
    pagamento.setDate(time.getDate() + ndcarenciaPg);
    console.log(atendimento);
    console.log(pagamento);

    var data = new Date(atendimento),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? "0" + mes : mes,
      anoF = data.getFullYear();
    document.getElementById("CAREN_AT").value = anoF + "-" + mesF + "-" + diaF;
    values.CAREN_AT = anoF + "-" + mesF + "-" + diaF;

    var data = new Date(pagamento),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? "0" + mes : mes,
      anoF = data.getFullYear();
    document.getElementById("CAREN_PG").value = anoF + "-" + mesF + "-" + diaF;
    values.CAREN_PG = anoF + "-" + mesF + "-" + diaF;
  }

  function onBlurTaxa(IDPLANO, CONSIDERA, CATEGORIA) {
    if (
      CONSIDERA === "SIM" &&
      (CATEGORIA === "ADESAO" || CATEGORIA === "MANUTENCAO")
    ) {
      let plano = planoAtual;
      let venc = dataAtualFormatada();
      api
        .get(`/buscataxa/` + plano + `&` + CATEGORIA + `&` + venc)
        .then((resTaxa) => {
          setFilteredTaxa(resTaxa.data);
          let valor = resTaxa.data.VALOR_TX;
          console.log("VALOR_TX:", resTaxa.data);
          if (valor > 0) {
            document.getElementById("VALOR_TX").value = formatCurrency(valor);
            values.VALOR = valor;
          } else {
            document.getElementById("VALOR_TX").value = formatCurrency("0.00");
          }
        });
    } else {
      document.getElementById("VALOR_TX").value = formatCurrency("0.00");
    }
  }

  function onBlurSTaxa(VALOR1, VALOR2) {
    function convert_to_float(a) {
      var floatValue = parseFloat(a.replace(/, /, "."), 2);
      return floatValue;
    }
    function formataFloat(valor) {
      if (valor !== null && valor !== undefined) {
        return valor.replace(",", ".");
      }
      return valor;
    }
    let valor1 = parseFloat(formataFloat(VALOR1), 2);
    let valor2 = parseFloat(formataFloat(VALOR2), 2);
    let valor3 = valor1 + valor2;
    console.log("valor1", valor1);
    console.log("valor2", valor2);
    console.log("valor3", valor3);
    //document.getElementById('VALORTOTAL').value = formatCurrency((valor3+""));
    //values.VALOR = formatCurrency((valor3+""));
    values.VALOR = valor3 + "";
    document.getElementById("QTD").focus();
  }

  function onBlurQtd(qtd) {
    if (qtd <= 0) {
      document.getElementById("QTD").focus();
    }
  }

  function onBlurVenc(Venc, qtd, CONSIDERA, CATEGORIA) {
    function formataFloat(valor) {
      if (valor !== null && valor !== undefined) {
        return valor.replace(",", ".");
      }
      return valor;
    }
    let diad = "";
    let diadF = "";
    let mesd = "";
    let anodF = "";
    let diaF = "";
    let nParcela = qtd;
    var data = new Date(moment(Venc).format("YYYY/MM/DD"));
    diad = data.getDate().toString();
    diaF = diad.length == 1 ? "0" + diad : diad;
    mesd = data.getMonth() + 1;
    anodF = data.getFullYear();

    for (var i = 0; i < nParcela; i = i + 1) {
      diadF = diaF;
      if (i > 0) {
        switch (mesd) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12:
            if (diadF >= 31) {
              diadF = 31;
            }
            break;
          case 4:
          case 6:
          case 9:
          case 11:
            if (diadF >= 30) {
              diadF = 30;
            }
            break;
          case 2:
            if (anodF % 400 == 0 || (anodF % 4 == 0 && anodF % 100 != 0)) {
              if (diadF >= 29) {
                diadF = 29;
              }
              break;
            } else {
              if (diadF >= 28) {
                diadF = 28;
              }
              break;
            }
        }
      }
      if (mesd > 12) {
        anodF++;
        mesd = 1;
      }

      let mes = mesd.toString();
      let mesF = mes.length == 1 ? "0" + mes : mes;
      let anoF = anodF.toString().substr(-2);
      let datavenc = anodF + "-" + mesd + "-" + diadF;
      let refer = mesF + "/" + anoF;
      let valor3 = 0;
      let valor1 = 0;
      let parc = i + 1;
      let valor2 = parseFloat(formataFloat(values.VALOR_AD), 2);

      if (
        CONSIDERA === "SIM" &&
        (CATEGORIA === "ADESAO" || CATEGORIA === "MANUTENCAO")
      ) {
        //valor1 = buscatx(CONSIDERA, CATEGORIA, datavenc);
        api
          .get(`/buscataxa/` + planoAtual + `&` + CATEGORIA + `&` + datavenc)
          .then((resTaxa) => {
            setFilteredTaxa(resTaxa.data);
            console.log("VALOR_TX:", resTaxa.data);
            valor1 = parseFloat(formataFloat(resTaxa.data.VALOR_TX), 2);
            if (valor1 > 0) {
              if (valor2 > 0) {
                valor3 = valor1 + valor2;
              } else {
                valor3 = valor1;
              }
            } else {
              valor3 = valor2;
            }
            console.log("Valor1 ", valor1);
            values.VALOR = valor3;
            values.REFERENCIA = refer;
            values.DT_VENC = datavenc;
            values.ORDEM = parc;

            values["IDDEBITO"] = filteredDebitos.length + 1;
            const createdDebito = values;
            createdDebito.IDPLANO = planoAtual;
            let key,
              keys = Object.keys(createdDebito);
            let n = keys.length;
            let sendDebito = {};
            while (n--) {
              key = keys[n];
              sendDebito[key.toLowerCase()] = createdDebito[key];
            }
            console.log(sendDebito);

            //if (validarDebito(sendDebito)) {
            api.post(`/ct_pl_debito`, sendDebito);
            //setDebitosDataInsert(false);
            //}
          });
      } else {
        valor3 = valor2;
        values.VALOR = valor3;
        values.REFERENCIA = refer;
        values.DT_VENC = datavenc;
        values.ORDEM = parc;

        values["IDDEBITO"] = filteredDebitos.length + 1;
        const createdDebito = values;
        createdDebito.IDPLANO = planoAtual;
        let key,
          keys = Object.keys(createdDebito);
        let n = keys.length;
        let sendDebito = {};
        while (n--) {
          key = keys[n];
          sendDebito[key.toLowerCase()] = createdDebito[key];
        }
        console.log(sendDebito);
        //if (validarDebito(sendDebito)) {
        api.post(`/ct_pl_debito`, sendDebito);
        //setDebitosDataInsert(false);
        //}
      }

      setTimeout(5000);
      mesd++;
    }
    setDebitosDataInsert(false);
    handleCloseDebitos(false);
  }

  //CEP
  function onBlurCep(ev) {
    const { value } = ev.target;
    const cep = value?.replace(/[^0-9]/g, "");
    if (cep?.length !== 8) {
      return;
    }
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        document.getElementById("ENDERECO").value = data.logradouro;
        document.getElementById("BAIRRO").value = data.bairro;
        document.getElementById("CIDADE").value = data.localidade;
        document.getElementById("ESTADO").value = data.uf;
        document.getElementById("PAIS").value = "Brasil";

        values.ENDERECO = data.logradouro;
        values.BAIRRO = data.bairro;
        values.CIDADE = data.localidade;
        values.ESTADO = data.uf;
        values.PAIS = "Brasil";

        document.getElementById("NUMERO").focus();
      });
  }
  //Termina CEP

  return (
    <>
      <Card>
        <CardHeader title="Contratos (Novo)">
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <form className={classesSelect.root} autoComplete="off">
            <FormControl className={classesSelect.margin}>
              <FormHelperText>Filtrar por Inscrição / Nome</FormHelperText>
              <BootstrapInput
                onChange={filtrarNome}
                id="age-customized-input"
              />
            </FormControl>
            <FormControl
              className={classesSelect.margin}
              style={{ marginRight: 15 }}
            >
              <FormHelperText>Filtrar por CPF/CNPJ</FormHelperText>
              <BootstrapInput onChange={filtrarCPF} id="age-customized-input" />
            </FormControl>
            <div style={{ marginTop: 18 }}>
              <Fab
                title="Adicionar"
                className={classes.fab}
                color="primary"
                onClick={handleShow}
                aria-label="Edit"
              >
                <Icon>add</Icon>
              </Fab>
              <Fab
                title="Editar"
                className={classes.fab}
                color="secondary"
                onClick={() => editarContrato()}
                aria-label="Edit"
              >
                <Icon>edit_icon</Icon>
              </Fab>
              <Fab
                title="Apagar"
                className={classes.fab}
                aria-label="Delete"
                onClick={() => modalDeleteShow()}
              >
                <DeleteIcon />
              </Fab>
              <Link to={`/imprimir/${selectedValue}`}>
                <Fab
                  title="Imprimir"
                  className={classes.fab}
                  color="inherit"
                  aria-label="Imprimir"
                  onClick={handlePrint}
                >
                  <Icon>print</Icon>
                </Fab>
              </Link>
              <Link to={`/icontrato/${selectedValue}`}>
                <Fab
                  title="Contrato"
                  className={classes.fab}
                  color="inherit"
                  aria-label="Contrato"
                  onClick={handlePrint}
                >
                  <Icon>description</Icon>
                </Fab>
              </Link>
            </div>
          </form>
          <Paper className={classes.root}>
            <TableContainer className={classes.tcontainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">Inscrição</TableCell>
                    <TableCell align="center">Nome</TableCell>
                    <TableCell align="center">Cidade</TableCell>
                    <TableCell align="center">CPF/CNPJ</TableCell>
                    <TableCell align="center">RG/IE</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsBusca
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow key={row.IDPLANO}>
                          <TableCell align="center">
                            <Radio
                              checked={selectedValue === row.IDPLANO}
                              onChange={handleChangeRadio}
                              value={row.IDPLANO}
                              color="default"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "D" }}
                            />
                          </TableCell>
                          <TableCell align="center">{row.IDPLANO}</TableCell>
                          <TableCell align="center">{row.NOME}</TableCell>
                          <TableCell align="center">{row.CIDADE}</TableCell>
                          <TableCell align="center">
                            {row.CNPJ_CPF
                              ? row.CNPJ_CPF.replace(
                                  /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                                  "$1.$2.$3-$4"
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row.IE_RG}</TableCell>
                          <TableCell align="center">
                            <Fab
                              title="Dependentes"
                              className={classes.fab}
                              color="secondary"
                              onClick={() =>
                                showOrHideActions(
                                  row.INDICE - 1,
                                  row.IS_SHOW_ACTION
                                )
                              }
                              aria-label="Dependentes"
                              disabled={row.MEMCANMOM == null ? false : true}
                            >
                              <Icon>zoom_out_map</Icon>
                            </Fab>
                            {row.IS_SHOW_ACTION ? (
                              <div>
                                <Fab
                                  title="Dependentes"
                                  className={classes.fab}
                                  color="primary"
                                  onClick={() =>
                                    modalDependentesShow(row.INDICE - 1)
                                  }
                                  aria-label="Dependentes"
                                >
                                  <Icon>people</Icon>
                                </Fab>
                                <Fab
                                  title="Débitos"
                                  className={classes.fab}
                                  color="primary"
                                  onClick={() =>
                                    modalDebitosShow(row.INDICE - 1)
                                  }
                                  aria-label="Débitos"
                                >
                                  <Icon>attach_money</Icon>
                                </Fab>
                                <br />
                                <Fab
                                  title="Informações"
                                  className={classes.fab}
                                  color="primary"
                                  onClick={() => modalInfosShow(row.INDICE - 1)}
                                  aria-label="Informações"
                                >
                                  <Icon>info_outlined</Icon>
                                </Fab>
                                <Fab
                                  title="Taxas"
                                  className={classes.fab}
                                  color="primary"
                                  onClick={() => modalTaxasShow(row.INDICE - 1)}
                                  aria-label="Taxas"
                                >
                                  <Icon>
                                    <b>%</b>
                                  </Icon>
                                </Fab>{" "}
                                <br />
                                <Fab
                                  title="Débitos"
                                  className={classes.fab}
                                  color="primary"
                                  onClick={() =>
                                    modalDebitosCobShow(row.INDICE - 1)
                                  }
                                  aria-label="Débitos"
                                >
                                  <Icon>payment</Icon>
                                </Fab>
                              </div>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={rowsBusca.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              //onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              //onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Registros por Página:"}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          </Paper>
        </CardBody>
      </Card>

      <Modal size="lg" show={show} onHide={handleClose}>
        <form autoComplete="off" onSubmit={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Cadastro de Contrato</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>Identificação</b>
            <hr />
            <TextField autoComplete="off"
              required
              onChange={handleChangeModal}
              name="VENDADT"
              value={values.VENDADT}
              id="outlined-multiline-flexible"
              label="Data de venda"
              type="date"
              rowsMax="4"
              onBlur={(ev) => somaData(values.VENDADT)}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
              error={hasError("vendadt")}
            />
            <TextField autoComplete="off"
              required
              onChange={handleChangeModal}
              name="VENDADTPROP"
              value={values.VENDADTPROP}
              id="outlined-multiline-flexible"
              label="Data Proposta"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              required
              onChange={handleChangeModal}
              name="NOME"
              value={values.NOME}
              id="outlined-multiline-flexible"
              label="Nome"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              error={hasError("nome")}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DT_NASC"
              value={values.DT_NASC}
              id="outlined-multiline-flexible"
              label="Data Nascimento"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={somenteleitura}
              style={{ width: 232 }}
              error={hasError("dt_nasc")}
            />

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Grau
              </InputLabel>
              <Select
                value={values.CODGRAU ? values.CODGRAU : ""}
                name="CODGRAU"
                label="Grau"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                disabled={somenteleitura}
                input={
                  <OutlinedInput
                    name="agegrau"
                    id="outlined-age-simple"
                    label="Grau"
                  />
                }
              >
                {graus.map((row, index) => (
                  <MenuItem value={row.CODGRAU} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Sexo
              </InputLabel>
              <Select
                value={values.SEXO ? values.SEXO : " "}
                name="SEXO"
                label="Sexo"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                disabled={somenteleitura}
                input={
                  <OutlinedInput
                    name="ages"
                    id="outlined-age-simple"
                    label="Sexo"
                  />
                }
              >
                {sexo.map((row, index) => (
                  <MenuItem value={row.CODSEXO} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Religião
              </InputLabel>
              <Select
                value={values.RELIGIAO ? values.RELIGIAO : " "}
                name="RELIGIAO"
                label="Religiao"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                disabled={somenteleitura}
                input={
                  <OutlinedInput
                    name="ager"
                    id="outlined-age-simple"
                    label="Religiao"
                  />
                }
              >
                {religiao.map((row, index) => (
                  <MenuItem value={row.CODRELIG} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Estado Civil
              </InputLabel>
              <Select
                value={values.ESTCIVIL ? values.ESTCIVIL : " "}
                name="ESTCIVIL"
                label="Estcivil"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                disabled={somenteleitura}
                input={
                  <OutlinedInput
                    name="ageec"
                    id="outlined-age-simple"
                    label="Estcivil"
                  />
                }
              >
                {estcivil.map((row, index) => (
                  <MenuItem value={row.CODESTCIV} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CNPJ_CPF"
              value={values.CNPJ_CPF}
              id="outlined-multiline-flexible"
              label="CNPJ/CPF "
              type="number"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              error={hasError("cnpj_cpf")}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="IE_RG"
              value={values.IE_RG}
              id="outlined-multiline-flexible"
              label="Inscrição Estadual/RG"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />
            <hr />
            <b>Classificação</b>
            <hr />

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Grupo
              </InputLabel>
              <Select
                value={values.GRUPO ? values.GRUPO : " "}
                name="GRUPO"
                label="Grupo"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="ageg"
                    id="outlined-age-simple"
                    label="Grupo"
                  />
                }
              >
                {grupos.map((row, index) => (
                  <MenuItem value={row.CODGRUPO} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/*<TextField autoComplete="off"
              onChange={handleChangeModal}
              name="NUMNOGRUPO"
              value={values.NUMNOGRUPO}
              id="outlined-multiline-flexible"
              label="Nro. do Grupo"
              type="number"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />*/}

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Categoria
              </InputLabel>
              <Select
                value={values.CATEGORIA ? values.CATEGORIA : " "}
                name="CATEGORIA"
                label="Categoria"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="age"
                    id="outlined-age-simple"
                    label="Categoria"
                  />
                }
              >
                {categorias.map((row, index) => (
                  <MenuItem value={row.CODCATEG} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Familia
              </InputLabel>
              <Select
                value={values.FAMILIA ? values.FAMILIA : " "}
                name="FAMILIA"
                label="Familia"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="agefm"
                    id="outlined-age-simple"
                    label="Familia"
                  />
                }
              >
                {familias.map((row, index) => (
                  <MenuItem value={row.CODFAMILIA} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Tipo de Venda
              </InputLabel>
              <Select
                value={values.VENDATIPO ? values.VENDATIPO : " "}
                name="VENDATIPO"
                label="Tipo de Venda"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="agetpv"
                    id="outlined-age-simple"
                    label="Tipo de Venda"
                  />
                }
              >
                {tpvendas.map((row, index) => (
                  <MenuItem value={row.CODTIPO} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              name="emissaotp"
              required
              error={hasError("emissaotp")}
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Convênio
              </InputLabel>
              <Select
                value={values.EMISSAOTP ? values.EMISSAOTP : " "}
                name="EMISSAOTP"
                label="Convênio"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="ageconv"
                    id="outlined-age-simple"
                    label="Convênio"
                  />
                }
              >
                {convenio.map((row, index) => (
                  <MenuItem value={row.CODCONV} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Vendedor
              </InputLabel>
              <Select
                value={values.VENDEDOR ? values.VENDEDOR : " "}
                name="VENDEDOR"
                label="Vendedor"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="agevend"
                    id="outlined-age-simple"
                    label="Vendedor"
                  />
                }
              >
                {vendedor.map((row, index) => (
                  <MenuItem value={row.IDVENDEDOR} key={index}>
                    {row.NOME}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <hr />
            <b>Endereço de Cobrança</b>
            <hr />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CEP"
              value={values.CEP}
              id="outlined-multiline-flexible"
              label="CEP"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              onBlur={(ev) => onBlurCep(ev)}
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              onBlur={handleChangeModal}
              name="ENDERECO"
              value={values.ENDERECO}
              //type={values.ENDERECO}
              id="ENDERECO"
              label="Endereço"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              onFocus={handleChangeModal}
              id="NUMERO"
              name="NUMERO"
              value={values.NUMERO}
              label="Número"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="COMPLEMENT"
              value={values.COMPLEMENT}
              id="outlined-multiline-flexible"
              label="Complemento"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              onBlur={handleChangeModal}
              name="BAIRRO"
              value={values.BAIRRO}
              id="BAIRRO"
              label="Bairro"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              onBlur={handleChangeModal}
              name="CIDADE"
              value={values.CIDADE}
              id="CIDADE"
              label="Cidade"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              onBlur={handleChangeModal}
              name="ESTADO"
              value={values.ESTADO}
              id="ESTADO"
              label="Estado"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              onBlur={handleChangeModal}
              name="PAIS"
              value={values.PAIS}
              id="PAIS"
              label="País"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              //InputProps={{
              //  startAdornment: <InputAdornment position="end"> </InputAdornment>,
              //}}
              InputLabelProps={{ shrink: true }}
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CPOSTAL"
              value={values.CPOSTAL}
              id="outlined-multiline-flexible"
              label="Caixa Postal"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <hr />
            <b>Contato</b>
            <hr />

            <InputMask
              onChange={handleChangeModal}
              value={values.FONE}
              mask="(99) 9999-9999"
              maskChar=" "
            >
              {() => (
                <TextField autoComplete="off"
                  onChange={handleChangeModal}
                  name="FONE"
                  value={values.FONE}
                  id="outlined-multiline-flexible"
                  label="Telefone"
                  type="text"
                  rowsMax="4"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  style={{ width: 232 }}
                />
              )}
            </InputMask>

            <InputMask
              onChange={handleChangeModal}
              value={values.FAX}
              mask="(99)99999-9999"
              maskChar=" "
            >
              {() => (
                <TextField autoComplete="off"
                  onChange={handleChangeModal}
                  name="FAX"
                  value={values.FAX}
                  id="outlined-multiline-flexible"
                  label="Celular"
                  type="text"
                  rowsMax="4"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  style={{ width: 232 }}
                />
              )}
            </InputMask>

            <InputMask
              onChange={handleChangeModal}
              value={values.CELULAR}
              mask="(99)99999-9999"
              maskChar=" "
            >
              {() => (
                <TextField autoComplete="off"
                  onChange={handleChangeModal}
                  name="CELULAR"
                  value={values.CELULAR}
                  id="outlined-multiline-flexible"
                  label="Celular"
                  type="text"
                  rowsMax="4"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  style={{ width: 232 }}
                />
              )}
            </InputMask>

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CONTATO"
              value={values.CONTATO}
              id="outlined-multiline-flexible"
              label="Contato"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="EMAIL"
              value={values.EMAIL}
              id="outlined-multiline-flexible"
              label="E-mail"
              type="email"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <hr />
            <b>Controle</b>
            <hr />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CAREN_AT"
              value={values.CAREN_AT}
              id="CAREN_AT"
              label="Carência Atend."
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CAREN_PG"
              value={values.CAREN_PG}
              id="CAREN_PG"
              label="Carência Pagto"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
            />
            <hr />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="OBSERVACAO"
              value={values.OBSERVACAO}
              id="outlined-multiline-flexible"
              label="Observação"
              type="text"
              multiline
              rows="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 725 }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button
              className={classes.button}
              onClick={handleSubmit1(cadastrarContrato)}
              type="submit"
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showDelete} onHide={modalDeleteClose}>
        <form onSubmit={modalDeleteClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Remover contrato</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>Tem certeza que deseja remover o contrato?</h3>
            <br />
            Esta ação <strong>não</strong> pode ser desfeita.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={modalDeleteClose}>
              Não
            </Button>
            <Button
              variant="contained"
              onClick={removerContrato}
              type="button"
              color="primary"
              className={classes.button}
            >
              Sim
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {/***********************DÉBITOS********************** */}
      <Modal size="xl" show={showDebitos} onHide={handleCloseDebitos}>
        <form onSubmit={handleCloseDebitos}>
          <Modal.Header closeButton>
            <Modal.Title>Débitos do contrato</Modal.Title>
            {/*<Button variant="contained" color="primary" onClick={handleDebitosInsert} className={classes.button}>
                Adicionar Adesão
            </Button>*/}
            <Button
              variant="contained"
              color="primary"
              onClick={handleDebitosInsert}
              className={classes.button}
            >
              Adicionar Débito
            </Button>
            {/*<Fab className={classes.fab} color="primary" onClick={handleDebitosInsert} aria-label="Adesao">
                <Icon>add</Icon>
            </Fab>
             <Fab className={classes.fab} color="secondary" onClick={handleDebitos} aria-label="Manutencao">
                <Icon>add_box</Icon>
            </Fab> Manutenção        */}
          </Modal.Header>
          <Modal.Body>
            <Paper className={classes.root}>
              <TableContainer className={classes.tcontainerF}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Categoria</TableCell>
                      <TableCell align="center">Tipo</TableCell>
                      <TableCell align="center">Vencimento</TableCell>
                      <TableCell align="center">Valor</TableCell>
                      <TableCell align="center">Data Pagamento</TableCell>
                      <TableCell align="center">Valor Pago</TableCell>

                      <TableCell align="center">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredDebitos
                      .slice(
                        pageDeb * rowsPerPageDeb,
                        pageDeb * rowsPerPageDeb + rowsPerPageDeb
                      )
                      .map((rowsDeb, index) => {
                        return (
                          <TableRow
                            key={rowsDeb.IDDEBITO}
                            className={`${
                              rowsDeb.DT_PAGTO != null
                                ? classes.tableA
                                : `${
                                    rowsDeb.DT_VENC < dataAtualFormatada()
                                      ? classes.tableC
                                      : classes.tableB
                                  }`
                            }`}
                          >
                            <TableCell align="center">
                              {rowsDeb.CATEGORIA}
                            </TableCell>
                            <TableCell align="center">
                              {rowsDeb.DESCRICAO}
                            </TableCell>
                            <TableCell align="center">
                              {rowsDeb.DT_VENC != null
                                ? moment(rowsDeb.DT_VENC).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              {formatCurrency(rowsDeb.VALOR)}
                            </TableCell>
                            <TableCell align="center">
                              {rowsDeb.DT_PAGTO != null
                                ? moment(rowsDeb.DT_PAGTO).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              {formatCurrency(rowsDeb.VL_PAGO)}
                            </TableCell>
                            <TableCell align="center">
                              <Fab
                                title="Débitos"
                                className={classes.fab}
                                color="secondary"
                                onClick={() =>
                                  showOrHideActionsD(
                                    pageDeb === 0
                                      ? index
                                      : index + pageDeb * rowsPerPageDeb,
                                    rowsDeb.IS_SHOW_ACTION_D
                                  )
                                }
                                aria-label="Debitos"
                              >
                                <Icon>zoom_out_map</Icon>
                              </Fab>
                              {rowsDeb.IS_SHOW_ACTION_D ? (
                                <div>
                                  <Fab
                                    title="Editar"
                                    className={classes.fab}
                                    color="primary"
                                    aria-label="Edit"
                                    onClick={() =>
                                      editarDebito(
                                        pageDeb === 0
                                          ? index
                                          : index + pageDeb * rowsPerPageDeb
                                      )
                                    }
                                    disabled={
                                      rowsDeb.DT_PAGTO != null ? true : false
                                    }
                                  >
                                    <Icon>edit_icon</Icon>
                                  </Fab>
                                  <Fab
                                    title="Apagar"
                                    className={classes.fab}
                                    color="primary"
                                    aria-label="Delete"
                                    onClick={() =>
                                      modalDeleteDebitoShow(
                                        pageDeb === 0
                                          ? index
                                          : index + pageDeb * rowsPerPageDeb
                                      )
                                    }
                                    disabled={
                                      rowsDeb.DT_PAGTO != null ? true : false
                                    }
                                  >
                                    <DeleteIcon />
                                  </Fab>
                                  <br />
                                  <Fab
                                    title="Gerar Cobrança"
                                    className={classes.fab}
                                    color="primary"
                                    aria-label="Gerar Cobrança"
                                    onClick={() =>
                                      modalgerarCobranca(
                                        rowsDeb.IDDEBITO,
                                        rowsDeb.DT_VENC,
                                        rowsDeb.VALOR,
                                        rowsDeb.DESCRICAO,
                                        rowsDeb.CATEGORIA,
                                        cadGalax.IDPLANO,
                                        cadGalax.EMISSAOTP,
                                        cadGalax.IDPLANO,
                                        cadGalax.NOME,
                                        cadGalax.CNPJ_CPF,
                                        cadGalax.IE_RG,
                                        cadGalax.EMAIL,
                                        cadGalax.CELULAR,
                                        cadGalax.FONE,
                                        cadGalax.CEP,
                                        cadGalax.ENDERECO,
                                        cadGalax.NUMERO,
                                        cadGalax.COMPLEMENT,
                                        cadGalax.CIDADE,
                                        cadGalax.BAIRRO,
                                        cadGalax.ESTADO,
                                        cadGalax.PAIS
                                      )
                                    }
                                    disabled={
                                      rowsDeb.DT_PAGTO != null ? true : false
                                    }
                                  >
                                    <Icon>payments</Icon>
                                  </Fab>
                                  <Fab
                                    className={classes.fab}
                                    color="primary"
                                    onClick={() =>
                                      `${
                                        rowsDeb.DT_PAGTO != null
                                          ? null
                                          : baixarDebito(
                                              pageDeb === 0
                                                ? index
                                                : index +
                                                    pageDeb * rowsPerPageDeb
                                            )
                                      }`
                                    }
                                    disabled={
                                      rowsDeb.DT_PAGTO != null ? true : false
                                    }
                                    aria-label="Baixar Débito"
                                  >
                                    <Icon>check</Icon>
                                  </Fab>
                                  <br />
                                  <Fab
                                    title="Desfazer Baixa"
                                    className={classes.fab}
                                    color="primary"
                                    onClick={() =>
                                      `${
                                        rowsDeb.DT_PAGTO != null
                                          ? modalDesbaixaDebitoShow(
                                              pageDeb === 0
                                                ? index
                                                : index +
                                                    pageDeb * rowsPerPageDeb
                                            )
                                          : null
                                      }`
                                    }
                                    disabled={
                                      rowsDeb.DT_PAGTO != null ? false : true
                                    }
                                    aria-label="Desfaz Baixa"
                                  >
                                    <Icon>undo</Icon>
                                  </Fab>
                                </div>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredDebitos.length}
                rowsPerPage={rowsPerPageDeb}
                page={pageDeb}
                onChangePage={handleChangePageDeb}
                //onPageChange={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPageDeb}
                //onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Débitos por Página:"}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </Paper>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDebitos}>
              Fechar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {/**************Remover Debito**************** */}
      <Modal show={showDeleteDebito} onHide={modalDeleteDebitoClose}>
        <form onSubmit={modalDeleteDebitoClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Remover débito</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>Tem certeza que deseja remover o débito?</h3>
            <br />
            Esta ação <strong>não</strong> pode ser desfeita.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={modalDeleteDebitoClose}>
              Não
            </Button>
            <Button
              variant="contained"
              onClick={removerDebito}
              type="button"
              color="primary"
              className={classes.button}
            >
              Sim
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {/**************DESFAZ BAIXAR**************** */}
      <Modal show={showDesbaixaDebito} onHide={modalDesbaixaDebitoClose}>
        <form onSubmit={modalDesbaixaDebitoClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Desfazer a Baixa do débito</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>Tem certeza que deseja desfazer a baixa do débito?</h3>
            <br />
            Esta ação <strong>não</strong> pode ser desfeita.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={modalDesbaixaDebitoClose}>
              Não
            </Button>
            <Button
              variant="contained"
              onClick={removerbaixaDebito}
              type="button"
              color="primary"
              className={classes.button}
            >
              Sim
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {/*****************ATÉ AQUI********************** */}
      <Modal size="lg" show={DebitosData} onHide={handleDebitosData}>
        <form onSubmit={handleDebitosData}>
          <Modal.Header closeButton>
            <Modal.Title>Cadastro/Alteração de Débito</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Classificação
            <hr />
            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Categoria
              </InputLabel>
              <Select
                value={values.CATEGORIA ? values.CATEGORIA : " "}
                name="CATEGORIA"
                label="Categoria"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                // margin="normal"
                // variant="outlined"
                input={<OutlinedInput name="ageDeb" id="outlined-age-simple" />}
              >
                <MenuItem value="ADESAO">Adesão</MenuItem>
                <MenuItem value="MANUTENCAO">Manutenção</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Tipo Débito
              </InputLabel>
              <Select
                value={values.TIPO ? values.TIPO : " "}
                name="TIPO"
                label="Tipo"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="agetpv"
                    id="outlined-age-simple"
                    label="Tipo de Venda"
                  />
                }
              >
                {debitotp.map((row, index) => (
                  <MenuItem value={row.CODTIPO} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <hr />
            Geral
            <hr />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="REFERENCIA"
              value={values.REFERENCIA}
              id="outlined-multiline-flexible"
              label="Referencia"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="ORDEM"
              value={values.ORDEM}
              id="outlined-multiline-flexible"
              label="Ordem"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />
            <TextField autoComplete="off"
              required
              onChange={handleChangeModalCurrency}
              onKeyUp={maskCurrency}
              name="VALOR"
              value={values.VALOR}
              id="outlined-multiline-flexible"
              label="Valor"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              error={hasError("valor")}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModalCurrency}
              onKeyUp={maskCurrency}
              name="Juros"
              value={values.JUROS}
              id="outlined-multiline-flexible"
              label="Juros"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              error={hasError("valor")}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DT_VENC"
              value={values.DT_VENC}
              id="outlined-multiline-flexible"
              label="Data de Vencimento"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
            />
            <br />
            <hr />
            Baixa - Para baixar clique no botão baixar débito na tela de débitos
            <hr />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="BAIXATIPO"
              value={values.BAIXATIPO}
              id="outlined-multiline-flexible"
              label="Tipo Baixa"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              disabled={true}
              hidden={true}
              style={{ width: 232 }}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DT_PAGTO"
              value={values.DT_PAGTO}
              id="outlined-multiline-flexible"
              label="Data de Pagamento"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              disabled={true}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModalCurrency}
              onKeyUp={maskCurrency}
              name="VL_PAGO"
              value={values.VL_PAGO}
              id="outlined-multiline-flexible"
              label="Valor Pago"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              disabled={true}
              style={{ width: 232 }}
              error={hasError("vl_pago")}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="COBRADOR"
              value={values.IDCOBRADOR}
              id="outlined-multiline-flexible"
              label="Cobrador"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              disabled={true}
              style={{ width: 232 }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDebitosData}>
              Fechar
            </Button>
            <Button
              className={classes.button}
              onClick={handleSubmit1(cadastrarDebito)}
              type="submit"
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Daqui**********************BAIXAR DEBITO********************************/}
      <Modal
        size="lg"
        show={DebitosDatabaixar}
        onHide={handleDebitosDatabaixar}
      >
        <form onSubmit={handleDebitosDatabaixar}>
          <Modal.Header closeButton>
            <Modal.Title>Baixar de Débito </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Dados do Débito
            <hr />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DT_VENC"
              value={values.DT_VENC}
              id="outlined-multiline-flexible"
              label="Data de Vencimento"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
              style={{ width: 232 }}
            />
            <TextField autoComplete="off"
              required
              onChange={handleChangeModalCurrency}
              onKeyUp={maskCurrency}
              name="VALOR"
              value={values.VALOR}
              id="outlined-multiline-flexible"
              label="Valor"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              disabled={true}
              style={{ width: 232 }}
              error={hasError("valor")}
            />
            <br />
            <hr />
            Baixa Débito
            <hr />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DT_PAGTO"
              value={values.DT_PAGTO}
              id="outlined-multiline-flexible"
              label="Data de Pagamento"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
            />
            <TextField autoComplete="off"
              required
              onChange={handleChangeModalCurrency}
              onKeyUp={maskCurrency}
              name="VL_PAGO"
              value={values.VL_PAGO}
              id="outlined-multiline-flexible"
              label="Valor Pago"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              error={hasError("vl_pago")}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="COBRADOR"
              value={values.IDCOBRADOR}
              id="outlined-multiline-flexible"
              label="Cobrador"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDebitosDatabaixar}>
              Fechar
            </Button>
            <Button
              className={classes.button}
              onClick={handleSubmit1(cbaixarDebito)}
              type="submit"
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {/*********************ATÈ AQUI************* */}
      {/***********************Selecionar Débitos para cobrança*****************************/}
      <Modal size="lg" show={showDebitosC} onHide={handleCloseDebitosC}>
        <form onSubmit={handleCloseDebitosC}>
          <Modal.Header closeButton>
            <Modal.Title>Geração de Cobrança</Modal.Title>

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Selecionar Todos
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Seleção</TableCell>
                    <TableCell align="center">Categoria</TableCell>
                    <TableCell align="center">Tipo</TableCell>
                    <TableCell align="center">Vencimento</TableCell>
                    <TableCell align="center">Valor</TableCell>
                    <TableCell align="center">Data Pagamento</TableCell>
                    <TableCell align="center">Valor Pago</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredDebitos.map((rowsDeb, index) => (
                    <TableRow
                      key={rowsDeb.IDDEBITO}
                      className={`${
                        rowsDeb.DT_PAGTO != null
                          ? classes.tableA
                          : `${
                              rowsDeb.DT_VENC < dataAtualFormatada()
                                ? classes.tableC
                                : classes.tableB
                            }`
                      }`}
                    >
                      <TableCell align="center">
                        <Checkbox
                          onChange={handleChangeRadio}
                          value={rowsDeb.IDDEBITO}
                          color="default"
                          name="Checkdebito"
                          id="Checkdebito"
                        />
                      </TableCell>
                      <TableCell align="center">{rowsDeb.CATEGORIA}</TableCell>
                      <TableCell align="center">{rowsDeb.DESCRICAO}</TableCell>
                      <TableCell align="center">
                        {rowsDeb.DT_VENC != null
                          ? moment(rowsDeb.DT_VENC).format("DD/MM/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {formatCurrency(rowsDeb.VALOR)}
                      </TableCell>
                      <TableCell align="center">
                        {rowsDeb.DT_PAGTO != null
                          ? moment(rowsDeb.DT_PAGTO).format("DD/MM/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {formatCurrency(rowsDeb.VL_PAGO)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDebitosC}>
              Fechar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Daqui**********************INSERIR VARIOS DEBITOS*****************************/}
      <Modal
        size="lg"
        show={DebitosDataInsert}
        onHide={handleDebitosDataInsert}
      >
        <form onSubmit={handleDebitosDataInsert}>
          <Modal.Header closeButton>
            <Modal.Title>Cadastro de Débito</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Tipo Débito
            <hr />
            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Categoria
              </InputLabel>
              <Select
                value={values.CATEGORIA ? values.CATEGORIA : " "}
                name="CATEGORIA"
                label="Categoria"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                // margin="normal"
                // variant="outlined"
                input={<OutlinedInput name="ageDeb" id="outlined-age-simple" />}
              >
                <MenuItem value="ADESAO">Adesão</MenuItem>
                <MenuItem value="MANUTENCAO">Manutenção</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Tipo Débito
              </InputLabel>
              <Select
                value={values.TIPO ? values.TIPO : " "}
                name="TIPO"
                label="Tipo"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="agetpd"
                    id="outlined-age-simple"
                    label="Tipo de débito"
                  />
                }
              >
                {debitotp.map((row, index) => (
                  <MenuItem value={row.CODTIPO} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Cons. Taxa
              </InputLabel>
              <Select
                //value={}
                name="CONSIDERA"
                label="Cons. Taxa"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                onBlur={(ev) =>
                  onBlurTaxa(values.IDPLANO, values.CONSIDERA, values.CATEGORIA)
                }
                input={<OutlinedInput name="ageDeb" id="outlined-age-simple" />}
              >
                <MenuItem value="SIM">Sim</MenuItem>
                <MenuItem value="NÃO">Não</MenuItem>
              </Select>
            </FormControl>
            <br />
            <hr />
            Taxas
            <hr />
            <TextField autoComplete="off"
              required
              onChange={handleChangeModalCurrency}
              onKeyUp={maskCurrency}
              name="VALOR_TX"
              //value={values.VALOR}
              id="VALOR_TX"
              label="Valor Taxas"
              type="text"
              rowsMax="4"
              disabled={true}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: 232 }}
              error={hasError("valor")}
            />
            <br />
            <hr />
            Adicional
            <hr />
            <TextField autoComplete="off"
              //required
              onChange={handleChangeModalCurrency}
              onKeyUp={maskCurrency}
              name="VALOR_AD"
              //value={values.VALOR}
              id="VALOR_AD"
              defaultValue="0,00"
              label="Valor Adicional"
              type="text"
              rowsMax="4"
              className={classes.textField}
              onBlur={(ev) =>
                onBlurSTaxa(
                  document.getElementById("VALOR_TX").value,
                  document.getElementById("VALOR_AD").value
                )
              }
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              error={hasError("valorad")}
            />
            <br />
            <hr />
            Parcela
            <hr />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              onFocus={handleChangeModal}
              name="QUANTIDADE"
              //value={}
              id="QTD"
              label="Quantidade"
              type="number"
              rowsMax="4"
              onBlur={(ev) => onBlurQtd(document.getElementById("QTD").value)}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DT_VENC"
              value={values.DT_VENC}
              id="outlined-multiline-flexible"
              label="Data de Vencimento"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              //onBlur={(ev) => onBlurVenc(values.DT_VENC,document.getElementById('QTD').value, values.CONSIDERA, values.CATEGORIA)}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
            />
            <TextField autoComplete="off"
              required
              onChange={handleChangeModalCurrency}
              onKeyUp={maskCurrency}
              name="VALOR"
              value={values.VALOR}
              id="outlined-multiline-flexible"
              label="Valor Total"
              type="text"
              rowsMax="4"
              //disabled={true}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: 232 }}
              error={hasError("valor")}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDebitosDataInsert}>
              Fechar
            </Button>
            <Button
              className={classes.button}
              onClick={() =>
                onBlurVenc(
                  values.DT_VENC,
                  document.getElementById("QTD").value,
                  values.CONSIDERA,
                  values.CATEGORIA
                )
              }
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {/******************ATÉ AQUI**************** */}

      {/* Daqui********************** TAXA ********************************/}
      <Modal size="lg" show={showTaxas} onHide={handleCloseTaxas}>
        <form onSubmit={handleCloseTaxas}>
          <Modal.Header closeButton>
            <Modal.Title>Taxas do contrato</Modal.Title>
            <Button
              variant="contained"
              color="primary"
              onClick={handleTaxas}
              className={classes.button}
            >
              Adicionar Taxas
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Paper className={classes.root}>
              <TableContainer className={classes.tcontainerF}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Categoria</TableCell>
                      <TableCell align="center">Descrição Taxa</TableCell>
                      <TableCell align="center">Descrição</TableCell>
                      <TableCell align="center">Valor</TableCell>
                      <TableCell align="center">Inicio</TableCell>
                      <TableCell align="center">Final</TableCell>
                      <TableCell align="center">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTaxas
                      .slice(
                        pageTx * rowsPerPageTx,
                        pageTx * rowsPerPageTx + rowsPerPageTx
                      )
                      .map((rowsTaxa, index) => {
                        return (
                          <TableRow
                            key={rowsTaxa.IDTAXA}
                            className={`${
                              rowsTaxa.DATAFIN <= dataAtualFormatada()
                                ? classes.tableA
                                : classes.tableB
                            }`}
                          >
                            <TableCell align="center">
                              {rowsTaxa.CATEGORIA}
                            </TableCell>
                            <TableCell align="center">
                              {rowsTaxa.DESCTAXA}
                            </TableCell>
                            <TableCell align="center">
                              {rowsTaxa.DESCRICAO}
                            </TableCell>
                            <TableCell align="center">
                              {formatCurrency(rowsTaxa.VALOR)}
                            </TableCell>
                            <TableCell align="center">
                              {rowsTaxa.DATAINI != null
                                ? moment(rowsTaxa.DATAINI).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              {rowsTaxa.DATAFIN != null
                                ? moment(rowsTaxa.DATAFIN).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              <Fab
                                title="Débitos"
                                className={classes.fab}
                                color="secondary"
                                onClick={() =>
                                  showOrHideActionsT(
                                    pageDeb === 0
                                      ? index
                                      : index + pageDeb * rowsPerPageDeb,
                                    rowsTaxa.IS_SHOW_ACTION_T
                                  )
                                }
                                aria-label="Debitos"
                              >
                                <Icon>zoom_out_map</Icon>
                              </Fab>
                              {rowsTaxa.IS_SHOW_ACTION_T ? (
                                <div>
                                  <Fab
                                    title="Editar"
                                    className={classes.fab}
                                    color="secondary"
                                    aria-label="Edit"
                                    onClick={() =>
                                      editarTaxa(
                                        pageTx === 0
                                          ? index
                                          : index + pageTx * rowsPerPageTx
                                      )
                                    }
                                  >
                                    <Icon>edit_icon</Icon>
                                  </Fab>
                                  <Fab
                                    title="Apagar"
                                    className={classes.fab}
                                    aria-label="Delete"
                                    onClick={() =>
                                      modalDeleteTaxaShow(
                                        pageTx === 0
                                          ? index
                                          : index + pageTx * rowsPerPageTx
                                      )
                                    }
                                  >
                                    <DeleteIcon />
                                  </Fab>
                                </div>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredTaxas.length}
                rowsPerPage={rowsPerPageTx}
                page={pageTx}
                onChangePage={handleChangePageTx}
                //onPageChange={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPageTx}
                //onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Taxas por Página:"}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </Paper>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseTaxas}>
              Fechar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showDeleteTaxa} onHide={modalDeleteTaxaClose}>
        <form onSubmit={modalDeleteTaxaClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Remover taxa</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>Tem certeza que deseja remover a taxa?</h3>
            <br />
            Esta ação <strong>não</strong> pode ser desfeita.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={modalDeleteTaxaClose}>
              Não
            </Button>
            <Button
              variant="contained"
              onClick={removerTaxa}
              type="button"
              color="primary"
              className={classes.button}
            >
              Sim
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal size="md" show={TaxasData} onHide={handleTaxasData}>
        <form onSubmit={handleTaxasData}>
          <Modal.Header closeButton>
            <Modal.Title>Cadastro de Taxas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Classificação
            <hr />
            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Tipo Taxa
              </InputLabel>
              <Select
                value={values.CODTPTAXA ? values.CODTPTAXA : " "}
                name="CODTPTAXA"
                label="Tipo"
                onChange={handleChangeModal}
                style={{ width: 200 }}
                className={classes.textField}
                // margin="normal"
                // variant="outlined"
                input={
                  <OutlinedInput
                    name="tpt"
                    id="outlined-age-simple"
                    label="Tipo Taxa"
                  />
                }
              >
                {taxatp.map((row, index) => (
                  <MenuItem value={row.CODTPTAXA} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <hr />
            Taxa-fixa
            <hr />
            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Categoria
              </InputLabel>
              <Select
                value={values.CATEGORIA ? values.CATEGORIA : " "}
                name="CATEGORIA"
                label="Categoria"
                onChange={handleChangeModal}
                style={{ width: 200 }}
                className={classes.textField}
                input={<OutlinedInput name="ageDeb" id="outlined-age-simple" />}
              >
                <MenuItem value="ADESAO">Adesão</MenuItem>
                <MenuItem value="MANUTENCAO">Manutenção</MenuItem>
              </Select>
            </FormControl>
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DESCRICAO"
              value={values.DESCRICAO}
              id="outlined-multiline-flexible"
              label="Descrição"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 200 }}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModalCurrency}
              onKeyUp={maskCurrency}
              name="VALOR"
              value={values.VALOR}
              id="outlined-multiline-flexible"
              label="Valor"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 200 }}
              error={hasError("valor")}
            />
            <br />
            <hr />
            Vigência
            <hr />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DATAINI"
              value={values.DATAINI}
              id="outlined-multiline-flexible"
              label="Data Inicio"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 200 }}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DATAFIN"
              value={values.DATAFIN}
              id="outlined-multiline-flexible"
              label="Data Fim"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 200 }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleTaxasData}>
              Fechar
            </Button>
            <Button
              className={classes.button}
              onClick={handleSubmit1(cadastrarTaxas)}
              type="submit"
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {/* Daqui********************** INFORMAÇÃO ********************************/}
      <Modal size="lg" show={showInfos} onHide={handleCloseInfos}>
        <form onSubmit={handleCloseInfos}>
          <Modal.Header closeButton>
            <Modal.Title>Informações do contrato</Modal.Title>

            <Button
              variant="contained"
              color="primary"
              onClick={handleInfos}
              className={classes.button}
            >
              Adicionar Informações
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handlecadastrargalaxpay(
                  cadGalax.IDPLANO,
                  cadGalax.COD_C_RECORRENCIA,
                  cadGalax.CHAVE_C_RECORRENCIA,
                  cadGalax.END_P_RECORRENCIA,
                  cadGalax.COD_P_RECORRENCIA,
                  cadGalax.CHAVE_P_RECORRENCIA,
                  cadGalax.SITE_C_RECORRENCIA,
                  cadGalax.IDPLANO,
                  cadGalax.NOME,
                  cadGalax.CNPJ_CPF,
                  cadGalax.EMAIL,
                  cadGalax.CELULAR,
                  cadGalax.FONE,
                  cadGalax.CEP,
                  cadGalax.ENDERECO,
                  cadGalax.NUMERO,
                  cadGalax.COMPLEMENT,
                  cadGalax.CIDADE,
                  cadGalax.BAIRRO,
                  cadGalax.ESTADO,
                  cadGalax.PAIS,
                  cadGalax.OPERADOR_RECORRENCIA,
                  cadGalax.USAR_PARCEIRO
                )
              }
              className={classes.button}
            >
              Adicionar Recorrência
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Paper className={classes.root}>
              <TableContainer className={classes.tcontainerF}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Tipo</TableCell>
                      <TableCell align="center">Descrição</TableCell>
                      <TableCell align="center">Valor</TableCell>
                      <TableCell align="center">Inicio</TableCell>
                      <TableCell align="center">Final</TableCell>
                      <TableCell align="center">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredInfos
                      .slice(
                        pageInf * rowsPerPageInf,
                        pageInf * rowsPerPageInf + rowsPerPageInf
                      )
                      .map((rowsInfo, index) => {
                        return (
                          <TableRow
                            key={rowsInfo.IDINFO}
                            className={`${
                              rowsInfo.DT_FIM <= dataAtualFormatada()
                                ? classes.tableA
                                : classes.tableB
                            }`}
                          >
                            <TableCell align="center">
                              {rowsInfo.TIPO}
                            </TableCell>
                            <TableCell align="center">
                              {rowsInfo.DESCRICAO}
                            </TableCell>
                            <TableCell align="center">{`${
                              rowsInfo.TIPO == "GALAXPAY"
                                ? Math.round(rowsInfo.VALOR)
                                : formatCurrency(rowsInfo.VALOR)
                            }`}</TableCell>
                            {/*<TableCell align="center">{formatCurrency(rowsInfo.VALOR)}</TableCell>*/}
                            <TableCell align="center">
                              {rowsInfo.DT_INICIO != null
                                ? moment(rowsInfo.DT_INICIO).format(
                                    "DD/MM/YYYY"
                                  )
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              {rowsInfo.DT_FIM != null
                                ? moment(rowsInfo.DT_FIM).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              <Fab
                                title="Débitos"
                                className={classes.fab}
                                color="secondary"
                                onClick={() =>
                                  showOrHideActionsI(
                                    pageInf === 0
                                      ? index
                                      : index + pageInf * rowsPerPageInf,
                                    rowsInfo.IS_SHOW_ACTION_I
                                  )
                                }
                                aria-label="Debitos"
                              >
                                <Icon>zoom_out_map</Icon>
                              </Fab>
                              {rowsInfo.IS_SHOW_ACTION_I ? (
                                <div>
                                  <Fab
                                    title="Editar"
                                    className={classes.fab}
                                    color="secondary"
                                    aria-label="Edit"
                                    onClick={() =>
                                      editarInfo(
                                        pageInf === 0
                                          ? index
                                          : index + pageInf * rowsPerPageInf
                                      )
                                    }
                                  >
                                    <Icon>edit_icon</Icon>
                                  </Fab>
                                  <Fab
                                    title="Apagar"
                                    className={classes.fab}
                                    aria-label="Delete"
                                    onClick={() =>
                                      modalDeleteInfoShow(
                                        pageInf === 0
                                          ? index
                                          : index + pageInf * rowsPerPageInf
                                      )
                                    }
                                    disabled={
                                      rowsInfo.TIPO === "GALAXPAY"
                                        ? true
                                        : false
                                    }
                                  >
                                    <DeleteIcon />
                                  </Fab>
                                  <br />
                                  <Fab
                                    title="Remover Recorrência"
                                    aria-label="Remover Recorrência"
                                    onClick={() =>
                                      removerrecorre(
                                        rowsInfo.IDINFO,
                                        rowsInfo.TIPO,
                                        Math.round(rowsInfo.VALOR),
                                        cadGalax.COD_C_RECORRENCIA,
                                        cadGalax.CHAVE_C_RECORRENCIA,
                                        cadGalax.END_P_RECORRENCIA,
                                        cadGalax.COD_P_RECORRENCIA,
                                        cadGalax.CHAVE_P_RECORRENCIA,
                                        cadGalax.SITE_C_RECORRENCIA,
                                        cadGalax.USAR_PARCEIRO
                                      )
                                    }
                                    className={`${
                                      rowsInfo.TIPO == "GALAXPAY"
                                        ? classes.fabd
                                        : classes.fabd
                                    }`}
                                    disabled={
                                      rowsInfo.TIPO === "GALAXPAY"
                                        ? false
                                        : true
                                    }
                                  >
                                    <Icon>removecircleoutline</Icon>
                                  </Fab>
                                </div>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredInfos.length}
                rowsPerPage={rowsPerPageInf}
                page={pageInf}
                onChangePage={handleChangePageInf}
                //onPageChange={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPageInf}
                //onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Informações por Página:"}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </Paper>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseInfos}>
              Fechar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={showDeleteInfo} onHide={modalDeleteInfoClose}>
        <form onSubmit={modalDeleteInfoClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Remover informação</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>Tem certeza que deseja remover a informação?</h3>
            <br />
            Esta ação <strong>não</strong> pode ser desfeita.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={modalDeleteInfoClose}>
              Não
            </Button>
            <Button
              variant="contained"
              onClick={removerInfo}
              type="button"
              color="primary"
              className={classes.button}
            >
              Sim
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal size="md" show={InfosData} onHide={handleInfosData}>
        <form onSubmit={handleInfosData}>
          <Modal.Header closeButton>
            <Modal.Title>Cadastro de Informações</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Classificação
            <hr />
            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Tipo Informação
              </InputLabel>
              <Select
                value={values.TIPO ? values.TIPO : " "}
                name="TIPO"
                label="Tipo"
                onChange={handleChangeModal}
                style={{ width: 200 }}
                className={classes.textField}
                // margin="normal"
                // variant="outlined"
                input={
                  <OutlinedInput
                    name="agetpinf"
                    id="outlined-age-simple"
                    label="Tipo Informação"
                  />
                }
              >
                {infotp.map((row, index) => (
                  <MenuItem value={row.CODTIPO} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <hr />
            Informação
            <hr />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DESCRICAO"
              value={values.DESCRICAO}
              id="outlined-multiline-flexible"
              label="Descrição"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 200 }}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModalCurrency}
              onKeyUp={maskCurrency}
              name="VALOR"
              value={values.VALOR}
              id="outlined-multiline-flexible"
              label="Valor"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 200 }}
              error={hasError("valor")}
            />
            <br />
            <hr />
            Vigência
            <hr />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DT_INICIO"
              value={values.DT_INICIO}
              id="outlined-multiline-flexible"
              label="Data Inicio"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 200 }}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DT_FIM"
              value={values.DT_FIM}
              id="outlined-multiline-flexible"
              label="Data Fim"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 200 }}
            />
            <br />
            <hr />
            Detalhes
            <hr />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="MEMO"
              value={values.MEMO}
              id="outlined-multiline-flexible"
              label="Detalhes"
              type="text"
              multiline
              rows="6"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 420 }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleInfosData}>
              Fechar
            </Button>
            <Button
              className={classes.button}
              onClick={handleSubmit1(cadastrarInfos)}
              type="submit"
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/******************* Até Aqui ***********************/}
      {/******************* DEPENDENTES ***********************/}
      <Modal size="lg" show={showDependentes} onHide={handleCloseDependentes}>
        <form onSubmit={handleCloseDependentes}>
          <Modal.Header closeButton>
            <Modal.Title>Dependentes do contrato</Modal.Title>
            <Button
              onClick={handleDependencias}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Adicionar Dependente
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Paper className={classes.root}>
              <TableContainer className={classes.tcontainerF}>
                <Table stickyHeader className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">ID</TableCell>
                      <TableCell align="center">Nome</TableCell>
                      <TableCell align="center">Parentesco</TableCell>
                      <TableCell align="center">Nascimento</TableCell>
                      <TableCell align="center">Falecimento</TableCell>
                      <TableCell align="center">Carencia</TableCell>
                      <TableCell align="center">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredDependentes
                      .slice(
                        pageDep * rowsPerPageDep,
                        pageDep * rowsPerPageDep + rowsPerPageDep
                      )
                      .map((rowsDep, index) => {
                        return (
                          <TableRow
                            key={rowsDep.IDDEP}
                            className={`${
                              rowsDep.DT_FALEC != null
                                ? classes.tableA
                                : classes.tableB
                            }`}
                          >
                            <TableCell align="center">
                              {rowsDep.IDDEP}
                            </TableCell>
                            <TableCell align="center">{rowsDep.NOME}</TableCell>
                            <TableCell align="center">
                              {rowsDep.CODGRAU}
                            </TableCell>
                            <TableCell align="center">
                              {rowsDep.DT_NASC != null
                                ? moment(rowsDep.DT_NASC).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              {rowsDep.DT_FALEC != null
                                ? moment(rowsDep.DT_FALEC).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              {rowsDep.CAREN_AT != null
                                ? moment(rowsDep.CAREN_AT).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              <Fab
                                title="Débitos"
                                className={classes.fab}
                                color="secondary"
                                onClick={() =>
                                  showOrHideActionsA(
                                    pageDep === 0
                                      ? index
                                      : index + pageDep * rowsPerPageDep,
                                    rowsDep.IS_SHOW_ACTION_A
                                  )
                                }
                                aria-label="Debitos"
                              >
                                <Icon>zoom_out_map</Icon>
                              </Fab>
                              {rowsDep.IS_SHOW_ACTION_A ? (
                                <div>
                                  <Fab
                                    title="Editar"
                                    className={classes.fab}
                                    color="secondary"
                                    aria-label="Edit"
                                    onClick={() =>
                                      editarDependente(
                                        pageDep === 0
                                          ? index
                                          : index + pageDep * rowsPerPageDep
                                      )
                                    }
                                  >
                                    <Icon>edit_icon</Icon>
                                  </Fab>
                                  <Fab
                                    title="Apagar"
                                    className={classes.fab}
                                    aria-label="Delete"
                                    onClick={() =>
                                      modalDeleteDependenciaShow(
                                        pageDep === 0
                                          ? index
                                          : index + pageDep * rowsPerPageDep
                                      )
                                    }
                                  >
                                    <DeleteIcon />
                                  </Fab>
                                </div>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredDependentes.length}
                rowsPerPage={rowsPerPageDep}
                page={pageDep}
                onChangePage={handleChangePageDep}
                //onPageChange={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPageDep}
                //onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Dependentes por Página:"}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </Paper>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDependentes}>
              Fechar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={showDeleteDependencia} onHide={modalDeleteDependenciaClose}>
        <form onSubmit={modalDeleteDependenciaClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Remover dependente</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>Tem certeza que deseja remover o dependente?</h3>
            <br />
            Esta ação <strong>não</strong> pode ser desfeita.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={modalDeleteDependenciaClose}>
              Não
            </Button>
            <Button
              variant="contained"
              onClick={removerDependente}
              type="button"
              color="primary"
              className={classes.button}
            >
              Sim
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={DependentesData} onHide={handleDependentesData}>
        <form onSubmit={handleDependentesData} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title>Cadastro de Dependente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>Identificação</b>
            <hr />
            <TextField autoComplete="off"
              required
              onChange={handleChangeModal}
              name="NOME"
              value={values.NOME}
              id="outlined-multiline-flexible"
              label="Nome"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              error={hasError("nome")}
              style={{ width: 200 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DT_NASC"
              value={values.DT_NASC}
              id="outlined-multiline-flexible"
              label="Nascimento"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 200 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DT_INCLUSAO"
              value={values.DT_INCLUSAO}
              id="outlined-multiline-flexible"
              label="Inclusão"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 200 }}
            />
            <br />
            <b>Classificação</b>
            <hr />
            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 200, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Sexo
              </InputLabel>
              <Select
                value={values.SEXO ? values.SEXO : " "}
                name="SEXO"
                label="Sexo"
                onChange={handleChangeModal}
                style={{ width: 200 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="ages"
                    id="outlined-age-simple"
                    label="Sexo"
                  />
                }
              >
                {sexo.map((row, index) => (
                  <MenuItem value={row.CODSEXO} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 200, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Religião
              </InputLabel>
              <Select
                value={values.RELIGIAO ? values.RELIGIAO : " "}
                name="RELIGIAO"
                label="Religiao"
                onChange={handleChangeModal}
                style={{ width: 200 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="ager"
                    id="outlined-age-simple"
                    label="Religiao"
                  />
                }
              >
                {religiao.map((row, index) => (
                  <MenuItem value={row.CODRELIG} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 200, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Estado Civil
              </InputLabel>
              <Select
                value={values.ESTCIVIL ? values.ESTCIVIL : " "}
                name="ESTCIVIL"
                label="Estcivil"
                onChange={handleChangeModal}
                style={{ width: 200 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="ageec"
                    id="outlined-age-simple"
                    label="Estcivil"
                  />
                }
              >
                {estcivil.map((row, index) => (
                  <MenuItem value={row.CODESTCIV} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 200, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Grau
              </InputLabel>
              <Select
                value={values.CODGRAU ? values.CODGRAU : ""}
                name="CODGRAU"
                label="Grau"
                onChange={handleChangeModal}
                style={{ width: 200 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="agegrau"
                    id="outlined-age-simple"
                    label="Grau"
                  />
                }
              >
                {graus.map((row, index) => (
                  <MenuItem value={row.CODGRAU} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 200, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Tipo
              </InputLabel>
              <Select
                value={values.TIPO ? values.TIPO : ""}
                name="TIPO"
                label="Tipo"
                onChange={handleChangeModal}
                style={{ width: 200 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="agetipo"
                    id="outlined-age-simple"
                    label="Tipo"
                  />
                }
              >
                {deptp.map((row, index) => (
                  <MenuItem value={row.CODTIPO} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="PROFISSAO"
              value={values.PROFISSAO}
              id="outlined-multiline-flexible"
              label="Profissão"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 200 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="NACIONALID"
              value={values.NACIONALID}
              id="outlined-multiline-flexible"
              label="Nacionalidade"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 200 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="NATURALID"
              value={values.NATURALID}
              id="outlined-multiline-flexible"
              label="Naturalidade"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 200 }}
            />
            <b>Datas</b>
            <hr />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DT_FALEC"
              value={values.DT_FALEC}
              id="outlined-multiline-flexible"
              label="Falecimento"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 200 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CAREN_AT"
              value={values.CAREN_AT}
              id="outlined-multiline-flexible"
              label="Carência"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 200 }}
            />
            <b>Documentos</b>
            <hr />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CNPJ_CPF"
              value={values.CNPJ_CPF}
              id="outlined-multiline-flexible"
              label="CNPJ/CPF"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              error={hasError("cnpj_cpf")}
              style={{ width: 200 }}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="IE_RG"
              value={values.IE_RG}
              id="outlined-multiline-flexible"
              label="IE/RG"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 200 }}
            />
            <b>Contato</b>
            <hr />

            <InputMask
              onChange={handleChangeModal}
              value={values.FONE}
              mask="(99)99999-9999"
              maskChar=" "
            >
              {() => (
                <TextField autoComplete="off"
                  onChange={handleChangeModal}
                  name="FONE"
                  value={values.FONE}
                  id="outlined-multiline-flexible"
                  label="Telefone"
                  type="text"
                  rowsMax="4"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  style={{ width: 200 }}
                />
              )}
            </InputMask>

            <InputMask
              onChange={handleChangeModal}
              value={values.FAX}
              mask="(99)99999-9999"
              maskChar=" "
            >
              {() => (
                <TextField autoComplete="off"
                  onChange={handleChangeModal}
                  name="FAX"
                  value={values.FAX}
                  id="outlined-multiline-flexible"
                  label="Celular"
                  type="text"
                  rowsMax="4"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  style={{ width: 200 }}
                />
              )}
            </InputMask>

            <InputMask
              onChange={handleChangeModal}
              value={values.CELULAR}
              mask="(99)99999-9999"
              maskChar=" "
            >
              {() => (
                <TextField autoComplete="off"
                  onChange={handleChangeModal}
                  name="CELULAR"
                  value={values.CELULAR}
                  id="outlined-multiline-flexible"
                  label="Celular"
                  type="text"
                  rowsMax="4"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  style={{ width: 200 }}
                />
              )}
            </InputMask>

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CONTATO"
              value={values.CONTATO}
              id="outlined-multiline-flexible"
              label="Contato"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 200 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="EMAIL"
              value={values.EMAIL}
              id="outlined-multiline-flexible"
              label="e-mail"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 200 }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDependentesData}>
              Fechar
            </Button>
            <Button
              className={classes.button}
              onClick={handleSubmit1(cadastrarDependencia)}
              type="submit"
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
