import { Fab, Icon } from "@material-ui/core";
import React, { useRef } from "react";

import { useReactToPrint } from "react-to-print";
import { getAtualDate, getAtualHour } from "../../../../../utils/atualDateHour";
import { formatDate, formatMoney } from "../../../../../utils/formats";
import styles from "./styles.module.css";

export function RelatorioAnalitico(props) {
  const { data = [], initialDate, finalDate } = props;

  console.log(data);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: { padding: 20 },
  });

  function totalCels(itens = [], field) {
    return itens.reduce((acc, item) => acc + Number(item[field]), 0).toFixed(2);
  }

  if (!data.length) return null;

  return (
    <div>
      <div
        style={{
          textAlign: "right",
          marginBottom: -60,
          marginTop: 50,
        }}
      >
        <Fab
          color="info"
          onClick={handlePrint}
          style={{ width: 35, height: 5 }}
        >
          <Icon>print</Icon>
        </Fab>
      </div>

      <div ref={componentRef}>
        <div className={styles.container_report}>
          <div className={styles.header_report}>
            <div className={styles.line} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
                width: "100%",
              }}
            >
              <img
                alt="logo da empresa"
                src={
                  data[0]?.LogoEmpresa ??
                  "https://memorium.cocreare.etc.br/media/logos/logo-letter-2.png"
                }
                style={{ width: 60, height: 60, objectFit: "contain" }}
              />
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 className={styles.title_report}>Fluxo de Recebimento</h1>
                <h2 className={styles.subtitle_report}>(Analítico)</h2>
              </div>
              <div style={{ width: 60 }} />
            </div>
            <div className={styles.line} />

            <div className={styles.time_container}>
              <p>{getAtualDate()}</p>
              <p>{data[0]?.Empresa ?? ""}</p>
              <p>{getAtualHour()}</p>
            </div>
            <div className={styles.line} />

            <div className={styles.info_container}>
              <div>
                <div className={styles.item_info_title}>
                  <p>Pag. Inicial</p>
                  <span>{formatDate(initialDate)}</span>
                </div>
                <div className={styles.item_info_title}>
                  <p>Pag. Inicial</p>
                  <span>{formatDate(finalDate)}</span>
                </div>
                <div className={styles.item_info_title}>
                  <p>Arq. Morto</p>
                  <span>N - Não</span>
                </div>
              </div>
              <div>
                <div className={styles.item_info_title}>
                  <p>Cancelado</p>
                  <span>N - Não</span>
                </div>
                <div className={styles.item_info_title}>
                  <p>Empresarial</p>
                  <span>N - Não</span>
                </div>
              </div>
            </div>
          </div>

          <table className={styles.table_reports}>
            <thead className={styles.t_head}>
              <tr className={styles.t_row}>
                <th className={`${styles.t_head} ${styles.center}`}>
                  Data Recebimento
                </th>
                <th className={`${styles.t_head} ${styles.center}`}>
                  Inscrição
                </th>
                <th className={`${styles.t_head} ${styles.center}`}>Nome</th>
                <th className={`${styles.t_head} ${styles.center}`}>
                  Vencimento
                </th>
                <th className={`${styles.t_head} ${styles.center}`}>
                  Valor Original
                </th>
                <th className={`${styles.t_head} ${styles.center}`}>
                  Valor Pago
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr className={styles.t_row} key={index}>
                  <td className={`${styles.t_data} ${styles.center}`}>
                    {formatDate(item.DT_PAGTO)}
                  </td>
                  <td className={`${styles.t_data} ${styles.center}`}>
                    {item.idplano}
                  </td>
                  <td className={`${styles.t_data} ${styles.left}`}>
                    {item.Nome}
                  </td>
                  <td className={`${styles.t_data} ${styles.center}`}>
                    {formatDate(item.DT_VENC)}
                  </td>
                  <td className={`${styles.t_data} ${styles.right}`}>
                    {formatMoney(item.VlOrig_Insc)}
                  </td>
                  <td className={`${styles.t_data} ${styles.right}`}>
                    {formatMoney(item.Valor_Total)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className={styles.t_row}>
                <td className={`${styles.t_data} ${styles.center}`} colspan="4">
                  TOTAL
                </td>
                <td className={`${styles.t_data} ${styles.right}`}>
                  {formatMoney(totalCels(data, "VlOrig_Insc"))}
                </td>
                <td className={`${styles.t_data} ${styles.right}`}>
                  {formatMoney(totalCels(data, "Valor_Total"))}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}
