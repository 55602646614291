import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import { Card } from "../../_metronic/_partials/controls";
import { getToken } from "../services/auth";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "flex-start",
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  button: {
    margin: theme.spacing(1),
  },
  moduleClass: {
    padding: "1px",
  },
  moduleCard: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    maxWidth: "250px",
  },

  moduleCard_1: {
    display: "flex",
    alignItems: "center",
    padding: "1px",
    maxWidth: "250px",
  },

  linha: {
    display: "flex",
    flexFlow: "row wrap",
  },

  coluna_50: {
    width: "50%",
  },
}));

export function ModManutPage() {
  const classes = useStyles();

  //TESTE
  //useEffect(() => {
  const token = getToken();
  console.log("TOKEN ", token);

  return (
    <div>
      <Link to="/grupos">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/grupos.svg")}/>*/}
            {/*<SVG height="15" src={toAbsoluteUrl("/media/svg/icons/Home/servico.svg")}/>*/}
            <h5>Grupos</h5>
          </span>
        </Card>
      </Link>

      <Link to="/familias">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/* <SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/familia.svg")}/>*/}
            {/*<SVG height="15" src={toAbsoluteUrl("/media/svg/icons/Home/servico.svg")}/>             */}
            <h5>Famílias</h5>
          </span>
        </Card>
      </Link>

      <Link to="/categorias">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            {/* <SVG height="15" src={toAbsoluteUrl("/media/svg/icons/Home/servico.svg")}/>*/}
            <h5>Categoria de contrato</h5>
          </span>
        </Card>
      </Link>

      <Link to="/vendedor">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            {/* <SVG height="15" src={toAbsoluteUrl("/media/svg/icons/Home/servico.svg")}/>*/}
            <h5>Vendedores</h5>
          </span>
        </Card>
      </Link>

      <Link to="/cobrador">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Cobrador</h5>
          </span>
        </Card>
      </Link>

      <Link to="/graus">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Grau Parentesco</h5>
          </span>
        </Card>
      </Link>

      <Link to="/tpvendas">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="15" src={toAbsoluteUrl("/media/svg/icons/Home/servico.svg")}/>*/}
            <h5>Tipos de Vendas</h5>
          </span>
        </Card>
      </Link>

      <Link to="/tpdep">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Tipo Dependente</h5>
          </span>
        </Card>
      </Link>

      <Link to="/tpdebito">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Tipo Débito</h5>
          </span>
        </Card>
      </Link>

      <Link to="/tpinfo">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Tipo de Informação</h5>
          </span>
        </Card>
      </Link>

      <Link to="/taxatp">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Tipo de Taxa</h5>
          </span>
        </Card>
      </Link>

      <Link to="/ctstatus">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Status do Contrato</h5>
          </span>
        </Card>
      </Link>

      <Link to="/cobstatus">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Status da Cobrança</h5>
          </span>
        </Card>
      </Link>

      <Link to="/categoriacob">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Categoria de Cobrança</h5>
          </span>
        </Card>
      </Link>

      <Link to="/unsexo">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Tab. Sexo</h5>
          </span>
        </Card>
      </Link>

      <Link to="/unreligiao">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Tab. Religião</h5>
          </span>
        </Card>
      </Link>

      <Link to="/unestcivil">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Tab. Estado Civil</h5>
          </span>
        </Card>
      </Link>

      <Link to="/unbanco">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Tab. Banco</h5>
          </span>
        </Card>
      </Link>

      <Link to="/unfinconta">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Tab. Contas</h5>
          </span>
        </Card>
      </Link>

      <Link to="/cobcobrconv">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Convênio Cobrança</h5>
          </span>
        </Card>
      </Link>

      <Link to="/ctvariaveis">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Variáveis do Sistema</h5>
          </span>
        </Card>
      </Link>

      <Link to="/empresas">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            <h5>Empresa do Sistema</h5>
          </span>
        </Card>
      </Link>
    </div>
  );
}
