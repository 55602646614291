/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import api from "../services/api";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import { Alert, Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import { toast, ToastContainer } from "react-toastify";
//import moment from 'moment';
//import { useReactToPrint } from 'react-to-print';
//import { Link } from "react-router-dom"
//import { PrintPage } from './Print';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  fab: {
    margin: theme.spacing(1),
    width: 35,
    height: 5,
  },
  modalContratos: {
    maxWidth: 900,
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStylesSelect = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export function CobrconvPage() {
  const classes = useStyles();
  const classesSelect = useStylesSelect();
  const [errors, setErrors] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [fincontas, setFincontas] = useState([]);
  const [show, setShow] = useState(false);
  const [editar, setEditar] = useState(false);
  const handleClose = () => setShow(false);

  const componentRef = useRef();

  
  const handleShow = () => {
    for (var key in values) {
      if(values[key] != null  && values[key].toUpperCase  != "MEMCANMOM"){
        values[key] = "";
      }   
    }
    setValues({});
    setErrors([]);
    api.get("/un_banco").then((res) => {
      console.log("un_banco  ", JSON.parse(JSON.stringify(res.data)));
      setBancos(res.data);
      setShow(true);
      setEditar(false);
    });
    api.get("/un_finconta").then((res) => {
      console.log("un_finconta  ", JSON.parse(JSON.stringify(res.data)));
      setFincontas(res.data);
      setShow(true);
      setEditar(false);
    });
  };

  function atualizaContratos() {
    api.get("/cobrconv").then((res) => {
      console.log("cobrconv ", JSON.parse(JSON.stringify(res.data)));
      res.data.map((data_plano, i) => {
        if (i === 0) {
          setSelectedValue(data_plano.CODCONV);
        }
        data_plano.IS_SHOW_ACTION = false;
      });
      setRows(res.data);
      setRowsBusca(res.data);
    });
  }

  const [showDelete, setShowDelete] = useState(false);

  const [linhaARemover, setLinhaARemover] = useState();

  const modalDeleteClose = () => setShowDelete(false);

  function modalDeleteShow() {
    console.log(selectedValue);
    setShowDelete(true);
    rows.map((row_aux, i) => {
      if (selectedValue === row_aux.CODCONV) {
        console.log("i", i);
        setLinhaARemover(i);
      }
    });
  }

  const [rows, setRows] = useState([]);
  const [rowsBusca, setRowsBusca] = useState([]);

  const [selectedValue, setSelectedValue] = useState("");
  function handleChangeRadio(event) {
    setSelectedValue(event.currentTarget.value);
  }
  useEffect(() => {
    atualizaContratos();
    api.get("/un_banco").then((res) => {
      setBancos(res.data);
    });
    api.get("/un_finconta").then((res) => {
      setFincontas(res.data);
    });
  }, []);

  const filtrarNome = (event) => {
    const name = event.target.value.toLowerCase();
    const auxBusca = rows.filter((row) =>
      row.DESCRICAO.toLowerCase().includes(name)
    );
    if (name) {
      setRowsBusca(auxBusca);
    } else {
      setRowsBusca(rows);
    }
  };

  const [values, setValues] = useState({});

  const handleChangeModal = (event) => {
    const auxValues = { ...values };
    console.log("VALOR ", event.target.value);
    auxValues[event.target.name] = event.target.value;
    setValues(auxValues);
  };

  const handleSubmit1 = (callback) => (event) => {
    event.preventDefault();
    callback();
  };

  //const [editarIndex, setEditarIndex] = useState(0)

  function validarContratos(dados) {
    var data = new Date();
    var errors = [];
    if (dados["sequencia"] === "" || dados["sequencia"] === undefined) {
      errors.push("sequencia");
    }
    if (dados["codconv"] === "" || dados["codconv"] === undefined) {
      errors.push("codconv");
    }
    if (dados["seqmax"] === "" || dados["seqmax"] === undefined) {
      errors.push("seqmax");
    }
    if (dados["banco"] === "" || dados["banco"] === undefined) {
      errors.push("banco");
    }
    if (dados["conta"] === "" || dados["conta"] === undefined) {
      errors.push("conta");
    }
    setErrors(errors);
    console.log("errors ", errors);
    if (errors.length > 0) {
      toast("Preencha os campos obrigatórios.", { type: "error" });
      return false;
    }
    return true;
  }

  const cadastrarContrato = () => {
    if (editar) {
      const editedContrato = values;
      var key,
        keys = Object.keys(editedContrato);
      var n = keys.length;
      var newobj = {};
      while (n--) {
        key = keys[n];
        newobj[key.toLowerCase()] = editedContrato[key];
      }
      console.log(newobj);
      if (validarContratos(newobj)) {
        api.put(`/cobrconv/${editedContrato.CODCONV}`, newobj).then((res) => {
          atualizaContratos();
          setShow(false);
        });
      }
    } else {
      //values["CODCONV"] = rows.values;//.length;
      const createdContrato = values;
      let key,
        keys = Object.keys(createdContrato);
      let n = keys.length;
      let newobj = {};
      while (n--) {
        key = keys[n];
        newobj[key.toLowerCase()] = createdContrato[key];
      }
      if (validarContratos(newobj)) {
        api.post("/cobrconv", newobj).then((res) => {
          atualizaContratos();
          setShow(false);
        });
      }
    }
  };
  function hasError(key) {
    return errors.indexOf(key) !== -1;
  }

  function editarContrato() {
    rows.map((row_aux, i) => {
      if (selectedValue === row_aux.CODCONV) {
        console.log("IIIII ", i);
        const row = rows[i];
        console.log("row ", row);
        const auxEditar = row;
        setShow(true);
        setEditar(true);
        setValues(auxEditar);
        setErrors([]);
      }
    });
  }

  function removerContrato() {
    const removeRow = rows[linhaARemover];
    console.log(removeRow);
    api.delete(`/cobrconv/${removeRow.CODCONV}`).then((res) => {
      atualizaContratos();
      setShow(false);
    });
    modalDeleteClose();
    atualizaContratos();
  }

  return (
    <>
      <Card>
        <CardHeader title="Tabela Convênio Bancário">
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <form className={classesSelect.root} autoComplete="off">
            <FormControl className={classesSelect.margin}>
              <FormHelperText>Filtrar por Desrição</FormHelperText>
              <BootstrapInput
                onChange={filtrarNome}
                id="age-customized-input"
              />
            </FormControl>
            <div style={{ marginTop: 18 }}>
              <Fab
                title="Adicionar"
                className={classes.fab}
                color="primary"
                onClick={handleShow}
                aria-label="Edit"
              >
                <Icon>add</Icon>
              </Fab>
              <Fab
                className={classes.fab}
                color="secondary"
                onClick={() => editarContrato()}
                aria-label="Edit"
                title="Editar"
              >
                <Icon>edit_icon</Icon>
              </Fab>
              <Fab
                title="Apagar"
                className={classes.fab}
                aria-label="Delete"
                onClick={() => modalDeleteShow()}
              >
                <DeleteIcon />
              </Fab>
            </div>
          </form>
          <Paper className={classes.root}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">Código</TableCell>
                  <TableCell align="center">Descrição</TableCell>
                  <TableCell align="center">Banco</TableCell>
                  <TableCell align="center">Conta</TableCell>
                  <TableCell align="center">Agencia</TableCell>
                  <TableCell align="center">Seq.</TableCell>
                  <TableCell align="center">Codigo Cedente</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsBusca.map((row, index) => (
                  <TableRow key={row.CODCONV}>
                    <TableCell align="center">
                      <Radio
                        checked={selectedValue === row.CODCONV}
                        onChange={handleChangeRadio}
                        value={row.CODCONV}
                        color="default"
                        name="radio-button-demo"
                        inputProps={{ "aria-label": "D" }}
                      />
                    </TableCell>
                    <TableCell align="center">{row.CODCONV}</TableCell>
                    <TableCell align="center">{row.DESCRICAO}</TableCell>
                    <TableCell align="center">{row.BANCO}</TableCell>
                    <TableCell align="center">{row.CONTA}</TableCell>
                    <TableCell align="center">{row.AGENCIA}</TableCell>
                    <TableCell align="center">{row.SEQUENCIA}</TableCell>
                    <TableCell align="center">{row.CEDENCOD}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </CardBody>
      </Card>
      <Modal size="lg" show={show} onHide={handleClose}>
        <form onSubmit={handleClose} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title>Cadastro de Convênio Bancário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>Detalhes</b>
            <hr />
            <TextField autoComplete="off"
              required
              onChange={handleChangeModal}
              name="CODCONV"
              value={values.CODCONV}
              id="outlined-multiline-flexible"
              label="Código do Convênio"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              error={hasError("codconv")}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DESCRICAO"
              value={values.DESCRICAO}
              id="outlined-multiline-flexible"
              label="Descrição"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              name="banco"
              required
              error={hasError("banco")}
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Banco
              </InputLabel>
              <Select
                value={values.BANCO ? values.BANCO : ""}
                name="BANCO"
                label="Banco"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="agebanco"
                    id="outlined-age-simple"
                    label="Banco"
                  />
                }
              >
                {bancos.map((row, index) => (
                  <MenuItem value={row.CODBANCO} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              name="conta"
              required
              error={hasError("conta")}
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Conta
              </InputLabel>
              <Select
                value={values.CONTA ? values.CONTA : ""}
                name="CONTA"
                label="Conta"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                input={
                  <OutlinedInput
                    name="ageconta"
                    id="outlined-age-simple"
                    label="Conta"
                  />
                }
              >
                {fincontas.map((row, index) => (
                  <MenuItem value={row.CODCONTA} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <hr />
            <b>Sequência</b>
            <hr />
            <TextField autoComplete="off"
              required
              onChange={handleChangeModal}
              name="SEQUENCIA"
              value={values.SEQUENCIA}
              id="outlined-multiline-flexible"
              label="Sequencia"
              type="number"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              error={hasError("sequencia")}
            />

            <TextField autoComplete="off"
              required
              onChange={handleChangeModal}
              name="SEQMAX"
              value={values.SEQMAX}
              id="outlined-multiline-flexible"
              label="Sequencia Maxima"
              type="number"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              error={hasError("seqmax")}
            />

            {/*<TextField autoComplete="off"
              onChange={handleChangeModal}
              name="SEQ_EXTERNA"
              value={values.SEQ_EXTERNA}
              id="outlined-multiline-flexible"
              label="Sequencia Externa"
              type="number"
              rowsMax="4"
              disabled={true}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />*/}
            <hr />
            <b>Códigos</b>
            <hr />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="AGENCIA"
              value={values.AGENCIA}
              id="outlined-multiline-flexible"
              label="Agencia"
              type="number"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="AGENDIG"
              value={values.AGENDIG}
              id="outlined-multiline-flexible"
              label="Agencia Digito"
              type="number"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CEDENCOD"
              value={values.CEDENCOD}
              id="outlined-multiline-flexible"
              label="Codigo Cedente"
              type="number"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CEDENDIG"
              value={values.CEDENDIG}
              id="outlined-multiline-flexible"
              label="Digito Cedente"
              type="number"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="AUXILIAR"
              value={values.AUXILIAR}
              id="outlined-multiline-flexible"
              label="Auxiliar"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CONVBANCO"
              value={values.CONVBANCO}
              id="outlined-multiline-flexible"
              label="Convênio Bancário"
              type="number"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />
            <hr />
            <b>Recorrência/API's </b>
            <hr />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="RECO_CCLIENTE"
              value={values.RECO_CCLIENTE}
              id="outlined-multiline-flexible"
              label="Cód. Cliente Recorrência"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="RECO_CHAVE"
              value={values.RECO_CHAVE}
              id="outlined-multiline-flexible"
              label="Chave do Cliente Recorrência"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 432 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="RECO_CPARCEIRO"
              value={values.RECO_CPARCEIRO}
              id="outlined-multiline-flexible"
              label="Cód. do Parceiro Recorrência"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              defaultValue="21329"
              disabled={true}
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="RECO_CHPARCEIRO"
              value={values.RECO_CHPARCEIRO}
              id="outlined-multiline-flexible"
              label="Chave do Parceiro Recorrência"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              defaultValue="TpQtBsB8W69zT8Dh43172cXeStV8UuPeD74iEbPs"
              disabled={true}
              style={{ width: 432 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="RECO_UTILIZA_PARC"
              value={values.RECO_UTILIZA_PARC}
              id="outlined-multiline-flexible"
              label="Utiliza Cód. do Parceiro S/N"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="RECO_SFATURA"
              value={values.RECO_SFATURA}
              id="outlined-multiline-flexible"
              label="Site Env. de Fatura Rec."
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 352 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="RECO_SCLIENTE"
              value={values.RECO_SCLIENTE}
              id="outlined-multiline-flexible"
              label="Site Cad. Cliente Rec."
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 352 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="RECO_SASSINA"
              value={values.RECO_SASSINA}
              id="outlined-multiline-flexible"
              label="Site Cad. Assinatura Rec."
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 352 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="RECO_SPAG"
              value={values.RECO_SPAG}
              id="outlined-multiline-flexible"
              label="Site Verificação Pagamento Rec."
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 352 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="RECO_CONVENIO"
              value={values.RECO_CONVENIO}
              id="outlined-multiline-flexible"
              label="Convênio Recorrencia"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="RECO_OPERADOR"
              value={values.RECO_OPERADOR}
              id="outlined-multiline-flexible"
              label="Operador Recorrencia"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="RECO_PRODUTO"
              value={values.RECO_PRODUTO}
              id="outlined-multiline-flexible"
              label="Produto Recorrencia"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="ENDPAUTENTICA"
              value={values.ENDPAUTENTICA}
              id="outlined-multiline-flexible"
              label="End Point Autenticação"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CHAVEA"
              value={values.CHAVEA}
              id="outlined-multiline-flexible"
              label="Chave Autenticação"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CHAVEB"
              value={values.CHAVEB}
              id="outlined-multiline-flexible"
              label="Chave Autenticação"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button
              className={classes.button}
              onClick={handleSubmit1(cadastrarContrato)}
              type="submit"
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={showDelete} onHide={modalDeleteClose}>
        <form onSubmit={modalDeleteClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Remover convênio</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>Tem certeza que deseja remover o convênio?</h3>
            <br />
            Esta ação <strong>não</strong> pode ser desfeita.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={modalDeleteClose}>
              Não
            </Button>
            <Button
              variant="contained"
              onClick={removerContrato}
              type="button"
              color="primary"
              className={classes.button}
            >
              Sim
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
