import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },

  tableA: {
    backgroundColor: "#DCDCDC", //'#D3D3D3',
  },

  tableB: {
    backgroundColor: "#FFFFFF",
  },

  tableC: {
    backgroundColor: "#FFC0CB", //'#E9967A', // '#FA8072',
    color: "#FFFFFF",
  },

  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  tcontainer: {
    maxHeight: 520,
  },
  tcontainerF: {
    maxHeight: 350,
  },

  lineTextFields: {
    display: "flex",
    gap: "20px",

    marginBottom: "30px",
  },

  textField: {
    flex: 1,
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  fab: {
    disabled: {},
    margin: theme.spacing(1),
    width: 35,
    height: 5,
  },

  fabd: {
    disabled: {},
    margin: theme.spacing(1),
    width: 35,
    height: 5,
  },

  wrapper: {
    position: "relavite",
  },

  lineFormDebits: {
    marginTop: 20,
  },
  textForm: {
    marginLeft: 10,
  },
}));

export const useStylesSelect = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));
