import { makeStyles } from "@material-ui/core";

export const useStylesSelect = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
  footer: {
    margin: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  box: {
    margin: theme.spacing(3),
  },
  containerLoading: {
    position: "absolute",
    background: "rgba(0,0,0,0.2)",
    width: "100%",
    height: "100%",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentLoading: {
    background: "#FFF",
    height: 190,
    width: 220,
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  textLoading: {
    marginTop: 20,
    fontSize: 14,
  },
}));
