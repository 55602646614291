import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { Link } from "react-router-dom";
import { Card } from "../../_metronic/_partials/controls";
import { getToken } from "../services/auth";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "flex-start",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  button: {
    margin: theme.spacing(1),
  },
  moduleClass: {
    padding: "1px",
  },
  moduleCard: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    maxWidth: "350px",
  },

  moduleCard_1: {
    display: "flex",
    alignItems: "center",
    padding: "1px",
    maxWidth: "350px",
  },
}));

export function ModRelatPage() {
  const classes = useStyles();

  //TESTE
  //useEffect(() => {
  const token = getToken();
  console.log("TOKEN ", token);

  return (
    <div>
      <Link to="/relrecrealizado">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/grupos.svg")}/>*/}
            {/*<SVG height="15" src={toAbsoluteUrl("/media/svg/icons/Home/servico.svg")}/>*/}
            <h5>Fluxo de Recebimento</h5>
          </span>
        </Card>
      </Link>
      <Link to="/relrecprevisto">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="15" src={toAbsoluteUrl("/media/svg/icons/Home/servico.svg")}/>*/}
            <h5>Fluxo de Recebimento Previsto</h5>
          </span>
        </Card>
      </Link>
      <Link to="/relrelacaodebito">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/* <SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/familia.svg")}/>*/}
            {/*<SVG height="15" src={toAbsoluteUrl("/media/svg/icons/Home/servico.svg")}/>             */}
            <h5>Relação de Débitos</h5>
          </span>
        </Card>
      </Link>
      <Link to="/relrelacinad">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            {/* <SVG height="15" src={toAbsoluteUrl("/media/svg/icons/Home/servico.svg")}/>*/}
            <h5>Relação de Inadimplência</h5>
          </span>
        </Card>
      </Link>
      <Link to="/manutencaop">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            {/* <SVG height="15" src={toAbsoluteUrl("/media/svg/icons/Home/servico.svg")}/>*/}
            <h5>Recibo de Pagamento</h5>
          </span>
        </Card>
      </Link>
      <Link to="/relcontrato">
        <Card className={classes.moduleCard_1}>
          <span className={classes.moduleClass}>
            {/*<SVG height="20" src={toAbsoluteUrl("/media/svg/icons/Home/categoria.svg")}/>*/}
            {/* <SVG height="15" src={toAbsoluteUrl("/media/svg/icons/Home/servico.svg")}/>*/}
            <h5>Impressão Contrato</h5>
          </span>
        </Card>
      </Link>
    </div>
  );
}
