/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts from "apexcharts";
import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";
// import { DropdownCustomToggler, DropdownMenu2 } from "../../dropdowns";
// import { useHtmlClassService } from "@layout";
import { useHtmlClassService } from "../../../../../_metronic/layout";
import { obterUltimosValoresArray } from "../utils";

export function ChartVendasXCencelados({
  salesVsCancelled,
  topVendas = [],
  topCancelamentos = [],
}) {
  const chartColor = "danger";
  const className = "";

  const uiService = useHtmlClassService();

  const [totais, setTotais] = useState([]);
  const [cancelados, setCancelados] = useState([]);
  const [labels, setLabels] = useState([]);

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);

  async function getChartData() {
    const obj = salesVsCancelled;
    const keysArray = Object.keys(obj);
    const totalArray = [];
    const cancelledArray = [];

    keysArray.forEach((key) => {
      const item = obj[key];
      totalArray.push(item.total ?? 0);
      cancelledArray.push(item.cancelled ?? 0);
    });

    setTotais(obterUltimosValoresArray(totalArray, 6));
    setCancelados(obterUltimosValoresArray(cancelledArray, 6));
    setLabels(obterUltimosValoresArray(keysArray, 6));
  }

  useEffect(() => {
    getChartData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_6_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
    // eslint-disable-next-line
  }, [layoutProps, totais, cancelados, labels]);

  let mediaCancelamentos = (
    cancelados?.reduce((acc, value) => acc + value, 0) / totais.length
  ).toFixed(2);

  let mediaVendas = (
    totais?.reduce((acc, value) => acc + value, 0) / totais.length
  ).toFixed(2);

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div
        className={`card card-custom bg-radial-gradient-danger ${className}`}
        // style={{ width: 400 }}
      >
        {/* begin::Header */}
        <div className="border-0 pt-5">
          {/* <h3 className="card-title font-weight-bolder text-white">
            Vendas x Cancelados
          </h3> */}
          <h4 class="font-weight-bolder ml-8 pt-2 text-white">
            Vendas Realizadas x Canceladas
          </h4>
          <div className="fs-6 fw-bold ml-8 text-gray-400 text-white">
            {/* 24 Overdue Tasks */}
          </div>
          {/* <div className="card-toolbar">
            <Dropdown className="dropdown-inline" alignRight>
              <Dropdown.Toggle
                className="btn btn-text-white btn-hover-white btn-sm btn-icon border-0"
                variant="transparent"
                id="dropdown-toggle-top"
                as={DropdownCustomToggler}
              >
                <i className="ki ki-bold-more-hor" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu2 />
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column p-0">
          {/* begin::Chart */}
          <div
            id="kt_mixed_widget_6_chart"
            data-color={chartColor}
            style={{ height: "200px", width: "400px" }}
          />
          {/* end::Chart */}

          {/* begin::Stats */}
          <div className="card-spacer bg-white card-rounded flex-grow-1">
            {/* begin::Row */}
            <div className="row">
              <div className="col px-2 py-2 mr-2">
                <div className="font-size-sm text-muted font-weight-bold">
                  Média das Vendas
                </div>
                <div className="font-size-h4 font-weight-bolder">
                  R$
                  {mediaVendas}
                </div>
              </div>
              <div className="col px-2 py-2">
                <div className="font-size-sm text-muted font-weight-bold">
                  Média dos cancelamentos
                </div>
                <div className="font-size-h4 font-weight-bolder">
                  R$
                  {mediaCancelamentos}
                </div>
              </div>
            </div>
            {/* end::Row */}
            {/* begin::Row */}
            <div className="row">
              <div className="col px-2 py-2 mr-2">
                <div className="text-muted font-weight-bold">
                  % médio de cancelamentos por venda
                </div>
                <div className="font-size-h4 font-weight-bolder">
                  {(mediaCancelamentos / mediaVendas).toFixed(2)}%
                </div>
              </div>
            </div>

            <div className="d-flex flex-row justify-content-between">
              <div>
                <div className="row">
                  <div className="col px-2 py-2 mr-2">
                    <p class="font-weight-bolder text-muted">Top emissão</p>
                  </div>
                </div>

                <div className="row px-2 d-flex flex-column">
                  {obterUltimosValoresArray(topVendas, 6)?.map(
                    (vendor, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex justify-content-between font-weight-bolder "
                        >
                          <span className="font-size-sm">
                            {index + 1}. {vendor?.vendedor}
                          </span>
                          <span className="ml-4">{vendor?.total}</span>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div>
                <div className="row">
                  <div className="col px-2 py-2 mr-2">
                    <p class="font-weight-bolder text-muted">
                      Top cancelamento
                    </p>
                  </div>
                </div>

                <div className="row px-2 d-flex flex-column">
                  {obterUltimosValoresArray(topCancelamentos, 6)?.map(
                    (vendor, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex justify-content-between font-weight-bolder "
                        >
                          <span className="font-size-sm">
                            {index + 1}. {vendor?.vendedor}
                          </span>
                          <span className="ml-4">{vendor?.total}</span>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>

            {/* end::Row */}
          </div>

          {/* end::Stats */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );

  function getChartOption(layoutProps) {
    const options = {
      series: [
        {
          name: "Realizada",
          data: totais,
        },
        {
          name: "Cancelada",
          data: cancelados,
        },
      ],
      chart: {
        type: "bar",
        height: "200px",
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["30%"],
          endingShape: "rounded",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },
      xaxis: {
        // categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: layoutProps.colorsGrayGray500,
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily,
          },
        },
      },
      yaxis: {
        min: 0,
        // max: 100,
        labels: {
          style: {
            colors: layoutProps.colorsGrayGray500,
            fontSize: "12px",
            fontFamily: layoutProps.fontFamily,
          },
        },
      },
      fill: {
        type: ["solid", "solid"],
        opacity: [0.25, 1],
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
        // y: {
        //   formatter: function(val) {
        //     return "$" + val + " thousands";
        //   },
        // },
        marker: {
          show: false,
        },
      },
      colors: ["#ffffff", "#ffffff"],
      grid: {
        borderColor: layoutProps.colorsGrayGray200,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          left: 20,
          right: 20,
        },
      },
    };
    return options;
  }
}
