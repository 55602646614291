/* eslint-disable */
import {
  Button,
  Checkbox,
  Fab,
  FormControl,
  FormHelperText,
  Icon,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";

import {
  makeStyles,
  lighten,
  withStyles,
  useTheme
} from "@material-ui/core/styles";


import FilterListIcon from "@material-ui/icons/FilterList";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import DeleteIcon from "@material-ui/icons/Delete";
import LinkIcon from "@material-ui/icons/Link";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";

import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import api from "../../services/api";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

import { useStyles, useStylesSelect } from "./styles";
import { ToastContainer, toast } from "react-toastify";

export function CobrancasPage() {
  const [cobrancasSelecionadas, setCobrancasSelecionadas] = useState([]);
  const [
    isVisibleConfirmBillingModal,
    setIsVisibleConfirmBillingModal,
  ] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [formDataFilter, setFormDataFilter] = useState({
    name: "",
    cpf: "",
    initial_date: "",
    end_date: "",
    initial_id: "",
    end_id: "", 
    convenio: "",
    sending: false,
  });

  const classes = useStyles();
  const classesSelect = useStylesSelect();
  const [errors, setErrors] = useState([]);
  const [show, setShow] = useState(false);
  const [editar, setEditar] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseCobrdocu = () => setShowCobrDocu(false);
  const handleCloseCobrlog = () => setShowCobrLog(false);
  const [categorias, setCategorias] = useState([]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function TablePaginationActions5(props) {
    // const classes = useStyles51();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    function handleFirstPageButtonClick(event) {
      onChangePage(event, 0);
    }
  
    function handleBackButtonClick(event) {
      onChangePage(event, page - 1);
    }
  
    function handleNextButtonClick(event) {
      onChangePage(event, page + 1);
    }
  
    function handleLastPageButtonClick(event) {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }
  
    return (
      <div className={classes.root} style={{marginTop: 0}}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  function atualizaContratos() {
    api.get(generateRoute()).then((res) => {
      res.data?.results.map((data_plano, i) => {
        if (i === 0) {
          setSelectedValue(data_plano.IDCOBRA);
        }
        data_plano.IS_SHOW_ACTION = false;
      });
      setTotalContractsApi(res.data?.total);
      setRows(res.data?.results);
      setRowsBusca(res.data?.results);
    });
  }

  function formatCurrency(valor) {
    if (valor !== null && valor !== undefined) {
      return valor.replace(".", ",");
    }
    return valor;
  }

  const [showDelete, setShowDelete] = useState(false);
  const [showCobrdocu, setShowCobrDocu] = useState(false);
  const [showCobrlog, setShowCobrLog] = useState(false);
  const [planoAtual, setPlanoAtual] = useState();
  const [linhaARemover, setLinhaARemover] = useState();

  const modalDeleteClose = () => setShowDelete(false);

  function modalDeleteShow() {
    //console.log(selectedValue);
    setShowDelete(true);
    rows.map((row_aux, i) => {
      if (selectedValue === row_aux.IDCOBRA) {
        //console.log("i", i);
        setLinhaARemover(i);
      }
    });
  }

  function showOrHideActions(index, value) {
    const newRow = [...rows];
    const row = newRow[index];
    row.IS_SHOW_ACTION = !value;
    setRows(rows.map((item) => (item.IDCOBRA === index ? row : item)));
  }

  function modalCobrdocuShow(index) {
    let idCobSelecionado = [...rows][index].IDCOBRA;
    api.get("/cobrdocu/" + idCobSelecionado).then((resCob) => {
      // console.log("cobrdocu ", JSON.parse(JSON.stringify(resCob.data)));
      setPlanoAtual(idCobSelecionado);
      setFilteredCobrdocu(resCob.data);
      setShowCobrDocu(true);
    });
  }

  function modalCobrlogShow(index) {
    let idCobSelecionado = [...rows][index].IDCOBRA;
    api.get("/cobrlog/" + idCobSelecionado).then((resCob) => {
      // console.log("cobrlog ", JSON.parse(JSON.stringify(resCob.data)));
      setPlanoAtual(idCobSelecionado);
      setFilteredCobrlog(resCob.data);
      setShowCobrLog(true);
    });
  }

  function modalCobrEnv(index) {
    alert("Envio de Cobrança");
  }

  const [rows, setRows] = useState([]);
  const [totalContractsApi, setTotalContractsApi] = useState(0);
  const [rowsBusca, setRowsBusca] = useState([]);
  const [filteredCobrdocu, setFilteredCobrdocu] = useState([]);
  const [filteredCobrlog, setFilteredCobrlog] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [convernios, setConvenios] = useState([]);

  useEffect(() => {
    atualizaContratos();
  }, [rowsPerPage, page]);

  useEffect(() => {
    api.get("/cobrcateg").then((res) => {
      setCategorias(res.data);
    });
  }, []);

  useEffect(() => {
    api.get("/cobrconv").then((res) => {
      setConvenios(res.data);
    });
  }, []);

  const [values, setValues] = useState({});

  const handleChangeModal = (event) => {
    const auxValues = { ...values };
    // console.log("VALOR ", event.target.value);
    auxValues[event.target.name] = event.target.value;
    setValues(auxValues);
  };

  function hasError(key) {
    return errors.indexOf(key) !== -1;
  }

  function editarContrato() {
    rows.map((row_aux, i) => {
      if (selectedValue === row_aux.IDCOBRA) {
        //console.log("IIIII ", i);
        const row = rows[i];
        //console.log("row ", row);
        const auxEditar = row;
        setShow(true);
        setEditar(true);
        setValues(auxEditar);
        setErrors([]);
        //somenteleitura = true;
      }
    });
  }

  async function removerContrato() {
    Promise.all(
      cobrancasSelecionadas.map((cobranca) =>
        api.delete(`/cobranca/${cobranca.IDCOBRA}`)
      )
    ).then(() => {
      toast.success("Cobrança(s) apagada(s) com sucesso");
      atualizaContratos();
      setShow(false);
    });

    modalDeleteClose();
    atualizaContratos();
  }

  function dataAtualFormatada() {
    var data = new Date(),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? "0" + mes : mes,
      anoF = data.getFullYear();
    return anoF + "-" + mesF + "-" + diaF;
  }

  //CEP
  function onBlurCep(ev) {
    const { value } = ev.target;
    const cep = value?.replace(/[^0-9]/g, "");
    if (cep?.length !== 8) {
      return;
    }
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        document.getElementById("ENDERECO").value = data.logradouro;
        document.getElementById("BAIRRO").value = data.bairro;
        document.getElementById("CIDADE").value = data.localidade;
        document.getElementById("ESTADO").value = data.uf;
        document.getElementById("PAIS").value = "Brasil";

        values.ENDERECO = data.logradouro;
        values.BAIRRO = data.bairro;
        values.CIDADE = data.localidade;
        values.ESTADO = data.uf;
        values.PAIS = "Brasil";

        document.getElementById("NUMERO").focus();
      });
  }

  function handleCheckItem(idCobra) {
    const index = cobrancasSelecionadas.findIndex(
      (item) => item.IDCOBRA === idCobra
    );

    if (index > -1) {
      const cobrancasFiltradas = cobrancasSelecionadas.filter(
        (item) => item.IDCOBRA !== idCobra
      );
      setCobrancasSelecionadas(cobrancasFiltradas);
    } else {
      const [debito] = rowsBusca.filter((item) => item.IDCOBRA === idCobra);

      setCobrancasSelecionadas([...cobrancasSelecionadas, { ...debito }]);
    }
  }

  function searchItemSelected(idCobra) {
    const item = !!cobrancasSelecionadas.find(
      (item) => item.IDCOBRA === idCobra
    );

    return item;
  }

  function generateRoute() {
    let route = "cobranca?";

    if (formDataFilter.name) {
      route += `nome=${formDataFilter.name}&`;
    }

    if (formDataFilter.cpf) {
      route += `cnpj_cpf=${formDataFilter.cpf.replace(/\D/g, "")}&`;
    }

    if (formDataFilter.initial_id) {
      route += `seqInicial=${formDataFilter.initial_id}&`;
    }

    if (formDataFilter.end_id) {
      route += `seqFinal=${formDataFilter.end_id}&`;
    }


    if (formDataFilter.initial_date) {
      route += `dataInicial=${formDataFilter.initial_date}&`;
    }

    if (formDataFilter.end_date) {
      route += `dataFinal=${formDataFilter.end_date}&`;
    }

    if (formDataFilter.convenio) {
      route += `convenio=${formDataFilter.convenio}&`;
    }

    if (formDataFilter.sending) {
      route += `cobrancasEnviadas=${String(formDataFilter.sending)}&`;
    }

    return `${route}page=${page + 1}&pageSize=${rowsPerPage}`;
  }

  async function sendBillings() {
    const idBillings = cobrancasSelecionadas
      .map((cobranca) => cobranca.IDCOBRA)
      .join(", ");

    let formData = new FormData();
    formData.append("ids_cobranca", idBillings);
    formData.append("type", "GALAXPAY");

    try {
      await api.post("/transaction", formData);

      toast.success("Cobranças enviadas com sucesso");
    } catch (error) {
      toast.error("Erro ao enviar cobranças");
    } finally {
      atualizaContratos();
      setCobrancasSelecionadas([]);
      setIsVisibleConfirmBillingModal(false);
    }
  }

  const handleChangePage = (_, newPageTx) => {
    setPage(newPageTx);
  };

  function findIdCobranca(id, listIds = []) {
    const idFinded = listIds?.findIndex((item) => Number(item) === Number(id));

    if (idFinded > -1) {
      return true;
    }

    return false;
  }

  return (
    <>
      <Card>
        <CardHeader title="Cobrança">
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <form className={classesSelect.root} autoComplete="off">
            <FormControl className={classesSelect.margin}>
              <FormHelperText>Filtrar por Nome</FormHelperText>
              <TextField autoComplete="off"
                size="small"
                variant="outlined"
                style={{ width: 180, marginTop: 0 }}
                value={formDataFilter.name}
                onChange={(event) =>
                  setFormDataFilter({
                    ...formDataFilter,
                    name: event.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl className={classesSelect.margin}>
              <FormHelperText>Filtrar por CPF</FormHelperText>
              <TextField autoComplete="off"
                size="small"
                variant="outlined"
                style={{ width: 180, marginTop: 0 }}
                value={formDataFilter.cpf}
                onChange={(event) =>
                  setFormDataFilter({
                    ...formDataFilter,
                    cpf: event.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl className={classesSelect.margin}>
              <FormHelperText>Número inicial</FormHelperText>
              <TextField autoComplete="off"
                type="number"
                size="small"
                variant="outlined"
                style={{ width: 180, marginTop: 0 }}
                value={formDataFilter.initial_id}
                onChange={(event) =>
                  setFormDataFilter({
                    ...formDataFilter,
                    initial_id: event.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl className={classesSelect.margin}>
              <FormHelperText>Número Final</FormHelperText>
              <TextField autoComplete="off"
                type="number"
                size="small"
                variant="outlined"
                style={{ width: 180, marginTop: 0 }}
                value={formDataFilter.end_id}
                onChange={(event) =>
                  setFormDataFilter({
                    ...formDataFilter,
                    end_id: event.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl className={classesSelect.margin}>
              <FormHelperText>Data vencimento início</FormHelperText>
              <TextField autoComplete="off"
                type="date"
                size="small"
                variant="outlined"
                style={{ width: 180, marginTop: 0 }}
                value={formDataFilter.initial_date}
                onChange={(event) =>
                  setFormDataFilter({
                    ...formDataFilter,
                    initial_date: event.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl className={classesSelect.margin}>
              <FormHelperText>Data vencimento fim</FormHelperText>
              <TextField autoComplete="off"
                type="date"
                size="small"
                variant="outlined"
                style={{ width: 180, marginTop: 0 }}
                value={formDataFilter.end_date}
                onChange={(event) =>
                  setFormDataFilter({
                    ...formDataFilter,
                    end_date: event.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl className={classesSelect.margin}>
              <FormHelperText>Filtrar por Convénio</FormHelperText>
              <TextField autoComplete="off"
                select
                size="small"
                variant="outlined"
                style={{ width: 180, marginTop: 0 }}
                value={formDataFilter.convenio}
                onChange={(event) =>
                  setFormDataFilter({
                    ...formDataFilter,
                    convenio: event.target.value,
                  })
                }
              >
                <MenuItem value="">Todos</MenuItem>
                {convernios.map((convenio) => (
                  <MenuItem value={convenio.CODCONV}>
                    {convenio.CODCONV}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl
              className={classesSelect.margin}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormHelperText>Apenas cobranças enviadas</FormHelperText>
              <Checkbox
                color="default"
                checked={formDataFilter.sending}
                onChange={(event) =>
                  setFormDataFilter({
                    ...formDataFilter,
                    sending: !formDataFilter.sending,
                  })
                }
              />
            </FormControl>
            <div style={{ marginTop: 18 }}>
              <Fab
                title="Pesquisar"
                className={classes.fab}
                aria-label="Search"
                onClick={() => {
                  setCobrancasSelecionadas([]);
                  setPage(0);
                  atualizaContratos();
                }}
              >
                <SearchIcon />
              </Fab>
              <Fab
                title="Visualizar"
                className={classes.fab}
                color="secondary"
                onClick={() => editarContrato()}
                aria-label="Edit"
              >
                <Icon>visibility</Icon>
              </Fab>
              <Fab
                title="Apagar"
                className={classes.fab}
                aria-label="Delete"
                onClick={() => modalDeleteShow()}
              >
                <DeleteIcon />
              </Fab>
              <Link to={``}>
                <Fab
                  title="Imprimir"
                  className={classes.fab}
                  color="inherit"
                  aria-label="Imprimir"
                  onClick={handlePrint}
                >
                  <Icon>print</Icon>
                </Fab>
              </Link>
              <Fab
                disabled={!!cobrancasSelecionadas.length < 1}
                title="Enviar cobranças"
                className={classes.fab}
                aria-label="Delete"
                onClick={() => setIsVisibleConfirmBillingModal(true)}
              >
                <SendIcon />
              </Fab>
            </div>
          </form>
          <Paper className={classes.root}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">Convênio</TableCell>
                  <TableCell align="center">Numero</TableCell>
                  <TableCell align="center">Plano</TableCell>
                  <TableCell align="center">Nome</TableCell>
                  <TableCell align="center">CPF/CNPJ</TableCell>
                  <TableCell align="center">Vencimento</TableCell>
                  <TableCell align="center">Valor</TableCell>
                  <TableCell align="center">Pagamento</TableCell>
                  <TableCell align="center">Valor PG</TableCell>
                  <TableCell align="center">
                    Data de envio da cobrança
                  </TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsBusca.map((row, index) => (
                  <TableRow
                    key={index}
                    className={`${
                      row.BAIXADATA != null
                        ? classes.tableA
                        : `${
                            row.VENCIMENTO < dataAtualFormatada()
                              ? classes.tableC
                              : classes.tableB
                          }`
                    }`}
                  >
                    <TableCell align="center">
                      <Checkbox
                        disabled={findIdCobranca(
                          row.IDCOBRA,
                          JSON.parse(row.parsedIdCobrancas)
                        )}
                        // disabled={!!row.link_cobranca}
                        checked={searchItemSelected(row.IDCOBRA)}
                        onChange={() => handleCheckItem(row.IDCOBRA)}
                        color="default"
                      />
                    </TableCell>
                    <TableCell align="center">{row.CONVENIO}</TableCell>
                    <TableCell align="center">{row.SEQUENCIA}</TableCell>
                    <TableCell align="center">{row.SACADOID}</TableCell>
                    <TableCell align="center">{row.RAZ_SOCIAL}</TableCell>
                    <TableCell align="center">
                      {row.CNPJ_CPF
                        ? row.CNPJ_CPF.replace(
                            /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                            "$1.$2.$3-$4"
                          )
                        : ""}
                    </TableCell>
                    <TableCell align="center">
                      {row.VENCIMENTO != null
                        ? moment(row.VENCIMENTO).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell align="center">
                      {formatCurrency(row.VALORTOT)}
                    </TableCell>
                    <TableCell align="center">
                      {row.BAIXADATA != null
                        ? moment(row.BAIXADATA).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell align="center">
                      {formatCurrency(row.BAIXAVALOR)}
                    </TableCell>

                    <TableCell align="center">
                      {row.created_at_galaxPay != null
                        ? moment(row.created_at_galaxPay).format("DD/MM/YYYY")
                        : ""}
                    </TableCell>

                    <TableCell align="center">
                      <Fab
                        className={classes.fab}
                        color="secondary"
                        onClick={() =>
                          showOrHideActions(index, row.IS_SHOW_ACTION)
                        }
                        aria-label="Dependentes"
                      >
                        <Icon>zoom_out_map</Icon>
                      </Fab>
                      {row.IS_SHOW_ACTION ? (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            maxWidth: 140,
                            justifyContent: "center",
                          }}
                        >
                          <Fab
                            title="Documentos"
                            className={classes.fab}
                            color="primary"
                            onClick={() => modalCobrdocuShow(index)}
                            aria-label="Documentos"
                          >
                            <Icon>description</Icon>
                          </Fab>
                          <Fab
                            title="Log"
                            className={classes.fab}
                            color="primary"
                            onClick={() => modalCobrlogShow(index)}
                            aria-label="Log"
                          >
                            <Icon>error</Icon>
                          </Fab>
                          <Fab
                            title="Enviar Cobrança"
                            className={classes.fab}
                            color="primary"
                            onClick={() => modalCobrEnv(index)}
                            aria-label="Enviar Cobrança"
                          >
                            <Icon>outbox</Icon>
                          </Fab>
                          {/* <a href={row.link_cobranca} target="_blank"> */}
                          <NavLink
                            isActive={
                              !findIdCobranca(
                                row.IDCOBRA,
                                JSON.parse(row.parsedIdCobrancas)
                              )
                            }
                            link={row.link_cobranca}
                          >
                            <Fab
                              disabled={
                                !findIdCobranca(
                                  row.IDCOBRA,
                                  JSON.parse(row.parsedIdCobrancas)
                                )
                              }
                              title="Abrir Cobrança"
                              className={classes.fab}
                              color="primary"
                              // onClick={() => modalCobrEnv(index)}
                              aria-label="Enviar Cobrança"
                            >
                              <LinkIcon />
                            </Fab>
                          </NavLink>
                          {/* </a> */}
                        </div>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={totalContractsApi}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={(v) => setRowsPerPage(v.target.value)}
              labelRowsPerPage={"Registros por Página:"}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
              ActionsComponent={TablePaginationActions5}
            />
          </Paper>
        </CardBody>
      </Card>

      <Modal size="lg" show={show} onHide={handleClose}>
        <form autoComplete="off" onSubmit={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Visualizar Cobrança</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <b>Cobrança</b>
            <hr />

            <TextField autoComplete="off"
              required
              onChange={handleChangeModal}
              name="Razão Social"
              value={values.RAZ_SOCIAL}
              id="outlined-multiline-flexible"
              label="Nome"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              disabled={true}
              error={hasError("raz_social")}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CNPJ_CPF"
              value={values.CNPJ_CPF}
              id="outlined-multiline-flexible"
              label="CNPJ/CPF "
              type="number"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              disabled={true}
              error={hasError("cnpj_cpf")}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="IE_RG"
              value={values.IE_RG}
              id="outlined-multiline-flexible"
              label="Inscrição Estadual/RG"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              disabled={true}
            />
            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CEP"
              value={values.CEP}
              id="outlined-multiline-flexible"
              label="CEP"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              onBlur={(ev) => onBlurCep(ev)}
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              onBlur={handleChangeModal}
              name="ENDERECO"
              value={values.ENDERECO}
              id="ENDERECO"
              label="Endereço"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              onFocus={handleChangeModal}
              id="NUMERO"
              name="NUMERO"
              value={values.NUMERO}
              label="Número"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="COMPLEMENT"
              value={values.COMPLEMENT}
              id="outlined-multiline-flexible"
              label="Complemento"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              onBlur={handleChangeModal}
              name="BAIRRO"
              value={values.BAIRRO}
              id="BAIRRO"
              label="Bairro"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              onBlur={handleChangeModal}
              name="CIDADE"
              value={values.CIDADE}
              id="CIDADE"
              label="Cidade"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              onBlur={handleChangeModal}
              name="ESTADO"
              value={values.ESTADO}
              id="ESTADO"
              label="Estado"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              onBlur={handleChangeModal}
              name="PAIS"
              value={values.PAIS}
              id="PAIS"
              label="País"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="CPOSTAL"
              value={values.CPOSTAL}
              id="outlined-multiline-flexible"
              label="Caixa Postal"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              disabled={true}
            />
            <hr />
            <b>Classificação</b>
            <hr />

            <FormControl
              className="MuiFormControl-root MuiTextField-root makeStyles-textField-4 MuiFormControl-marginNormal"
              style={{ width: 232, marginLeft: 0, marginRight: 16 }}
            >
              <InputLabel htmlFor="name-multiple" style={{ marginLeft: 20 }}>
                Categoria
              </InputLabel>
              <Select
                value={values.CATEGORIA ? values.CATEGORIA : " "}
                name="CATEGORIA"
                label="Categoria"
                onChange={handleChangeModal}
                style={{ width: 232 }}
                className={classes.textField}
                disabled={true}
                input={
                  <OutlinedInput
                    name="age"
                    id="outlined-age-simple"
                    label="Categoria"
                  />
                }
              >
                {categorias.map((row, index) => (
                  <MenuItem value={index} key={index}>
                    {row.DESCRICAO}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <hr />
            <b>Documento</b>
            <hr />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DOCUMENTO"
              value={values.DOCUMENTO}
              id="outlined-multiline-flexible"
              label="Documento"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              style={{ width: 232 }}
              disabled={true}
            />

            <hr />
            <b>Datas e Valores</b>
            <hr />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="VENCIMENTO"
              value={values.VENCIMENTO}
              id="VENCIMENTO"
              label="Vencimento"
              type="date"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="VALOR"
              value={values.VALOR}
              id="VALOR"
              label="Valor"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="DESCONTO"
              value={values.DESCONTO}
              id="DESCONTO"
              label="Desconto"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="OUTROS"
              value={values.OUTROS}
              id="OUTROS"
              label="Outros"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="JUROS"
              value={values.JUROS}
              id="JUROS"
              label="Juros"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="MULTA"
              value={values.MULTA}
              id="MULTA"
              label="Multa"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
              disabled={true}
            />

            <TextField autoComplete="off"
              onChange={handleChangeModal}
              name="VALORTOT"
              value={values.VALORTOT}
              id="VALORTOT"
              label="Valor Total"
              type="text"
              rowsMax="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: 232 }}
              disabled={true}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            {/*<Button className={classes.button} onClick={handleSubmit1(cadastrarContrato)} type="submit" color="primary" variant="contained">
              Salvar
            </Button>*/}
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={showDelete} onHide={modalDeleteClose}>
        <form onSubmit={modalDeleteClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Remover cobrança</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>Tem certeza que deseja remover a cobrança?</h3>
            <br />
            Esta ação <strong>não</strong> pode ser desfeita.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={modalDeleteClose}>
              Não
            </Button>
            <Button
              variant="contained"
              onClick={removerContrato}
              type="button"
              color="primary"
              className={classes.button}
            >
              Sim
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/******************* DOCUMENTOS ***********************/}
      <Modal size="lg" show={showCobrdocu} onHide={handleCloseCobrdocu}>
        <form onSubmit={handleCloseCobrdocu}>
          <Modal.Header closeButton>
            <Modal.Title>Documentos da Cobrança</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Venc. Original</TableCell>
                    <TableCell align="center">Valor Original</TableCell>
                    <TableCell align="center">Juros</TableCell>
                    <TableCell align="center">Multa</TableCell>
                    <TableCell align="center">Orig. ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCobrdocu.map((rowsDoc, index) => (
                    <TableRow key={index} className={classes.table}>
                      <TableCell align="center">
                        {rowsDoc.VENCORIG != null
                          ? moment(rowsDoc.VENCORIG).format("DD/MM/YYYY")
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        {formatCurrency(rowsDoc.VALORORIG)}
                      </TableCell>
                      <TableCell align="center">
                        {formatCurrency(rowsDoc.JUROS)}
                      </TableCell>
                      <TableCell align="center">
                        {formatCurrency(rowsDoc.MULTA)}
                      </TableCell>
                      <TableCell align="center">
                        {formatCurrency(rowsDoc.ORIGEMID)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCobrdocu}>
              Fechar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      {/******************* LOG ***********************/}
      <Modal size="lg" show={showCobrlog} onHide={handleCloseCobrlog}>
        <form onSubmit={handleCloseCobrlog}>
          <Modal.Header closeButton>
            <Modal.Title>Log da Cobrança</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Tipo</TableCell>
                    <TableCell align="center">Descricao</TableCell>
                    <TableCell align="center">Detalhe</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCobrlog.map((rowsLog, index) => (
                    <TableRow key={index} className={classes.table}>
                      <TableCell align="center">{rowsLog.TIPO}</TableCell>
                      <TableCell align="center">{rowsLog.DESCRICAO}</TableCell>
                      <TableCell align="center">{rowsLog.DETALHE}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCobrlog}>
              Fechar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={isVisibleConfirmBillingModal}
        onHide={() => setIsVisibleConfirmBillingModal(false)}
      >
        <form onSubmit={handleCloseCobrlog}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmação de envio</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Typography>Desejar enviar as cobranças selecionadas?</Typography>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained" color="primary" onClick={sendBillings}>
              Sim
            </Button>
            <Button
              variant="secondary"
              onClick={() => setIsVisibleConfirmBillingModal(false)}
            >
              Não
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <ToastContainer />
    </>
  );
}

function NavLink({ children, link, isActive }) {
  if (!isActive) {
    return (
      <a href={link} target="_blank">
        {children}
      </a>
    );
  }
  return <div>{children}</div>;
}
