import React, { useEffect, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import moment from "moment";

import { useParams } from "react-router-dom";
import api from "../../../../services/api";

export function ComponentToPrint({ componentRef }) {
  const { idPlano } = useParams();

  const [ctPlano, setCtPlano] = useState([]);
  const [ctPlanoDebito, setCtPlanoDebito] = useState([]);
  const [ctPlanoDepend, setCtPlanoDepend] = useState([]);

  useEffect(() => {
    api.get("/ct_plano/" + idPlano).then((res) => {
      setCtPlano(res.data);
    });

    api.get("/ct_pl_debito/" + idPlano).then((res) => {
      setCtPlanoDebito(res.data);
    });

    api.get("/ct_pl_depend/" + idPlano).then((res) => {
      setCtPlanoDepend(res.data);
    });
  }, [idPlano]);

  function formatCurrency(valor) {
    if (valor !== null && valor !== undefined) {
      return valor.replace(".", ",");
    }
    return valor;
  }

  return (
    <div ref={componentRef}>
      <Table>
        <TableRow>
          <TableCell width="5%" bgcolor="black">
            <div bgcolor="black">
              <img
                src={ctPlano?.EMP_LOGO}
                width="50px"
                height="50px"
                alt=""
              ></img>
            </div>
          </TableCell>
          <TableCell>
            <h1 align="center">{ctPlano?.EMP_RAZAO_SOCIAL}</h1>
          </TableCell>
        </TableRow>
      </Table>{" "}
      <br />
      <Typography variant="h3" gutterBottom style={{ textAlign: "center" }}>
        Ficha Geral
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        style={{ textAlign: "center" }}
        id="plano"
      >
        Inscrição: <b>{ctPlano?.IDPLANO}</b>
      </Typography>
      <Typography variant="h4" gutterBottom style={{ textAlign: "left" }}>
        Dados do titular:
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell colSpan="6">
              Nome: <b>{ctPlano?.NOME}</b>
            </TableCell>
            <TableCell colSpan="3">
              CNPJ / CPF:{" "}
              <b>
                {ctPlano?.CNPJ_CPF
                  ? ctPlano?.CNPJ_CPF.replace(
                      /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                      "$1.$2.$3-$4"
                    )
                  : ""}
              </b>
            </TableCell>
            <TableCell colSpan="3">
              RG: <b>{ctPlano?.IE_RG}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan="6">
              Endereço: <b>{ctPlano?.ENDERECO}</b>{" "}
            </TableCell>
            <TableCell colSpan="3">
              Numero: <b>{ctPlano?.NUMERO}</b>
            </TableCell>
            <TableCell colSpan="3">
              Complemento: <b>{ctPlano?.COMPLEMENT}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan="3">
              Bairro: <b>{ctPlano?.BAIRRO}</b>
            </TableCell>
            <TableCell colSpan="3">
              Cidade: <b>{ctPlano?.CIDADE}</b>
            </TableCell>
            <TableCell colSpan="3">
              CEP: <b>{ctPlano?.CEP}</b>
            </TableCell>
            <TableCell colSpan="3">
              Estado: <b>{ctPlano?.ESTADO}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan="3">
              Fone: <b>{ctPlano?.FONE}</b>
            </TableCell>
            <TableCell colSpan="3">
              Celular: <b>{ctPlano?.CELULAR}</b>
            </TableCell>
            <TableCell colSpan="3">
              Fone Contato: <b>{ctPlano?.FAX}</b>
            </TableCell>
            <TableCell colSpan="3">
              Contato: <b>{ctPlano?.CONTATO}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan="6">
              Email: <b>{ctPlano?.EMAIL}</b>
            </TableCell>
            <TableCell colSpan="3">
              Carência Atendimento:{" "}
              <b>
                {ctPlano?.CAREN_AT != null
                  ? moment(ctPlano?.CAREN_AT).format("DD/MM/YYYY")
                  : ""}
              </b>
            </TableCell>
            <TableCell colSpan="3">
              Carência Pagamento:{" "}
              <b>
                {ctPlano?.CAREN_PG != null
                  ? moment(ctPlano?.CAREN_PG).format("DD/MM/YYYY")
                  : ""}
              </b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan="12">
              Observação: <b>{ctPlano?.OBSERVACAO}</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
      <br />
      <br />
      <Typography variant="h4" gutterBottom style={{ textAlign: "left" }}>
        Dados dos dependentes:
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Nome</TableCell>
            <TableCell align="center">Parentesco</TableCell>
            <TableCell align="center">Nascimento</TableCell>
            <TableCell align="center">Falecimento</TableCell>
            <TableCell align="center">Carencia</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ctPlanoDepend?.map((rowsDep, index) => {
            return (
              <TableRow key={rowsDep.IDDEP} jey={index}>
                <TableCell align="center">{rowsDep.NOME}</TableCell>
                <TableCell align="center">{rowsDep.CODGRAU}</TableCell>
                <TableCell align="center">
                  {rowsDep.DT_NASC != null
                    ? moment(rowsDep.DT_NASC).format("DD/MM/YYYY")
                    : ""}
                </TableCell>
                <TableCell align="center">
                  {rowsDep.DT_FALEC != null
                    ? moment(rowsDep.DT_FALEC).format("DD/MM/YYYY")
                    : ""}
                </TableCell>
                <TableCell align="center">
                  {rowsDep.CAREN_AT != null
                    ? moment(rowsDep.CAREN_AT).format("DD/MM/YYYY")
                    : ""}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <br />
      <br />
      <br />
      <Typography variant="h4" gutterBottom style={{ textAlign: "left" }}>
        Débitos do plano
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Categoria</TableCell>
            <TableCell align="center">Tipo</TableCell>
            <TableCell align="center">Referencia</TableCell>
            <TableCell align="center">Vencimento</TableCell>
            <TableCell align="center">Valor</TableCell>
            <TableCell align="center">Pagamento</TableCell>
            <TableCell align="center">Valor Pago</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ctPlanoDebito?.map((rowsDeb, index) => (
            <TableRow key={rowsDeb.IDDEBITO} jey={index}>
              <TableCell align="center">{rowsDeb.CATEG}</TableCell>
              <TableCell align="center">{rowsDeb.DESCRICAO}</TableCell>
              <TableCell align="center">{rowsDeb.REFERENCIA}</TableCell>
              <TableCell align="center">{rowsDeb.DT_VENC_F}</TableCell>
              <TableCell align="center">
                {formatCurrency(rowsDeb.VALOR)}
              </TableCell>
              <TableCell align="center">{rowsDeb.DT_PAGTO_F}</TableCell>
              <TableCell align="center">
                {formatCurrency(rowsDeb.VL_PAGO)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
