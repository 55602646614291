export const dataMenu = [
  {
    title: "Módulos",
    icon: "/media/svg/icons/Layout/Layout-4-blocks.svg",
    items: [
      {
        title: "Contratos",
        icon: "/media/svg/misc/006-plurk.svg",
        route: "/contratos",
        subItems: [],
      },
      {
        title: "Cobranças",
        icon: "/media/svg/misc/010-vimeo.svg",
        route: "/cobranca",
        subItems: [],
      },
      {
        title: "Débitos",
        icon: "/media/svg/misc/008-infography.svg",
        route: "/debitos",
        subItems: [],
      },
      {
        title: "Relatórios",
        icon: "/media/svg/misc/007-disqus.svg",
        route: "",
        subItems: [
          {
            title: "Fluxo de Recebimento",
            icon: "",
            route: "/relrecrealizado",
          },
          {
            title: "Fluxo de Recebimento Previsto",
            icon: "",
            route: "/relrecprevisto",
          },
          {
            title: "Relação de Débitos",
            icon: "",
            route: "/relrelacaodebito",
          },
          {
            title: "Relação de Inadimplência",
            icon: "",
            route: "/relrelacinad",
          },
          {
            title: "Recibo de Pagamento",
            icon: "",
            route: "/manutencaop",
          },
          {
            title: "Impressão Contrato",
            icon: "",
            route: "/relcontrato",
          },
          {
            title: "Analítico / Sintético",
            icon: "",
            route: "/relatorios",
          },
        ],
      },
    ],
  },
  {
    title: "Configurações",
    icon: "/media/svg/icons/Layout/Layout-4-blocks.svg",
    items: [
      {
        title: "Usuários",
        icon: "/media/svg/misc/014-kickstarter.svg",
        route: "/usuarios",
        subItems: [],
      },
      {
        title: "Empresas",
        icon: "/media/svg/misc/009-hot-air-balloon.svg",
        route: "/empresas-novo",
        subItems: [],
      },

      {
        title: "Manutenção",
        icon: "/media/svg/misc/005-bebo.svg",
        route: "",
        subItems: [
          {
            title: "Grupos",
            icon: "",
            route: "/grupos",
          },
          {
            title: "Famílias",
            icon: "",
            route: "/familias",
          },
          {
            title: "Categoria de contrato",
            icon: "",
            route: "/categorias",
          },
          {
            title: "Vendedores",
            icon: "",
            route: "/vendedor",
          },
          {
            title: "Cobrador",
            icon: "",
            route: "/cobrador",
          },
          {
            title: "Grau Parentesco",
            icon: "",
            route: "/graus",
          },
          {
            title: "Tipos de Vendas",
            icon: "",
            route: "/tpvendas",
          },
          {
            title: "Tipo Dependente",
            icon: "",
            route: "/tpdep",
          },
          {
            title: "Tipo Débito",
            icon: "",
            route: "/tpdebito",
          },
          {
            title: "Tipo de Informação",
            icon: "",
            route: "/tpinfo",
          },
          {
            title: "Tipo de Taxa",
            icon: "",
            route: "/taxatp",
          },
          {
            title: "Status do Contrato",
            icon: "",
            route: "/ctstatus",
          },
          {
            title: "Status da Cobrança",
            icon: "",
            route: "/cobstatus",
          },
          {
            title: "Categoria de Cobrança",
            icon: "",
            route: "/categoriacob",
          },
          {
            title: "Tab. Sexo",
            icon: "",
            route: "/unsexo",
          },
          {
            title: "Tab. Religião",
            icon: "",
            route: "/unreligiao",
          },
          {
            title: "Tab. Estado Civil",
            icon: "",
            route: "/unestcivil",
          },
          {
            title: "Tab. Banco",
            icon: "",
            route: "/unbanco",
          },
          {
            title: "Tab. Contas",
            icon: "",
            route: "/unfinconta",
          },
          {
            title: "Convênio Cobrança",
            icon: "",
            route: "/cobcobrconv",
          },
          {
            title: "Variáveis do Sistema",
            icon: "",
            route: "/ctvariaveis",
          },
          {
            title: "Empresa do Sistema",
            icon: "",
            route: "/empresas",
          },
        ],
      },
    ],
  },
];
