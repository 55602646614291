import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 150,
  },

  msg: {
    fontWeight: 300,
    fontSize: "1.1rem",
  },
}));
