import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    gap: 20,
  },
  containerLoading: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: 150,
  },
  line1Charts: {
    display: "flex",
    gap: 20,
    width: "100%",
    flexWrap: "wrap",
  },
  line2Charts: {
    display: "flex",
    gap: 20,
    width: "100%",
    marginTop: 20,
    flexWrap: "wrap",
  },
}));
