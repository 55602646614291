import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    gap: "10px",
    alignItems: "top",
    flexWrap: "wrap",
  },
  containerNotFound: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 50,
  },
  textNotFound: {
    fontSize: "1rem",
    fontWeight: "300",
  },
}));
