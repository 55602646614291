import React from "react";
import Chart from "react-apexcharts";
// import "./styles.css";

export function ChartTopRegisters() {
  const options = {
    chart: {
      height: 200,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
        barHeight: "75%",
        borderRadius: 10,
        strokeWidth: 0,
        strokeColors: "transparent",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "16px",
        fontFamily: "Poppins",
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: ["ECR-90%", "FGI-82%", "EOQ-75%", "FMG-60%", "PLG-50%"],
      labels: {
        style: {
          // fontWeight: "bold",
          fontFamily: "Poppins",
          fontSize: "11px",
          colors: "#aaa",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontWeight: "bold",
          fontFamily: "Poppins",
          fontSize: "13px",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
    },
    colors: ["#00A3FF", "#f25776", "#50CC89", "#F8D15F", "#6B3CE1"],
  };

  const series = [
    {
      data: [400, 430, 448, 470, 540],
    },
  ];

  return (
    <div className={`card card-custom p-8 pr-12`}>
      <h4 class="font-weight-bolder ml-2 pt-0">Tasks Over Time</h4>
      <div className="fs-6 fw-bold ml-2 text-gray-400">24 Overdue Tasks</div>

      <Chart
        options={options}
        series={series}
        type="bar"
        height={350}
        className="custom-bar-chart"
        dir="ltr"
      />
    </div>
  );
}
