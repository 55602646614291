import {
  Box,
  Card,
  CardHeader,
  Fab,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  AddOutlined,
  DeleteOutline,
  EditOutlined,
  RemoveRedEye,
  VisibilityOff,
} from "@material-ui/icons";

import Modal from "../../components/Modal";
import Confirm from "../../components/Confirm";

import React, { useEffect, useState } from "react";

import { toast, ToastContainer } from "react-toastify";
import * as yup from "yup";

import {
  CardBody,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import api from "../../services/api";

import Input from "../../components/Input";

import { filterResults } from "../../utils/filterResults";
import { useStylesSelect } from "./styles";

const userValidateSchema = yup.object().shape({
  name: yup.string().required("Digite o nome do usuário"),
  email: yup
    .string()
    .email("Digite um e-mail válido")
    .required("Digite um e-mail"),
  password: yup.string().required("Digite uma senha"),
  company: yup.string().required("Escolha uma empresa"),
  roles: yup.string().required("Informe o tipo de permissão"),
});

const UsersPage = () => {
  const classes = useStylesSelect();

  const [filterByName, setFilterByName] = useState("");

  const [idUser, setIdUser] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roles, setRoles] = useState("");
  const [company, setCompany] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersFiltered, setUsersFiltered] = useState([]);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isVisibleModalConfirm, setIsVisibleModalConfirm] = useState(false);
  const [userSelected, setUserSelected] = useState({});

  const [errors, setErrors] = useState({});

  useEffect(() => {
    getCompanies();
    getUsers();
  }, []);

  useEffect(() => {
    const result = filterResults(users, filterByName);
    setUsersFiltered(result);
  }, [users, filterByName]);

  const closeModal = () => {
    setIdUser("");
    setEmail("");
    setName("");
    setCompany("");
    setPassword("");
    setRoles("");
    setIsVisibleModal(false);
    setErrors({});
  };

  async function getUserById(id) {
    setIsVisibleModal(true);
    setLoading(true);

    try {
      const response = await api.get(`admin/user/${id}`);
      const user = response.data;
      setIdUser(id);
      setEmail(user.email);
      setName(user.name);
      setCompany(user.idcompany);
      setRoles(JSON.parse(user.roles)[0]);
    } catch (error) {
      toast("Erro ao buscar dados do usuário: " + error, { type: "error" });
    } finally {
      setLoading(false);
    }
  }

  async function getCompanies() {
    try {
      const response = await api.get("admin/company");
      setCompanies(response.data);
    } catch (error) {
      toast("Erro ao buscar empresas: " + error, { type: "error" });
    }
  }

  async function getUsers() {
    try {
      const response = await api.get("admin/user");
      setUsers(response.data);
      setUsersFiltered(response.data);
    } catch (error) {
      toast("Erro ao buscar usuários: " + error, { type: "error" });
    }
  }

  const createUser = async () => {
    try {
      await api.post("/admin/user", {
        name,
        email,
        password,
        idcompany: company,
        roles: `["${roles}"]`,
      });
      toast("Usuário adicionado com sucesso", { type: "success" });
      closeModal();
      getUsers();
    } catch (error) {
      toast("Erro ao adicionar usuário: " + error, { type: "error" });
    }
  };

  const editUser = async () => {
    try {
      await api.put(`/admin/user/${idUser}`, {
        name,
        email,
        password,
        idcompany: company,
        roles: `["${roles}"]`,
      });
      toast("Usuário editado com sucesso", { type: "success" });
      getUsers();
      closeModal();
    } catch (error) {
      toast("Erro ao editar usuário: " + error, { type: "error" });
    }
  };

  const deleteUser = async (id) => {
    try {
      await api.delete(`/admin/user/${id}`);
      getUsers();
      toast("Usuário excluido com sucesso", { type: "success" });
    } catch (error) {
      toast("Erro ao excluir usuário: " + error, { type: "error" });
    }
    setIsVisibleModalConfirm(false);
    setUserSelected({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let addressFormData = {
      name,
      email,
      password,
      company,
      roles,
    };

    userValidateSchema
      .validate(addressFormData, { abortEarly: false })
      .then(() => {
        idUser ? editUser() : createUser();
      })
      .catch((err) => {
        const inners = err.inner;

        let errors = {};

        inners.forEach((element) => {
          errors = { ...errors, [element.path]: element.errors[0] };
        });

        setErrors(errors);
        console.log(">>>", errors);
      });
  };

  return (
    <div>
      <Card>
        <CardHeader title="Usuários">
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <form className={classes.containerForm}>
            <FormControl className={classes.margin}>
              <FormHelperText>Filtrar por nome</FormHelperText>
              <Input
                onChange={(event) => setFilterByName(event.target.value)}
                id="age-customized-input"
              />
            </FormControl>

            <div className={classes.containerFloatButtons}>
              <Fab
                title="Adicionar"
                size="small"
                color="primary"
                onClick={() => setIsVisibleModal(true)}
                aria-label="Add"
              >
                <AddOutlined />
              </Fab>
            </div>
          </form>
          <Paper className={classes.root}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Empresa</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersFiltered.map((user) => (
                  <TableRow key={user.iduser}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.company_name}</TableCell>
                    <TableCell>
                      <Fab
                        title="Editar"
                        className={classes.fab}
                        size="small"
                        onClick={() => getUserById(user.iduser)}
                        aria-label="Log"
                      >
                        <EditOutlined />
                      </Fab>
                      <Fab
                        title="Apagar"
                        className={classes.fab}
                        size="small"
                        /*                         onClick={() => deleteUser(user.iduser)} */
                        onClick={() => {
                          setUserSelected(user);
                          setIsVisibleModalConfirm(true);
                        }}
                        aria-label="Log"
                      >
                        <DeleteOutline />
                      </Fab>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </CardBody>
        <Modal
          isVisible={isVisibleModal}
          title={idUser ? "Editar usuário" : "Novo usuário"}
          handleSave={handleSubmit}
          closeModal={() => setIsVisibleModal(false)}
        >
          <form onSubmit={handleSubmit}>
            {loading ? (
              <div>Carregando...</div>
            ) : (
              <div>
                <TextField autoComplete="off"
                  onChange={(event) => setName(event.target.value)}
                  name="name"
                  value={name}
                  label="Nome"
                  type="text"
                  rowsMax="1"
                  margin="normal"
                  variant="outlined"
                  error={errors.name}
                  helperText={!!errors.name && errors.name}
                />
                <Box className={classes.lineForm}>
                  <TextField autoComplete="off"
                    onChange={(event) => setEmail(event.target.value)}
                    name="email"
                    value={email}
                    label="Email"
                    type="email"
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    error={errors.email}
                    helperText={!!errors.email && errors.email}
                  />

                  <TextField autoComplete="off"
                    onChange={(event) => setPassword(event.target.value)}
                    name="password"
                    value={password}
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    error={errors.password}
                    helperText={!!errors.password && errors.password}
                    InputProps={{
                      endAdornment: showPassword ? (
                        <RemoveRedEye
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <VisibilityOff
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      ),
                    }}
                  />
                </Box>

                <Box className={classes.lineForm}>
                  <TextField autoComplete="off"
                    select
                    value={company}
                    name="company"
                    label="Empresa"
                    onChange={(event) => setCompany(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    error={errors.company}
                    helperText={!!errors.company && errors.company}
                  >
                    {companies.map((company) => (
                      <MenuItem
                        key={company.idcompany}
                        value={company.idcompany}
                      >
                        {company.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField autoComplete="off"
                    select
                    value={roles}
                    label="Permissão do usuário"
                    onChange={(event) => setRoles(event.target.value)}
                    error={errors.roles}
                    helperText={!!errors.roles && errors.roles}
                    margin="normal"
                    variant="outlined"
                  >
                    <MenuItem value="ADMIN">Administrador</MenuItem>
                    <MenuItem value="OPERATOR">Operador</MenuItem>
                  </TextField>
                </Box>
              </div>
            )}
          </form>
        </Modal>
        <Confirm
          closeModal={() => setIsVisibleModalConfirm(false)}
          handleConfirm={() => deleteUser(userSelected.iduser)}
          isVisible={isVisibleModalConfirm}
          title="Confirmar exclusão"
          text={`Deseja excluir o usuário ${userSelected.name}?`}
        />
      </Card>
      <ToastContainer />
    </div>
  );
};

export default UsersPage;
