/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts from "apexcharts";
import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";

import { useHtmlClassService } from "../../../../../_metronic/layout";
import { formatMoney, getMonthAbbreviations } from "../../../../utils/formats";
import {
  calcularPorcentagem,
  extrairValorNumericoPorcentagem,
  obterUltimosValoresArray,
  pegarPrimeiraMetadeDoArray,
} from "../utils";

export function ChartInadiplencia({
  className,
  chartColor = "danger",
  issuedVsReceived = {},
}) {
  const uiService = useHtmlClassService();

  const [labels, setLabels] = useState([]);
  const [inadiplentes, setInadiplentes] = useState([]);

  const [inadiplentesAnteriores, setInadiplentesAnteriores] = useState([]);

  const [indexButton, setIndexButton] = useState(6);

  async function getChartData() {
    const obj = issuedVsReceived;
    const keysArray = Object.keys(obj);
    const totalInadiplentes = [];

    keysArray.forEach((key) => {
      const item = obj[key];
      totalInadiplentes.push(item.total - item.received);
    });

    const keysMonth = getMonthAbbreviations(keysArray);

    const responseLabels = obterUltimosValoresArray(keysMonth, indexButton);
    const responseValues = obterUltimosValoresArray(
      totalInadiplentes,
      indexButton
    );

    const responseValuesAnteriores = obterUltimosValoresArray(
      totalInadiplentes,
      indexButton * 2
    );

    setLabels(responseLabels);
    setInadiplentes(responseValues);
    setInadiplentesAnteriores(
      pegarPrimeiraMetadeDoArray(responseValuesAnteriores)
    );
  }

  useEffect(() => {
    getChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issuedVsReceived, indexButton]);

  function somaValores(valores = []) {
    return valores.reduce((acc, val) => acc + val, 0);
  }

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_120_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
    // eslint-disable-next-line
  }, [layoutProps, inadiplentes, labels, indexButton]);

  const porcentagem = calcularPorcentagem(
    somaValores(inadiplentes),
    somaValores(inadiplentesAnteriores)
  );

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom  ${className}`} style={{ width: 400 }}>
        {/* begin::Header */}
        <div className="mt-8 border-0"></div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column p-0">
          {/* begin::Chart */}
          <h4 class="font-weight-bolder ml-8 pt-2">Inadimplentes</h4>

          <div className="ml-8 d-flex flex-row">
            <h2
              style={{
                color: "#c6c6c6",
                fontSize: 14,
                marginRight: 6,
                marginTop: 2,
              }}
            >
              R$
            </h2>
            <div className="d-flex flex-row align-items-center">
              <h2 className="font-weight-bolder pt-0">
                {formatMoney(somaValores(inadiplentes))}
              </h2>
              <div
                className="ml-4 d-flex flex-row justify-content-center align-items-center pt-2 pl-2 pr-2"
                style={{
                  marginTop: -7,
                  height: 25,
                  background:
                    extrairValorNumericoPorcentagem(porcentagem) > 0
                      ? "rgba(90, 211, 255, 0.2)"
                      : "rgba(242, 87, 118, 0.2)",
                }}
              >
                <h6
                  style={{
                    color:
                      extrairValorNumericoPorcentagem(porcentagem) > 0
                        ? "#00A3FF"
                        : "rgba(242, 87, 118, 1)",
                  }}
                >
                  <span style={{ fontSize: 12, fontWeight: "bold" }}>
                    {extrairValorNumericoPorcentagem(porcentagem) > 0
                      ? "▲"
                      : "▼"}{" "}
                    {porcentagem}
                  </span>{" "}
                </h6>
              </div>
            </div>
          </div>

          <div className="ml-8 d-flex flex-row mt-4 mb-2">
            <button
              onClick={() => setIndexButton(3)}
              className="mr-8 p-3"
              style={{
                fontWeight: "bold",
                width: 50,
                border: 0,
                borderRadius: 8,
                background:
                  indexButton === 3 ? "rgba(242, 87, 118, 1)" : "transparent",
                color: indexButton === 3 ? "#fff" : "#b1b1b1",
              }}
            >
              3m
            </button>

            <button
              onClick={() => setIndexButton(6)}
              className="mr-8 p-3"
              style={{
                fontWeight: "bold",
                width: 50,
                border: 0,
                borderRadius: 8,
                background:
                  indexButton === 6 ? "rgba(242, 87, 118, 1)" : "transparent",
                color: indexButton === 6 ? "#fff" : "#b1b1b1",
              }}
            >
              6m
            </button>

            <button
              onClick={() => setIndexButton(12)}
              className="mr-8 p-3"
              style={{
                fontWeight: "bold",
                width: 50,
                border: 0,
                borderRadius: 8,
                background:
                  indexButton === 12 ? "rgba(242, 87, 118, 1)" : "transparent",
                color: indexButton === 12 ? "#fff" : "#b1b1b1",
              }}
            >
              12m
            </button>
          </div>

          <div class="fs-6 d-flex text-gray-400 fs-6 fw-bold ml-8"></div>

          <div id="kt_mixed_widget_120_chart" data-color={chartColor} />
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );

  function getChartOption(layoutProps) {
    const options = {
      series: [
        {
          name: "Total",
          data: inadiplentes,
        },
      ],
      chart: {
        type: "area",
        height: 300,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: ["#6B3CE1"],
      },
      xaxis: {
        categories: labels,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: "#bbb",
            fontSize: "12px",
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: "#aaa",
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#bbb",
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        // y: {
        //   formatter: function(val) {
        //     return val + " tasks";
        //   },
        // },
      },
      colors: ["#F1ECFC"], //
      grid: {
        borderColor: "#d9d9d9",
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        //size: 5,
        colors: ["#F1ECFC"], //
        strokeColor: ["#6B3CE1"],
        strokeWidth: 3,
      },
    };
    return options;
  }
}
