import React from "react";
import ReactToPrint from "react-to-print";
import { Fab, Icon } from "@material-ui/core";

import { Link } from "react-router-dom";
import { ComponentToContrato } from "../components/ComponentToContrato";

export class ContratoPPageM extends React.Component {
  constructor(props) {
    super(props);
    console.log("PROP ", props.match.params.idPlano);
    //console.log("PROP ", props.match.params.idPlanof);
    this.state = { idPlano: props.match.params.idPlano };
    //this.state = { idPlanof: props.match.params.idPlanof };
  }

  render() {
    const pageStyle = `

        body {
            -webkit-print-color-adjust: exact !important;
        }
        
        
        @page {
            size: 80mm 50mm;
        }
        
        @media all {
            .pagebreak {
                display: none;
            }
        }
        
        @media print {           
 
            #plano{
                background-color: #C5FBFA;
            }
            .pagebreak {
                page-break-before: always;
            }
        }

      
          
        
        
        `;

    return (
      <div style={{ textAlign: "right" }}>
        <Link style={{ marginRight: 20 }} to="/relcontrato">
          Voltar
        </Link>
        <ReactToPrint
          style={{ marginLeft: 40 }}
          pageStyle={pageStyle}
          trigger={() => (
            <Fab color="inherit" aria-label="Imprimir" title="Imprimir">
              <Icon>print</Icon>
            </Fab>
          )}
          content={() => this.componentRef}
        />
        <ComponentToContrato
          ref={(el) => (this.componentRef = el)}
          idPlano={this.state.idPlano}
        />
      </div>
    );
  }
}
