/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { dataMenu } from "./dataMenu";
import jwtDecode from "jwt-decode";

function normalizeString(str) {
  return str
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");
}

export function AsideMenuList({ layoutProps }) {
  const isFirstRender = useRef(true);
  const [menu, setMenu] = useState(dataMenu);
  const [inputMenu, setInputMenu] = useState("");

  const TOKEN_KEY = "X-Token";
  const getToken = () => localStorage.getItem(TOKEN_KEY);


  const token = getToken();
  let isAdmin = false;

  if (token) {
    const tokenDecoded = jwtDecode(token);

    let roles = [];
    if (tokenDecoded.roles) {
      roles = JSON.parse(tokenDecoded.roles);
    }
    console.log("ROLES ", roles);
    for (let i = 0; i < roles.length; i++) {
      console.log("ROLE ", roles[i]);
      if(roles[i] == "ADMIN"){
        isAdmin = true;
      }
    }
    // if(roles = "ADMIN"){
    //   isAdmin = true;
    // }
  }


  dataMenu.forEach((menuItem) => {
    console.log("menuItemmenuItem ", menuItem);

    if(menuItem.title == "Configurações"){
      menuItem.items.filter((item, index) =>{
        if(!isAdmin && (item.route == "/usuarios" || item.route == "/empresas-novo") ){
          delete menuItem.items[index];
        }
        
      })
    }
  })
  const filterMenu = useCallback(() => {
    const responseFilter = [];
    
    //Buscando nos itens
    dataMenu.forEach((menuItem) => {
      const response = menuItem.items.filter((item) =>
        normalizeString(item.title).includes(normalizeString(inputMenu))
      );

      responseFilter.push({
        title: menuItem.title,
        icon: menuItem.icon,
        items: response,
      });
    });

    //Buscando subitens

    if (inputMenu.length > 1) {
      dataMenu.forEach((menuItem) => {
        menuItem.items.forEach((items) => {
          const response = items.subItems.filter((subItem) =>
            normalizeString(subItem.title).includes(normalizeString(inputMenu))
          );

          responseFilter.push({
            title: items.title,
            icon: menuItem.icon,
            items: response,
          });
        });
      });
    } else {
      setMenu(dataMenu);
      return;
    }

    setMenu(responseFilter);
  }, [inputMenu]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    filterMenu();
  }, [filterMenu, inputMenu]);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      <form className="p-lg-3">
        <div className="input-icon h-40px">
          <input
            className="form-control form-control-lg form-control-solid h-40px w-full"
            placeholder="Pesquisar..."
            id="generalSearch"
            autoComplete="false"
            value={inputMenu}
            onChange={(event) => setInputMenu(event.target.value)}
          />

          <span>
            <span className="svg-icon svg-icon-lg">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
            </span>
          </span>
        </div>
      </form>

      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {menu.map((category, index) => (
          <Fragment key={index}>
            {category.items?.length > 0 && (
              <li
                className="menu-section mb-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span className="svg-icon svg-icon-lg">
                  <SVG src={toAbsoluteUrl(category.icon)} />
                </span>
                <h4 className="menu-text m-2" style={{ fontSize: "1.1rem" }}>
                  {category.title}
                </h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
            )}
            {category.items.map((item, index) =>
              item.subItems?.length > 0 ? (
                <li
                  key={index}
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    item.route,
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to=" ">
                    <span className="svg-icon menu-icon">
                      {/* <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/Layers.svg"
                        )}
                      /> */}
                      <SVG
                        src={toAbsoluteUrl(item.icon)}
                      />
                    </span>
                    {/* <span className="menu-text" style={{ fontSize: "1.1rem" }}>
                      {item.title}
                    </span> */}
                    <span className="text-dark-75 font-size-h6 mb-0 font-weight-bold">
                      {item.title}
                    </span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      {item.subItems.map((subItems, index) => (
                        <li
                          key={index}
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            subItems.route,
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to={subItems.route}
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span
                              className="menu-text"
                              style={{ fontSize: "1rem" }}
                            >
                              {subItems.title}
                            </span>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              ) : (
                <li
                  className={`menu-item ${getMenuItemActive(
                    item.route,
                    false
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to={item.route}>
                    <span className="svg-icon menu-icon">
                      {/* <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/Layers.svg"
                        )}
                      /> */}

                      <SVG
                        src={toAbsoluteUrl(item.icon)}
                      />
                    </span>
                    <span className="text-dark-75 font-size-h6 mb-0 font-weight-bold">
                      {item.title}
                    </span>
                  </NavLink>
                </li>
              )
            )}
          </Fragment>
        ))}
      </ul>
    </>
  );
}
