import { makeStyles } from "@material-ui/core";

export const useStylesSelect = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },

  containerForm: {
    display: "flex",
    alignItems: "center",
  },

  fab: {
    marginRight: 15,
  },

  containerFloatButtons: {
    marginLeft: "30px",
    display: "flex",
    alignItems: "center",
  },
  box: {
    padding: 30,
  },

  lineForm: {
    display: "flex",
    gap: "20px",
  },
}));

export const modalStyle = makeStyles(() => ({}));
