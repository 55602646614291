import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Fab,
  FormControl,
  FormHelperText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";

import { AddOutlined, EditOutlined } from "@material-ui/icons";
import { toast, ToastContainer } from "react-toastify";
import * as yup from "yup";
import {
  CardBody,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

import api from "../../services/api";

import Input from "../../components/Input";
import Modal from "../../components/Modal";

import { filterResults } from "../../utils/filterResults";
import { useStylesSelect } from "./styles";

const companyValidateSchema = yup.object().shape({
  name: yup.string().required("Digite o nome da empresa"),
});

const CompaniesPage = () => {
  const classes = useStylesSelect();

  const [filterByName, setFilterByName] = useState("");

  const [idCompany, setIdCompany] = useState("");
  const [name, setName] = useState("");

  const [companies, setCompanies] = useState([]);
  const [companiesFiltered, setCompaniesFiltered] = useState([]);

  const [errors, setErrors] = useState({});

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    const result = filterResults(companies, filterByName);
    setCompaniesFiltered(result);
  }, [companies, filterByName]);

  const closeModal = () => {
    setIdCompany("");
    setName("");
    setErrors({});
    setIsVisibleModal(false);
  };

  async function getCompanies() {
    try {
      const response = await api.get("admin/company");
      setCompaniesFiltered(response.data);
      setCompanies(response.data);
    } catch (error) {
      toast("Erro ao buscar empresa: " + error, { type: "error" });
    }
  }

  const createCompany = async () => {
    try {
      await api.post("/admin/company", {
        name,
        database_host:
          "memorium-database.cgyyuwxvfh6x.sa-east-1.rds.amazonaws.com",
        database_user: "memorium",
        database_password: "a3e34345#42$5AMEEde",
      });
      toast("Empresa adicionada com sucesso", { type: "success" });
      closeModal();
      getCompanies();
    } catch (error) {
      toast("Erro ao adicionar empresa: " + error, { type: "error" });
    }
  };

  const editCompany = async () => {
    try {
      await api.put(`/admin/company/${idCompany}`, {
        name,
        database_host:
          "memorium-database.cgyyuwxvfh6x.sa-east-1.rds.amazonaws.com",
        database_user: "memorium",
        database_password: "a3e34345#42$5AMEEde",
      });
      toast("Empresa editada com sucesso", { type: "success" });
      getCompanies();
      closeModal();
    } catch (error) {
      toast("Erro ao editar empresa: " + error, { type: "error" });
    }
  };

  const fillStatesAndOpenModal = (client) => {
    setIdCompany(client.idcompany);
    setName(client.name);
    setIsVisibleModal(true);
  };

  const handleSubmit = () => {
    let addressFormData = {
      name,
    };

    companyValidateSchema
      .validate(addressFormData, { abortEarly: false })
      .then(() => {
        idCompany ? editCompany() : createCompany();
      })
      .catch((err) => {
        const inners = err.inner;

        let errors = {};

        inners.forEach((element) => {
          errors = { ...errors, [element.path]: element.errors[0] };
        });

        setErrors(errors);
      });
  };

  return (
    <div>
      <Card>
        <CardHeader title="Empresas">
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <form className={classes.containerForm}>
            <FormControl className={classes.margin}>
              <FormHelperText>Filtrar por nome</FormHelperText>
              <Input
                onChange={(event) => setFilterByName(event.target.value)}
                id="age-customized-input"
              />
            </FormControl>

            <div className={classes.containerFloatButtons}>
              <Fab
                title="Adicionar"
                size="small"
                color="primary"
                onClick={() => setIsVisibleModal(true)}
                aria-label="Add"
              >
                <AddOutlined />
              </Fab>
            </div>
          </form>
          <Paper className={classes.root}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Database</TableCell>
                  <TableCell>Ações</TableCell>                 
                </TableRow>
              </TableHead>
              <TableBody>
                {companiesFiltered.map((company) => (
                  <TableRow key={company.idcompany}>
                    <TableCell>{company.idcompany}</TableCell>
                    <TableCell>{company.name}</TableCell>
                    <TableCell>memorium_client_{company.idcompany}</TableCell>
                    <TableCell>
                      <Fab
                        title="Editar"
                        className={classes.fab}
                        size="small"
                        onClick={() => fillStatesAndOpenModal(company)}
                        aria-label="Log"
                      >
                        <EditOutlined />
                      </Fab>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </CardBody>
        <Modal
          isVisible={isVisibleModal}
          closeModal={closeModal}
          title={idCompany ? "Editar empresa" : "Nova empresa"}
          handleSave={handleSubmit}
        >
          <TextField autoComplete="off"
            required
            onChange={(event) => setName(event.target.value)}
            name="name"
            value={name}
            id="outlined-multiline-flexible"
            label="Nome"
            type="text"
            rowsMax="1"
            margin="normal"
            variant="outlined"
            error={errors.name}
            helperText={!!errors.name && errors.name}
          />
        </Modal>
      </Card>
      <ToastContainer />
    </div>
  );
};

export default CompaniesPage;
