import ApexCharts from "apexcharts";
import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";
import { useHtmlClassService } from "../../../../../_metronic/layout";
import api from "../../../../services/api";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

function generateRandomHexColors(numColors) {
  const colors = [];
  const characters = "0123456789ABCDEF";

  for (let i = 0; i < numColors; i++) {
    let color = "#";
    for (let j = 0; j < 6; j++) {
      color += characters[Math.floor(Math.random() * 16)];
    }
    colors.push(color);
  }

  return colors;
}

export function ChartStatusContratos({ className }) {
  const uiService = useHtmlClassService();

  const currentDate = new Date();
  const oneYearBefore = new Date();
  oneYearBefore.setMonth(oneYearBefore.getMonth() - 6);

  const [formDataFilter] = useState({
    data_de: formatDate(oneYearBefore),
    data_ate: formatDate(currentDate),
  });

  const [colors, setColors] = useState([]);
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(
        uiService.config,
        "js.colors.gray.gray100"
      ),
      colorsGrayGray700: objectPath.get(
        uiService.config,
        "js.colors.gray.gray700"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  function stringsToNumbers(array) {
    if (!Array.isArray(array)) {
      return [];
    }

    const result = array.map((str) => {
      if (str === null || str === undefined) {
        return 0;
      }
      const number = parseFloat(str);
      return isNaN(number) ? 0 : number;
    });

    return result;
  }

  async function getStatusContratos() {
    try {
      const formdata = new FormData();
      formdata.append("data_de", formDataFilter.data_de);
      formdata.append("data_ate", formDataFilter.data_ate);

      const response = await api.post("/report/statuses", formdata, {
        redirect: "follow",
      });

      const tempColors = generateRandomHexColors(
        response?.data?.labels?.length
      );

      console.log(response?.data);

      setColors([...tempColors]);
      setLabels([...response?.data?.labels]);
      setValues([...response?.data?.series]);
    } catch (error) {
      console.log(error);
      alert("Erro ao buscar status dos contratos");
    }
  }

  useEffect(() => {
    getStatusContratos();
  }, []);

  // function getChartOption(layoutProps) {
  const options = {
    series: [values],
    labels: [labels],
    dataLabels: {
      enabled: false,
    },
    chart: {
      type: "donut",
      // height: 150,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        // enabled: true,
      },
    },
    legend: {
      show: false, // Remover legendas
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {},
            total: {
              show: true,
              showAlways: true,
              label: values?.reduce((acc, val) => Number(acc) + Number(val), 0),
              fontFamily: "Poppins",
              fontWeight: "bold",
              fontSize: "35px",
              formatter: function(w) {
                return "Total";
              },
            },
          },
        },
      },
    },
    colors: colors,
  };

  useEffect(() => {
    const element = document.getElementById("kt_stats_widget_110_chart");

    if (!element) {
      return;
    }

    // const options = getChartOption(layoutProps);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
    // eslint-disable-next-line
  }, [layoutProps, values, labels, colors]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card card-flush h-lg-100">
        <div className="mt-8">
          <div className="card-title flex-column ml-8">
            <h4 class="font-weight-bolder pt-0">Status dos contratos</h4>
            <div className="fs-6 fw-bold text-gray-400">24 Overdue Tasks</div>
          </div>
        </div>

        <div className="card-body p-9 pt-5">
          <div className="d-flex flex-wrap">
            <div
              id="kt_stats_widget_110_chart"
              style={{ fontFamily: "Poppins" }}
            ></div>
            <div className="d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5">
              {labels?.map((label, index) => (
                <div className="d-flex fs-6 fw-bold align-items-center mb-3">
                  <div
                    className="bullet  ml-2"
                    style={{ height: 3, background: colors[index] }}
                  ></div>
                  <div className="text-gray-400 ml-2">
                    {/* {console.log("asdasda>>>>>><<<", label)} */}
                    {label ? label : "--"}
                  </div>
                  <div className="ms-auto font-weight-bold text-gray-700 ml-2">
                    {values}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
