import React from "react";
import { Modal } from "react-bootstrap";
import { Typography, Button, Divider } from "@material-ui/core";

import { useStylesSelect } from "./styles";

const Confirm = (props) => {
  const {
    isVisible,
    closeModal,
    handleConfirm,
    text,
    title,
    size = "md",
  } = props;

  const classes = useStylesSelect();

  return (
    <Modal
      show={isVisible}
      onHide={closeModal}
      backdrop="static"
      centered
      size={size}
    >
      <Typography className={classes.title} component="h2" variant="h6">
        {title}
      </Typography>
      <Divider />
      {/*       <Box className={classes.box}>{children}</Box> */}
      <Typography className={classes.title}>{text}</Typography>
      <Divider />
      <div className={classes.footer}>
        <Button
          onClick={handleConfirm}
          className={classes.button}
          color="primary"
          variant="contained"
        >
          Sim
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          Não
        </Button>
      </div>
    </Modal>
  );
};

export default Confirm;
